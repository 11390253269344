// This file is to tune UIKit variables

// Important reset styles
$base-margin-vertical: 0;
$base-heading-margin-top: 0;
$base-list-padding-left: 0;
$base-body-font-size: 100%;
$base-em-color: inherit;

$base-body-line-height: 1.3;
$base-h1-line-height: $base-body-line-height;
$base-h2-line-height: $base-body-line-height;
$base-h3-line-height: $base-body-line-height;
$base-h4-line-height: $base-body-line-height;
$base-h5-line-height: $base-body-line-height;
$base-h6-line-height: $base-body-line-height;

// Globals
$container-max-width: 1200px;
$container-small-max-width: 780px;
$container-xsmall-max-width: 720px;

$container-padding-horizontal-m: 40px;
$container-padding-horizontal-s: 32px;
$container-padding-horizontal: 16px;

$global-font-family: $font-family-base;

// Links
$base-link-color: $color-yellow--dark;
$base-link-hover-color: $color-yellow--dark;
$base-link-text-decoration: none;
$base-link-hover-text-decoration: none;

// Forms
$form-height: var(--form-height);
$form-font-size: var(--form-font-size);
$form-line-height: 1.19;
$form-padding-horizontal: 16px;

$form-border-radius: 2px;

$form-placeholder-color: rgba($color-gray, 0.5);

$form-color: var(--form-default-text-color);
$form-background: var(--form-default-background);
$form-border-color: var(--form-default-border);

$form-hover-color: var(--form-hover-text-color);
$form-hover-background: var(--form-hover-background);
$form-hover-border-color: var(--form-hover-border);

$form-focus-color: var(--form-active-text-color);
$form-focus-background: var(--form-active-background);
$form-focus-border-color: var(--form-active-border);

$form-disabled-color: var(--form-disabled-text-color);
$form-disabled-background: var(--form-disabled-background);
$form-disabled-border-color: var(--form-disabled-border);

$form-radio-size: var(--form-radio-size);
$form-checkbox-size: 20px;
$form-radio-margin-top: 0;
$form-radio-background: transparent;
$form-radio-checked-background: transparent;
$form-radio-checked-focus-background: transparent;
$internal-form-checkbox-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2011%22%3E%3Cpath%20d%3D%22M1%204.429L5.364%209%2013%201%22%20stroke%3D%22%23FCCF17%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E";
$internal-form-select-image: var(--form-default-dropdown-arrow-source);

// Notifications
$custom-notification-header-font-weight: $font-weight-semi-bold;
$custom-notification-text-color: $color-gray;
$custom-notification-close-offset-top: 20px;
$custom-notification-close-offset-right: 15px;

// Accordion
$accordion-item-margin-top: 8px;
$accordion-content-margin-top: 0;
$accordion-title-color: $color-gray--darkest;
$accordion-title-hover-color: var(--button-hover-color, #{$color-gray--darkest});
$accordion-title-font-size: toRem(16px);
$accordion-title-line-height: 1.28;

// Modals
$modal-close-position-xs: 16px;
$modal-close-position: 12px;

// Tooltip
$tooltip-z-index: $z-index-modal + 1;

:root {
    --form-height: 48px;
    --form-font-size: #{toRem(16px)};
    --form-radio-size: 16px;

    // default arrow color for dropdowns placed not in forms
    // look in forms.scss to customize dropdown in forms
    --form-default-dropdown-arrow-source: #{getColoredArrow($color-black)};

    --container-padding-horizontal: #{$container-padding-horizontal-m};

    @include vw-large-down {
        --header-height: 80px;
    }

    @include vw-medium-down {
        --container-padding-horizontal: #{$container-padding-horizontal-s};
    }

    @include vw-header-small-down {
        --header-height: 64px;
    }

    @include vw-small-down {
        --container-padding-horizontal: #{$container-padding-horizontal};
        --form-radio-size: 20px;
    }

    @include vw-xsmall-down {
        --form-height: 44px;
    }
}
