.MiniCartItem {
    $block: &;

    position: relative;
    display: grid;
    grid-template-columns: 56px 1fr;
    grid-column-gap: 16px;

    @include vw-small-down {
        grid-template-columns: 40px 1fr;
    }

    &--removing {
        display: none;
    }

    &:not(&--lastChild) {
        #{$block}__footer {
            padding-bottom: 18px;
            border-bottom: 1px rgba($color-gray, 0.5) dashed;
        }
    }

    &__stocks {
        font-size: 14px;
        text-align: left;

        @include vw-small-down {
            font-size: toRem(12px);
        }
    }

    &__picture {
        @include aspect-ratio-picture(1, 1, transparent);

        border-radius: 2px;

        &--withPlaceholder {
            background-color: $color-gray--lighter;
        }
    }

    &__image {
        @extend %full-size-image;
    }

    &__placeholder {
        @extend %image-placeholder;

        width: 32px;
    }

    &__nameWrapper {
        padding-right: 40px;
        text-align: left;
    }

    &__nameLink {
        color: $color-gray--darkest;

        &:hover,
        &:focus,
        &:active {
            color: $color-orange;
        }
    }

    &__nameSpan {
        color: $color-gray--darkest;
    }

    &__name {
        font-size: toRem(18px);
        color: inherit;

        @include vw-small-down {
            text-align: left;
            font-size: toRem(14px);
        }
    }

    &__footer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "price price" "about counter";
        grid-gap: 4px;
        margin-top: 24px;

        @include vw-small-down {
            margin-top: 8px;
        }

        @include vw-xxsmall-down {
            grid-template-columns: 1fr;
            grid-template-areas: "price" "about" "counter";
        }
    }

    &__priceBlock {
        grid-area: price;
    }

    &__priceValues {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__price {
        &--total {
            font-size: toRem(22px);
            font-weight: $font-weight-bold;
            color: $color-gray--darkest;
            white-space: nowrap;
            margin-right: 8px;

            @include vw-small-down {
                font-size: toRem(14px);
            }
        }

        &--perUnit {
            color: $color-gray;

            @include vw-small-down {
                font-size: toRem(12px);
            }
        }
    }

    &__aboutBlock {
        grid-area: about;
        display: flex;
        flex-direction: column;
    }

    &__packageUnitsQuantity {
        font-size: toRem(14px);
        text-align: left;

        @include vw-small-down {
            font-size: toRem(12px);
        }
    }

    &__counter {
        width: 134px;
        height: 32px;
        grid-area: counter;
        justify-self: end;

        @include vw-xxsmall-down {
            justify-self: start;
        }
    }

    &__removeButton {
        top: -8px;
        right: -8px;
    }
}
