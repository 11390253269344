@mixin button-base-state($state) {
    color: var(--button-#{$state}-color);
    border-color: var(--button-#{$state}-border);
    background-color: var(--button-#{$state}-background);

    .Button__icon {
        color: var(--button-#{$state}-icon-color, currentColor);
    }
}

@mixin button-base {
    @include button-base-state("default");

    &:hover {
        @include button-base-state("hover");
    }

    &:focus {
        @include button-base-state("focus");
    }

    &:active,
    &.active {
        @include button-base-state("active");
    }

    &:disabled {
        @include button-base-state("disabled");
    }
}

$button-black-schema: (
    default: (
        color: white,
        border: $color-black,
        background: $color-black,
    ),
    hover: (
        color: white,
        border: $color-gray--dark,
        background: $color-gray--dark,
    ),
    focus: (
        color: white,
        border: $color-gray--dark,
        background: $color-gray--dark,
    ),
    active: (
        color: white,
        border: $color-gray--darkest,
        background: $color-gray--darkest,
    ),
    disabled: (
        color: rgba($color-gray, 0.5),
        border: transparent,
        background: rgba($color-gray, 0.1),
    ),
);

$button-white-schema: (
    default: (
        color: $color-gray,
        border: white,
        background: white,
    ),
    hover: (
        color: $color-gray,
        border: rgba($color-gray, 0.05),
        background: rgba($color-gray, 0.05),
    ),
    focus: (
        color: $color-gray,
        border: rgba($color-gray, 0.2),
        background: rgba($color-gray, 0.2),
    ),
    active: (
        color: $color-gray,
        border: rgba($color-gray, 0.2),
        background: rgba($color-gray, 0.2),
    ),
    disabled: (
        color: $color-gray,
        border: white,
        background: white,
    ),
);

$button-gray-schema: (
    default: (
        color: white,
        border: $color-gray--dark,
        background: $color-gray--dark,
    ),
    hover: (
        color: $color-yellow,
        border: $color-gray--darker,
        background: $color-gray--darker,
    ),
    focus: (
        color: $color-yellow,
        border: $color-gray--darker,
        background: $color-gray--darker,
    ),
    active: (
        color: $color-yellow,
        border: $color-gray--darkest,
        background: $color-gray--darkest,
    ),
    disabled: (
        color: rgba(white, 0.8),
        border: transparent,
        background: rgba($color-gray--dark, 0.4),
    ),
);

$button-yellow-schema: (
    default: (
        color: $color-black,
        border: $color-yellow,
        background: $color-yellow,
    ),
    hover: (
        color: $color-black,
        border: $color-yellow--dark,
        background: $color-yellow--dark,
    ),
    focus: (
        color: $color-black,
        border: $color-yellow--dark,
        background: $color-yellow--dark,
    ),
    active: (
        color: white,
        border: $color-yellow--dark,
        background: $color-yellow--dark,
    ),
    disabled: (
        color: rgba($color-gray, 0.5),
        border: transparent,
        background: rgba($color-gray, 0.1),
    ),
);

$button-yellow-text-schema: (
    default: (
        color: $color-yellow,
        border: transparent,
        background: transparent,
    ),
    hover: (
        color: $color-yellow,
        border: $color-gray--darker,
        background: $color-gray--darker,
    ),
    focus: (
        color: $color-yellow,
        border: $color-gray--darker,
        background: $color-gray--darker,
    ),
    active: (
        color: $color-yellow,
        border: $color-gray--darker,
        background: $color-gray--darker,
    ),
    disabled: (
        color: rgba($color-yellow, 0.4),
        border: transparent,
        background: transparent,
    ),
);

$button-dark-yellow-text-schema: (
    default: (
        color: $color-yellow--dark,
        border: transparent,
        background: transparent,
    ),
    hover: (
        color: rgba($color-yellow--dark, 0.7),
        border: transparent,
        background: transparent,
    ),
    focus: (
        color: rgba($color-yellow--dark, 0.7),
        border: transparent,
        background: transparent,
    ),
    active: (
        color: rgba($color-yellow--dark, 0.7),
        border: transparent,
        background: transparent,
    ),
    disabled: (
        color: rgba($color-yellow--dark, 0.4),
        border: transparent,
        background: transparent,
    ),
);

$button-black-outline-schema: (
    default: (
        color: $color-black,
        border: $color-black,
        background: white,
    ),
    hover: (
        color: white,
        border: $color-gray--dark,
        background: $color-gray--dark,
    ),
    focus: (
        color: white,
        border: $color-gray--dark,
        background: $color-gray--dark,
    ),
    active: (
        color: white,
        border: $color-gray--darkest,
        background: $color-gray--darkest,
    ),
    disabled: (
        color: $color-gray--dark,
        border: $color-gray--dark,
        background: white,
    ),
);

$button-outline-yellow-schema: (
    default: (
        color: $color-yellow,
        border: $color-yellow,
        background: transparent,
    ),
    hover: (
        color: $color-black,
        border: $color-yellow,
        background: $color-yellow,
    ),
    focus: (
        color: $color-black,
        border: $color-yellow,
        background: $color-yellow,
    ),
    active: (
        color: $color-black,
        border: $color-yellow,
        background: $color-yellow,
    ),
    disabled: (
        color: rgba(white, 0.4),
        border: rgba(white, 0.4),
        background: transparent,
    ),
);

$button-outline-white-schema: (
    default: (
        color: white,
        border: white,
        background: transparent,
    ),
    hover: (
        color: $color-black,
        border: white,
        background: white,
    ),
    focus: (
        color: $color-black,
        border: white,
        background: white,
    ),
    active: (
        color: $color-black,
        border: white,
        background: white,
    ),
    disabled: (
        color: rgba(white, 0.4),
        border: rgba(white, 0.4),
        background: transparent,
    ),
);

$button-light-gray-schema: (
    default: (
        color: $color-gray--darkest,
        background: rgba($color-gray, 0.1),
    ),
    hover: (
        color: $color-gray--darkest,
        background: rgba($color-gray, 0.2),
    ),
    focus: (
        color: $color-gray--darkest,
        background: rgba($color-gray, 0.2),
    ),
    active: (
        color: $color-gray--darkest,
        background: rgba($color-gray, 0.3),
    ),
);

$button-dark-gray-schema: (
    default: (
        color: white,
        background: $color-gray--darkest,
    ),
    hover: (
        color: white,
        background: lighten($color-gray--darkest, 5%),
    ),
    focus: (
        color: white,
        background: lighten($color-gray--darkest, 5%),
    ),
    active: (
        color: white,
        background: lighten($color-gray--darkest, 10%),
    ),
);

@mixin button-black {
    @include custom-schema("--button", $button-black-schema);
}

@mixin button-white {
    @include custom-schema("--button", $button-white-schema);
}

@mixin button-gray {
    @include custom-schema("--button", $button-gray-schema);
}

@mixin button-yellow {
    @include custom-schema("--button", $button-yellow-schema);
}

@mixin button-yellow-text {
    @include custom-schema("--button", $button-yellow-text-schema);
}

@mixin button-dark-yellow-text {
    @include custom-schema("--button", $button-dark-yellow-text-schema);
}

@mixin button-black-outline {
    @include custom-schema("--button", $button-black-outline-schema);
}

@mixin button-outline-yellow {
    @include custom-schema("--button", $button-outline-yellow-schema);
}

@mixin button-outline-white {
    @include custom-schema("--button", $button-outline-white-schema);
}

@mixin button-light-gray {
    @include custom-schema("--button", $button-light-gray-schema);
}

@mixin button-dark-gray {
    @include custom-schema("--button", $button-dark-gray-schema);
}

@mixin button-empty {
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    height: auto;
    min-width: 40px;
}

@mixin button-medium {
    min-height: 40px;
    height: 40px;
    font-size: toRem(16px);
}

$button-height: 48px;
$button-height-xs: 44px;

.Button {
    $block: &;

    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    overflow: visible;
    vertical-align: middle;
    appearance: none;

    min-width: 162px;
    height: $button-height;
    min-height: 40px;

    margin: 0;
    padding: 0 24px;

    font: inherit;
    font-weight: $font-weight-semi-bold;
    font-size: toRem(18px);
    line-height: 1;
    white-space: nowrap;
    text-decoration: none;

    border-radius: 2px;
    border-style: solid;
    border-width: 1px;

    transition: 0.25s ease-in-out;
    transition-property: color, border, background-color;

    // stylelint-disable-next-line order/order
    @include button-base;

    @include vw-xsmall-down {
        height: $button-height-xs;
        font-size: toRem(16px);
    }

    @include vw-xxsmall-down {
        width: 100%;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &--noEvents {
        pointer-events: none;
    }

    :root &--loading {
        #{$block}__spinner {
            display: block;
        }

        #{$block}__text {
            visibility: hidden;
        }
    }

    &--active {
        @include button-base-state("active");

        pointer-events: none;
    }

    &--medium {
        @include button-medium();
    }

    &--wide {
        @include vw-xxsmall-up {
            width: 256px;
            max-width: 100%;
        }
    }

    &--fullWidth {
        width: 100%;
    }

    &--black {
        @include button-black;
    }

    &--white {
        @include button-white;
    }

    &--gray {
        @include button-gray;
    }

    &--yellow {
        @include button-yellow;
    }

    &--yellowText {
        @include button-yellow-text;
    }

    &--darkYellowText {
        @include button-dark-yellow-text;
    }

    &--blackOutline {
        @include button-black-outline;
    }

    &--outlineYellow {
        @include button-outline-yellow;
    }

    &--outlineWhite {
        @include button-outline-white;
    }

    &--lightGray {
        @include button-light-gray;
    }

    &--pagination {
        min-width: 40px;
        height: 40px;
        padding: 0;
        border: none;
        font-size: toRem(22px);
        line-height: 40px;
    }

    &--empty {
        @include button-empty;
    }

    &--link {
        @include button-empty;

        --button-default-color: #{$color-yellow--dark};
        --button-hover-color: #{rgba($color-gray, 0.7)};
        --button-focus-color: #{darken($color-yellow--dark, 5%)};
        --button-active-color: #{$color-black};

        min-height: auto;
    }

    &--underlinedLink {
        @extend .Button--link;

        width: fit-content;
        border-bottom: 1px currentColor dashed;

        &.active {
            border-bottom: none;
        }
    }

    &--underlinedLinkNoUnderlinedIcon {
        @extend .Button--link;

        width: fit-content;

        #{$block}__text {
            border-bottom: 1px currentColor dashed;
        }
    }

    &--accordion {
        &.active {
            #{$block}__icon::after {
                transform: scaleY(0);
            }
        }

        &:not(.active) + * {
            display: none;
        }

        & + * {
            margin-top: 32px;

            @include vw-small-down {
                margin-top: 24px;
            }
        }

        #{$block}__icon {
            @extend %display-flex-center;

            position: relative;

            &::before,
            &::after {
                content: "";
                background-color: currentColor;
                position: absolute;
            }

            &::before {
                width: 12px;
                height: 2px;
            }

            &::after {
                width: 2px;
                height: 12px;
                transition: transform 0.25s ease-in-out;
            }
        }
    }

    &--remove {
        @include button-empty;

        position: absolute;
        size: 40px;
        color: $color-gray--darkest;

        &:hover,
        &:focus {
            color: $color-black;

            #{$block}__icon {
                background-color: rgba($color-gray, 0.2);
            }
        }

        &:active {
            color: $color-black;

            #{$block}__icon {
                background-color: rgba($color-gray, 0.3);
            }
        }

        #{$block}__icon {
            size: 24px;
            padding: 6px;
            background-color: rgba($color-gray, 0.1);
            border-radius: 2px;

            transition: background-color 0.25s ease-in-out;

            svg {
                width: 12px;
            }
        }
    }

    &--favorite {
        @include custom-schema(
            "--button",
            (
                default: (
                    color: $color-gray,
                    background: rgba($color-gray, 0.05),
                ),
                hover: (
                    background: rgba($color-gray, 0.1),
                ),
                focus: (
                    background: rgba($color-gray, 0.1),
                ),
                active: (
                    color: $color-orange,
                    background: rgba($color-yellow, 0.3),
                ),
            )
        );

        min-width: 48px;
        padding: 0;
        border: none;
        width: auto;

        &.liked {
            @include custom-schema(
                "--button",
                (
                    default: (
                        color: $color-orange,
                        background: rgba($color-yellow, 0.3),
                    ),
                    hover: (
                        color: $color-orange,
                        background: rgba($color-yellow, 0.3),
                    ),
                    focus: (
                        color: $color-orange,
                        background: rgba($color-yellow, 0.3),
                    ),
                    active: (
                        color: $color-gray,
                        background: rgba($color-gray, 0.05),
                    ),
                )
            );
        }

        #{$block}__icon {
            size: 16px;
        }
    }

    &--small {
        min-width: 120px;
    }

    &__icon + &__text,
    &__text + &__icon {
        margin-left: 8px;
    }

    &__icon {
        @extend %display-flex-center;

        position: relative;
        flex-shrink: 0;
        width: 12px;

        &--largeGap + #{$block}__text,
        #{$block}__text + &--largeGap {
            margin-left: 12px;

            @include vw-small-down {
                margin-left: 10px;
            }
        }

        &--medium {
            width: 14px;
        }

        &--large {
            width: 16px;

            @include vw-small-down {
                width: 14px;
            }
        }

        svg {
            width: 100%;
            fill: currentColor;
        }
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        :root & {
            display: none;
        }
    }
}
