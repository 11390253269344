// This file contains placeholders and mixins

// skeletons-preloaders
@mixin skeleton($color1: $color-gray--light, $color2: $color-gray--lighter) {
    background-image: linear-gradient(to right, $color1 25%, $color2 50%, $color1 85%);
    background-size: 600%;
    background-position: 0;
    will-change: background-position;
    animation: shine-loader 3s infinite ease-out;
}

%display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%full-size {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%full-size-image {
    @extend %full-size;

    object-fit: cover;
}

@mixin custom-schema($prefix, $map) {
    @each $state, $scheme in $map {
        @each $prop, $color in $scheme {
            #{$prefix}-#{$state}-#{$prop}: #{$color};
        }
    }
}

@mixin aspect-ratio-picture($width: 1, $height: $width, $color-placeholder: $color-gray--lighter) {
    display: block;
    position: relative;
    padding-bottom: aspect-ratio($width, $height);
    background-color: $color-placeholder;
    overflow: hidden;
}

@mixin image-with-scale {
    @extend %full-size-image;

    transform: scale(1);
    transition: transform 0.4s ease-out;
}

@mixin image-with-scale-active {
    transform: scale(1.065);
}

@mixin full-width-block {
    width: calc(100% + #{$container-padding-horizontal-m} * 2);
    margin-x: #{-$container-padding-horizontal-m};

    @include vw-medium-down {
        width: calc(100% + #{$container-padding-horizontal-s} * 2);
        margin-x: #{-$container-padding-horizontal-s};
    }

    @include vw-small-down {
        width: calc(100% + #{$container-padding-horizontal} * 2);
        margin-x: #{-$container-padding-horizontal};
    }
}

%overlay {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.15s linear, z-index 0.15s linear;
    z-index: -1;
}

@mixin off-canvas-layout {
    padding-x: $container-padding-horizontal-s;

    @include vw-medium-down {
        padding-x: $container-padding-horizontal-s;
    }

    @include vw-small-down {
        padding-x: $container-padding-horizontal;
    }
}

%image-placeholder {
    position: absolute;
    width: 62px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
