// This file contains all custom variables of this project

// Globals
$root-font-size: 16px;

// Colors
$color-black: #131818;

$color-gray--lighter: #dcdcdc;
$color-gray--light: #c4c4c4;
$color-gray: #656868;
$color-gray--dark: #464a4a;
$color-gray--darker: #383b3b;
$color-gray--darkest: #2b2f2f;

$color-red: #f22814;

$color-yellow: #fccf17;
$color-yellow--dark: #e39e00;

$color-orange: $color-yellow--dark;

$color-green: #28b378;

// Fonts
$font-family-base: "Futura PT", sans-serif;
$font-family-headings: $font-family-base;

// Font weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Custom
$off-canvas-width: 320px;
$off-canvas-width-xs: 100vw;

// Z-indexes
$z-index-modal: 9999;
$z-index-recaptcha-badge: $z-index-modal + 1;

$z-index-header: 99;
$z-index-overlay: $z-index-header - 2;
$z-index-offcanvas: $z-index-overlay + 1;
$z-index-header-logo: $z-index-offcanvas + 1;
$z-index-header-burger: $z-index-offcanvas + 1;
$z-index-mini-cart: $z-index-header - 1;
$z-index-sticky: $z-index-header - 1;
$z-index-tooltip: $z-index-header - 1;
$z-index-city-popup: $z-index-header + 1;

:root {
    @include vw-large-down {
        --header-height: 80px;
    }

    @include vw-header-small-down {
        --header-height: 64px;
    }
}
