// This file is to override UIKit mixins

// Base
@mixin hook-base-link {
    transition-property: color, opacity;
    transition: 0.25s ease-in-out;
}

// Forms
@mixin hook-form {
    // color & background set in uikit
    border: 1px $form-border-color solid;
    border-radius: $form-border-radius;
    font-size: $form-font-size;
    font-weight: $font-weight-medium;
    transition: 0.25s ease-in-out;
    transition-property: color, border-color, background-color;

    &:hover {
        color: $form-hover-color;
        background-color: $form-hover-background;
        border-color: $form-hover-border-color;
    }
}

@mixin hook-form-focus {
    // color & background set in uikit
    border-color: $form-focus-border-color;
}

@mixin hook-form-disabled {
    // color & background set in uikit
    border-color: $form-disabled-border-color;
}

// hack for select
:root .uk-select:not([multiple]):not([size]) {
    background-image: var(--form-default-dropdown-arrow-source);
}

@mixin hook-form-multi-line {
    padding: 16px $form-padding-horizontal;
    min-height: 120px;
    border-radius: $form-border-radius;
}

@mixin hook-form-radio {
    transition: 0.25s ease-in-out;
    transition-property: background-color, border-color;
    border: 2px $color-black solid;

    &:hover,
    &:focus {
        border-color: $color-gray;
    }

    &:checked {
        border-color: $color-black;
    }
}

// Modals
@mixin hook-modal-close-default {
    @include button-base;
    @include button-light-gray;

    top: $modal-close-position-xs;
    right: $modal-close-position-xs;
    padding: 10px;
    border-radius: 2px;

    transition: 0.25s ease-in-out;
    transition-property: color, background-color;

    @include vw-small-down {
        padding: 12px;
    }

    @include vw-xsmall-down {
        top: $modal-close-position;
        right: $modal-close-position;
    }

    svg {
        size: 12px;

        & > line {
            stroke-width: 2;
            stroke-linecap: round;
        }
    }
}

// Accordion
@mixin hook-accordion {
    --accordion-padding-horizontal: 16px;

    > * {
        border-radius: 2px;
        background-color: white;
        box-shadow: 0 8px 30px -10px rgba($color-black, 0.3);
        transition: box-shadow 0.25s ease-in-out;

        &:hover {
            box-shadow: 0 6px 20px -8px rgba($color-black, 0.5);
        }
    }

    > *.uk-open {
        .uk-accordion-title::after {
            transform: scaleY(0);
        }
    }
}

@mixin hook-accordion-title {
    min-height: 48px;
    padding-y: 8px;
    position: relative;
    transition-property: background-color, color;
    transition: 0.25s ease-in-out;

    &::before,
    &::after {
        content: "";
        display: block;
        background-color: $color-yellow--dark;
        position: absolute;
    }

    // horizontal line
    &::before {
        width: 14px;
        height: 2px;
        left: var(--accordion-padding-horizontal);
        top: calc(50% - 1px);
        transform: translateY(-50%);
    }

    // vertical line
    &::after {
        top: calc(50% - 8px);
        left: calc(var(--accordion-padding-horizontal) + 6px);
        width: 2px;
        height: 14px;
        transform: scaleY(1);
        transition: transform 0.25s ease-in-out;
    }
}

@mixin hook-accordion-content {
    padding-x: var(--accordion-padding-horizontal);
}

// Tooltip
@mixin hook-tooltip {
    padding: 8px 12px;
    border-radius: 4px;
    min-width: 270px;
    line-height: 1.28;

    ul {
        list-style: disc;
        padding-left: 16px;

        li {
            margin-top: 2px;
        }
    }
}
