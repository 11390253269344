// Custom medias
$breakpoint-header-s: 800px;
$breakpoint-search-details-xs: 500px;
$breakpoint-products-slider-s: 750px;
$breakpoint-product-m: 780px;
$breakpoint-physic-cart-item-s: 500px;
$breakpoint-legal-account-navigation-s: 800px;
$breakpoint-orders-list-m: 800px;
$breakpoint-product-card-s: 700px;
$breakpoint-product-card-xs: 560px;
$breakpoint-catalog-m: 780px;
$breakpoint-orders-list-s: 700px;

@mixin vw-header-small-down {
    @media (max-width: $breakpoint-header-s - 1) {
        @content;
    }
}

@mixin vw-header-small-up {
    @media (min-width: $breakpoint-header-s) {
        @content;
    }
}

@mixin vw-search-details-xsmall-down {
    @media (max-width: $breakpoint-search-details-xs - 1) {
        @content;
    }
}

@mixin vw-search-details-xsmall-up {
    @media (min-width: $breakpoint-search-details-xs) {
        @content;
    }
}

@mixin vw-products-slider-small-down {
    @media (max-width: $breakpoint-products-slider-s - 1) {
        @content;
    }
}

@mixin vw-products-slider-small-up {
    @media (min-width: $breakpoint-products-slider-s) {
        @content;
    }
}

@mixin vw-product-medium-down {
    @media (max-width: $breakpoint-product-m - 1) {
        @content;
    }
}

@mixin vw-product-medium-up {
    @media (min-width: $breakpoint-product-m) {
        @content;
    }
}

@mixin vw-physic-cart-item-price-column {
    @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge - 1),
        (min-width: $breakpoint-small) and (max-width: $breakpoint-medium - 1) {
        @content;
    }
}

@mixin vw-physic-cart-item-small-down {
    @media (max-width: $breakpoint-physic-cart-item-s - 1) {
        @content;
    }
}

@mixin vw-physic-cart-item-small-up {
    @media (min-width: $breakpoint-physic-cart-item-s) {
        @content;
    }
}

@mixin vw-legal-account-navigation-small-down {
    @media (max-width: $breakpoint-legal-account-navigation-s - 1) {
        @content;
    }
}

@mixin vw-legal-account-navigation-small-up {
    @media (min-width: $breakpoint-legal-account-navigation-s) {
        @content;
    }
}

@mixin vw-orders-list-medium-down {
    @media (max-width: $breakpoint-orders-list-m - 1) {
        @content;
    }
}

@mixin vw-orders-list-medium-only {
    @media (min-width: $breakpoint-small) and (max-width: $breakpoint-orders-list-m - 1) {
        @content;
    }
}

@mixin vw-orders-list-medium-up {
    @media (min-width: $breakpoint-orders-list-m) {
        @content;
    }
}

@mixin vw-product-card-small-down {
    @media (max-width: $breakpoint-product-card-s - 1) {
        @content;
    }
}

@mixin vw-product-card-small-up {
    @media (min-width: $breakpoint-product-card-s) {
        @content;
    }
}

@mixin vw-product-card-xsmall-down {
    @media (max-width: $breakpoint-product-card-xs - 1) {
        @content;
    }
}

@mixin vw-product-card-xsmall-up {
    @media (min-width: $breakpoint-product-card-xs) {
        @content;
    }
}

@mixin vw-catalog-medium-down {
    @media (max-width: $breakpoint-catalog-m - 1) {
        @content;
    }
}

@mixin vw-catalog-medium-up {
    @media (min-width: $breakpoint-catalog-m) {
        @content;
    }
}

@mixin vw-orders-list-small-down {
    @media (max-width: $breakpoint-orders-list-s - 1) {
        @content;
    }
}

@mixin vw-orders-list-small-up {
    @media (min-width: $breakpoint-orders-list-s) {
        @content;
    }
}
