$fields-gap-vertical: 24px;
$fields-gap-horizontal: 24px;
$fields-gap-vertical-s: 20px;
$fields-gap-horizontal-s: 24px;

$form-error-color: $color-red;
$form-required-star-color: $color-red;
$form-agreement-color: $color-gray;

$form-label-font-size: $form-font-size;

$form-default-schema: (
    default: (
        label-color: rgba($color-gray, 0.7),
        text-color: $color-black,
        background: white,
        border: $color-gray,
        option-color: $color-black,
        option-background: white,
        dropdown-arrow-source: getColoredArrow($color-black),
    ),
    hover: (
        label-color: $color-black,
        text-color: $color-black,
        background: white,
        border: $color-gray,
        option-color: $color-black,
        option-background: $color-gray--lighter,
    ),
    active: (
        label-color: $color-black,
        text-color: $color-black,
        background: white,
        border: $color-gray,
        option-color: $color-yellow--dark,
        option-background: white,
    ),
    disabled: (
        label-color: $color-gray,
        text-color: $color-gray,
        background: $color-gray--light,
        border: $color-gray--light,
    ),
);

$form-dark-schema: (
    default: (
        text-color: white,
        label-color: white,
        background: $color-gray--dark,
        border: $color-gray--dark,
        option-color: white,
        option-background: $color-gray--dark,
        dropdown-arrow-source: getColoredArrow($color-yellow--dark),
    ),
    hover: (
        text-color: white,
        label-color: white,
        background: lighten($color-gray--dark, 5%),
        border: lighten($color-gray--dark, 5%),
        option-color: white,
        option-background: lighten($color-gray--dark, 5%),
    ),
    active: (
        text-color: white,
        label-color: white,
        background: $color-gray--dark,
        border: $color-yellow--dark,
        option-color: $color-yellow--dark,
        option-background: $color-gray--dark,
    ),
    disabled: (
        text-color: $color-gray,
        label-color: $color-gray,
        background: $color-gray--light,
        border: $color-gray--light,
    ),
);

$form-black-schema: (
    default: (
        text-color: $color-yellow,
        label-color: $color-yellow,
        background: $color-black,
        border: $color-yellow,
        option-color: white,
        option-background: $color-black,
        dropdown-arrow-source: getColoredArrow($color-yellow),
    ),
    hover: (
        text-color: $color-yellow,
        label-color: $color-yellow,
        background: darken($color-gray--darkest, 5%),
        border: $color-yellow,
        option-color: white,
        option-background: darken($color-gray--darkest, 5%),
    ),
    active: (
        text-color: $color-yellow,
        label-color: $color-yellow,
        background: darken($color-gray--darkest, 5%),
        border: $color-yellow,
        option-color: $color-yellow,
        option-background: $color-black,
    ),
    disabled: (
        text-color: $color-gray,
        label-color: $color-gray,
        background: $color-black,
        border: $color-gray,
    ),
);

$form-no-border-schema: (
    default: (
        border: transparent,
    ),
    hover: (
        border: transparent,
    ),
    active: (
        border: transparent,
    ),
    disabled: (
        border: transparent,
    ),
);

.SiteForm {
    $block: &;

    $form-small-width: 440px;
    $form-medium-width: 520px;
    $form-large-width: 720px;

    @include custom-schema("--form", $form-default-schema);

    --fields-gap-vertical: #{$fields-gap-vertical};
    --fields-gap-horizontal: #{$fields-gap-horizontal};

    @include vw-small-down {
        --fields-gap-vertical: #{$fields-gap-vertical-s};
        --fields-gap-horizontal: #{$fields-gap-horizontal-s};
    }

    &--dark {
        @include custom-schema("--form", $form-dark-schema);
    }

    &--black {
        @include custom-schema("--form", $form-black-schema);
    }

    &--noBorder {
        @include custom-schema("--form", $form-no-border-schema);
    }

    &--smallWidth {
        max-width: $form-small-width;
    }

    &--mediumWidth {
        max-width: $form-medium-width;
    }

    &--largeWidth {
        max-width: $form-large-width;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin: calc(-1 * var(--fields-gap-vertical) / 2) calc(-1 * var(--fields-gap-horizontal) / 2);

        &--smallWidth {
            max-width: calc(#{$form-small-width} + var(--fields-gap-horizontal));
        }

        &--mediumWidth {
            max-width: calc(#{$form-medium-width} + var(--fields-gap-horizontal));
        }

        &--largeWidth {
            max-width: calc(#{$form-large-width} + var(--fields-gap-horizontal));
        }

        &--smallHorizontalGap {
            --fields-gap-horizontal: 8px;
        }

        &--checkboxesList {
            --fields-gap-vertical: 20px;
        }

        &--fieldWithButton {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 8px;

            @include vw-small-down {
                grid-template-columns: auto;
            }
        }
    }

    &__row {
        display: flex;
    }

    &__cell {
        $el: &;

        width: calc(100% - var(--fields-gap-horizontal));
        position: relative;
        margin: calc(var(--fields-gap-vertical) / 2) calc(var(--fields-gap-horizontal) / 2);
        text-align: left;

        &--col-1-2 {
            @include vw-small-up {
                width: calc(50% - var(--fields-gap-horizontal));
            }
        }

        &--col-1-3 {
            @include vw-xsmall-up {
                width: calc(50% - var(--fields-gap-horizontal));
            }

            @include vw-small-up {
                width: calc(33.3333% - var(--fields-gap-horizontal));
            }
        }

        &--col-3-5 {
            @include vw-small-up {
                width: calc(60% - var(--fields-gap-horizontal));
            }
        }

        &--withButton {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-column-gap: 8px;
        }
    }

    &__cellButton {
        @include vw-small-down {
            min-width: unset;
        }
    }

    &__section {
        margin-bottom: 64px;

        @include vw-xlarge-down {
            margin-bottom: 56px;
        }

        @include vw-large-down {
            margin-bottom: 48px;
        }

        @include vw-small-down {
            margin-bottom: 40px;
        }

        &--noMargin {
            margin: 0;
        }

        &--largeWidth {
            max-width: $form-large-width;
        }
    }

    &__controlWrapper {
        position: relative;

        &--boldLabel {
            #{$block}__label {
                font-weight: $font-weight-medium;
            }

            .vs__selected {
                font-weight: $font-weight-medium;
            }
        }
    }

    &__input {
        @extend .uk-input;
    }

    &__textarea {
        @extend .uk-textarea;
    }

    &__input,
    &__textarea {
        :root &[readonly] {
            color: darken($color-gray--light, 8%);
        }

        :root &--invalid {
            &,
            &:hover,
            &:focus,
            &:active {
                color: $form-error-color;
            }
        }

        &::placeholder {
            opacity: 0;
            user-select: none;
        }

        // Edge and Internet Explorer
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
            opacity: 0; // works in all browsers!
            user-select: none;
        }

        &:-webkit-autofill {
            &,
            &:hover,
            &:focus,
            &:active {
                -webkit-box-shadow: 0 0 0 1000px var(--form-active-background) inset;
                -webkit-text-fill-color: var(--form-active-text-color);
            }
        }
    }

    &__label {
        font-size: $form-label-font-size;
        color: var(--form-default-label-color);

        &--required {
            &::after {
                content: "*";
                color: $form-required-star-color;
            }
        }
    }

    &--withStaticLabel {
        #{$block}__cell {
            display: flex;
            flex-direction: column;
        }

        #{$block}__controlWrapper {
            display: flex;
            flex-direction: column;
        }

        #{$block}__label {
            order: 1;
            margin-bottom: 8px;
            font-weight: $font-weight-medium;
            line-height: 1.3;
        }

        #{$block}__input,
        #{$block}__textarea {
            order: 2;
        }

        #{$block}__error {
            order: 3;
        }
    }

    &--withDynamicLabel {
        %active-label {
            color: var(--form-active-label-color);
            transform: scale(0.7) translateY(calc(#{$form-height} / -2 - #{$form-label-font-size} / 1.6));
            pointer-events: none;
        }

        %hide-label {
            opacity: 0;
            transform: none;
        }

        #{$block}__label {
            $el: &;

            position: absolute;
            top: calc((#{$form-height} - #{$form-label-font-size}) / 2 - 2px);
            left: $form-padding-horizontal - 3px;
            padding: 2px 4px;
            border-radius: 3px;
            background-color: var(--form-default-background);
            line-height: 1;
            cursor: text;
            white-space: nowrap;
            user-select: none;
            transform-origin: top left;
            transition: 0.25s ease-in-out;
            transition-property: transform, background-color, color, opacity;
            pointer-events: none;

            &--active {
                @extend %active-label;
            }

            &:not(#{$block}__label--showActive) {
                &#{$block}__label--active {
                    @extend %hide-label;
                }
            }
        }

        // for vue-select
        .v-select:hover + #{$block}__label:not(#{$block}__label--active) {
            background-color: var(--form-hover-background);
            color: var(--form-hover-label-color);
        }

        .vs--disabled + #{$block}__label {
            background-color: var(--form-disabled-background);
            color: var(--form-disabled-label-color);
        }

        .vs--open + #{$block}__label {
            @extend %active-label;

            &:not(#{$block}__label--showActive) {
                @extend %hide-label;
            }
        }

        #{$block}__input,
        #{$block}__textarea {
            &:hover + #{$block}__label {
                color: var(--form-hover-label-color);
                background-color: var(--form-hover-background);
            }

            &:not(:placeholder-shown),
            &:focus {
                & + #{$block}__label {
                    @extend %active-label;

                    &:not(&--showActive) {
                        @extend %hide-label;
                    }
                }
            }

            &:focus + #{$block}__label {
                background-color: var(--form-active-background);
            }

            :root &[disabled] + #{$block}__label {
                background-color: var(--form-disabled-background);
                color: var(--form-disabled-label-color);
            }
        }
    }

    &__error {
        display: block;
        position: relative;
        margin-top: 4px;
        font-size: toRem(14px);
        color: $form-error-color;

        &--checkbox {
            margin-top: 0;
        }
    }

    &__footer {
        margin-top: var(--fields-gap-vertical);

        &--withAgreement {
            display: flex;
            align-items: center;

            @include vw-small-down {
                flex-direction: column;
                align-items: flex-start;
            }

            #{$block}__button {
                flex-shrink: 0;
            }
        }

        &--largeTopMargin {
            margin-top: 88px;

            @include vw-xlarge-down {
                margin-top: 80px;
            }

            @include vw-large-down {
                margin-top: 72px;
            }

            @include vw-medium-down {
                margin-top: 64px;
            }

            @include vw-small-down {
                margin-top: 56px;
            }

            @include vw-xsmall-down {
                margin-top: 40px;
            }
        }
    }

    &__agreement {
        margin-top: 24px;
        font-size: toRem(14px);
        line-height: 1.14;
        color: $form-agreement-color;

        @include vw-small-down {
            margin-top: 16px;
        }

        &--withLeftMargin {
            @include vw-small-up {
                margin-top: 0;
                margin-left: 24px;
            }
        }

        a {
            color: inherit;
            text-decoration: underline;

            &:hover,
            &:focus,
            &:active {
                color: $color-orange;
                text-decoration: underline;
            }
        }
    }

    &__tooltip {
        margin-left: 4px;
        color: $form-error-color;
        border-bottom: 1px $form-error-color dashed;
        cursor: help;
    }

    &__title {
        &--modal {
            @extend .SiteModal__title;
        }
    }

    &__subtitle {
        @extend %heading-small;

        margin-bottom: 24px;
        text-align: left;
        color: white;

        @include vw-xsmall-down {
            margin-bottom: 16px;
        }
    }

    &__citiesList {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 24px;
        margin-bottom: 48px;

        @include vw-small-down {
            margin-bottom: 40px;
        }
    }

    &__citiesListItem {
        text-align: left;
    }

    &__citiesListItemLink {
        font-size: toRem(18px);
        line-height: 1.28;
        color: white;

        @include vw-xxsmall-down {
            font-size: toRem(16px);
        }

        &:hover,
        &:focus {
            color: rgba(white, 0.5);
        }

        &:active,
        &.active {
            color: $color-yellow;
        }
    }

    &__citiesApply {
        display: flex;
        flex-direction: column;
        color: white;
    }

    &__citiesApplyLoader {
        margin-top: 2em;
    }
}

.RadioList {
    display: flex;

    @include vw-small-down {
        flex-direction: column;
    }

    &__item {
        &:not(:last-child) {
            @include vw-small-up {
                margin-right: 8px;
            }

            @include vw-small-down {
                margin-bottom: 20px;
            }
        }
    }
}

.Checkbox,
.Radio {
    $block: &;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;

    &__control {
        flex-shrink: 0;
    }

    &__label {
        margin-left: 12px;
        font-weight: $font-weight-medium;
        font-size: toRem(18px);
        color: $color-black;
        transition: color 0.25s ease-in-out;
    }
}

.Radio {
    $block: &;

    background-color: rgba($color-gray, 0.1);
    padding: 16px 24px;

    @include vw-medium-down {
        padding: 12px;
    }

    @include vw-small-down {
        padding: 0;
        background-color: transparent;
    }

    &--disabled {
        pointer-events: none;
    }

    &__control {
        @extend .uk-radio;

        cursor: pointer;

        &:hover:not(:focus):not(:disabled) + #{$block}__label {
            color: $color-gray;
        }

        &:disabled {
            pointer-events: none;

            & + #{$block}__label {
                color: $color-gray;
            }
        }

        :root &:checked {
            background-image: none;
            border-width: 5px;

            @include vw-small-down {
                border-width: 6px;
            }

            & + #{$block}__label {
                color: $color-black;
            }
        }
    }

    &__label {
        line-height: $form-radio-size;
        cursor: pointer;
    }
}

.Checkbox {
    $block: &;

    &--invalid {
        #{$block}__control {
            // override default hover
            &,
            &:hover:not(:checked):not(:focus) {
                border-color: $form-error-color;
            }
        }
    }

    &--disabled {
        pointer-events: none;
    }

    &__control {
        @extend .uk-checkbox;

        :root & {
            size: $form-checkbox-size;
            border-radius: 2px;

            &:checked {
                background-color: $color-black;

                &:focus {
                    background-color: $color-black;
                }
            }

            &:disabled {
                border-color: $color-gray;

                & + #{$block}__label {
                    color: $color-gray;
                }
            }

            &:not(:disabled) {
                &:hover,
                &:focus {
                    & + #{$block}__label {
                        color: $color-gray;
                    }
                }
            }
        }
    }

    &__label {
        line-height: $form-checkbox-size;
    }
}
