.Loader {
    $block: &;

    position: relative;
    width: 100%;
    height: 200px;
    color: $color-yellow--dark;
    display: flex;
    justify-content: center;
    align-items: center;

    &--button {
        height: 24px;
        color: inherit;

        #{$block}__spinner {
            width: 24px;
        }
    }

    &--orderBill {
        height: 24px;
        width: auto;
        margin-left: 16px;

        #{$block}__spinner {
            width: 24px;
        }
    }

    &--dropdown {
        height: 24px;
        color: var(--form-default-label-color);

        #{$block}__spinner {
            width: 24px;
        }
    }

    &--searchDetails {
        height: 160px;

        @include vw-xlarge-down {
            height: 232px;
        }

        @include vw-medium-down {
            height: 296px;
        }

        @include vw-small-down {
            height: 241px;
        }

        @include vw-search-details-xsmall-down {
            height: 445px;
        }
    }

    &--searchDetailsWithoutTabs {
        height: 64px;

        @include vw-xlarge-down {
            height: 136px;
        }

        @include vw-medium-down {
            height: 208px;
        }

        @include vw-small-down {
            height: 200px;
        }

        @include vw-search-details-xsmall-down {
            height: 404px;
        }

        @include vw-xsmall-down {
            height: 380px;
        }
    }

    &--searchTypeDropdown {
        height: 100%;
        color: var(--form-default-label-color);

        #{$block}__spinner {
            width: 24px;
        }
    }

    &--addressForm {
        height: 330px;
        max-width: 440px;
    }

    &--multiselect {
        height: 264px;
        max-width: 500px;
    }

    &__spinner {
        width: 50px;
        animation: 2s linear infinite loader-rotate;
    }

    &__circle {
        animation: 1.4s ease-in-out infinite both loader-animation;
        display: block;
        fill: transparent;
        stroke: currentColor;
        stroke-linecap: round;
        stroke-dasharray: 283;
        stroke-dashoffset: 200;
        stroke-width: 8px;
        transform-origin: 50% 50%;
    }
}

// SVG animation.
@keyframes loader-rotate {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

// Circle animation.
@keyframes loader-animation {
    0%,
    25% {
        stroke-dashoffset: 150;
        transform: rotate(0);
    }

    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }

    100% {
        stroke-dashoffset: 150;
        transform: rotate(360deg);
    }
}
