.LocationPicker {
    $block: &;

    display: flex;
    align-items: center;

    &__caption {
        margin-right: 8px;
        color: $color-gray;
        font-size: toRem(16px);
        white-space: nowrap;

        .uk-sticky.uk-active & {
            @include vw-large-up {
                display: none;
            }
        }
    }

    &__buttonWrapper {
        position: relative;
    }
}
