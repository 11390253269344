// This file is to store global page layout
.Layout {
    $block: &;

    display: flex;
    flex-flow: column;
    position: relative;

    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;

    @include vw-large-down {
        // gap for fixed header
        padding-top: var(--header-height);
    }

    &__header {
        flex-shrink: 0;
    }

    &__main {
        flex-grow: 1;
        &--centered {
            @extend %display-flex-center;

            min-height: 500px;
        }
    }

    &__footer {
        flex-shrink: 0;
    }

    &__section {
        --heading-color: #{$color-gray--darkest};

        // 5 types of paddings

        --section-padding-xxsmall: 40px;
        --section-padding-xsmall: 48px;
        --section-padding-small: 56px;
        --section-padding-medium: 80px;
        --section-padding-large: 106px;
        --section-padding-xlarge: 124px;
        --section-padding-xxlarge: 160px;

        //default
        padding-y: var(--section-padding-small);
        position: relative;

        @include vw-xlarge-down {
            --section-padding-xsmall: 44px;
            --section-padding-small: 52px;
            --section-padding-medium: 72px;
            --section-padding-large: 88px;
            --section-padding-xlarge: 116px;
            --section-padding-xxlarge: 140px;
        }

        @include vw-large-down {
            --section-padding-xxsmall: 36px;
            --section-padding-xsmall: 40px;
            --section-padding-small: 48px;
            --section-padding-medium: 64px;
            --section-padding-large: 80px;
            --section-padding-xlarge: 96px;
            --section-padding-xxlarge: 110px;
        }

        @include vw-medium-down {
            --section-padding-xxsmall: 32px;
            --section-padding-xsmall: 36px;
            --section-padding-small: 44px;
            --section-padding-medium: 56px;
            --section-padding-large: 72px;
            --section-padding-xlarge: 84px;
            --section-padding-xxlarge: 92px;
        }

        @include vw-small-down {
            --section-padding-xxsmall: 32px;
            --section-padding-xsmall: 32px;
            --section-padding-small: 40px;
            --section-padding-medium: 48px;
            --section-padding-large: 64px;
            --section-padding-xlarge: 72px;
            --section-padding-xxlarge: 80px;
        }

        @include vw-xsmall-down {
            --section-padding-xxsmall: 32px;
            --section-padding-xsmall: 32px;
            --section-padding-small: 32px;
            --section-padding-medium: 36px;
            --section-padding-large: 42px;
            --section-padding-xlarge: 46px;
            --section-padding-xxlarge: 52px;
        }

        // xxsmall
        &--paddingTopXxsmall {
            padding-top: var(--section-padding-xxsmall);
        }

        &--paddingBottomXxsmall {
            padding-bottom: var(--section-padding-xxsmall);
        }

        &--paddingXxsmall {
            padding-y: var(--section-padding-xxsmall);
        }

        // xsmall
        &--paddingTopXsmall {
            padding-top: var(--section-padding-xsmall);
        }

        &--paddingBottomXsmall {
            padding-bottom: var(--section-padding-xsmall);
        }

        &--paddingXsmall {
            padding-y: var(--section-padding-xsmall);
        }

        // small
        &--paddingTopSmall {
            padding-top: var(--section-padding-small);
        }

        &--paddingBottomSmall {
            padding-bottom: var(--section-padding-small);
        }

        &--paddingSmall {
            padding-y: var(--section-padding-small);
        }

        // medium
        &--paddingTopMedium {
            padding-top: var(--section-padding-medium);
        }

        &--paddingBottomMedium {
            padding-bottom: var(--section-padding-medium);
        }

        &--paddingMedium {
            padding-y: var(--section-padding-medium);
        }

        // large
        &--paddingTopLarge {
            padding-top: var(--section-padding-large);
        }

        &--paddingBottomLarge {
            padding-bottom: var(--section-padding-large);
        }

        &--paddingLarge {
            padding-y: var(--section-padding-large);
        }

        // xlarge
        &--paddingTopXlarge {
            padding-top: var(--section-padding-xlarge);
        }

        &--paddingBottomXlarge {
            padding-bottom: var(--section-padding-xlarge);
        }

        &--paddingXlarge {
            padding-y: var(--section-padding-xlarge);
        }

        // xxlarge
        &--paddingTopXxlarge {
            padding-top: var(--section-padding-xxlarge);
        }

        &--paddingBottomXxlarge {
            padding-bottom: var(--section-padding-xxlarge);
        }

        &--paddingXxlarge {
            padding-y: var(--section-padding-xxlarge);
        }

        &--noTopPadding {
            padding-top: 0;
        }

        &--noBottomPadding {
            padding-bottom: 0;
        }

        // Combinations
        &--main {
            @extend #{$block}__section--paddingTopXsmall;
            @extend #{$block}__section--paddingBottomXxlarge;
        }

        &--gray {
            --heading-color: #{$color-gray};

            background-color: rgba($color-gray, 0.05);
        }

        &--dark {
            --heading-color: white;

            background-color: $color-gray--darkest;
        }
    }

    &__container {
        @extend .uk-container;

        &--small {
            @extend .uk-container-small;
        }

        &--large {
            @extend .uk-container-large;
        }
    }

    &__title {
        @extend %heading-large;

        color: var(--heading-color, #{$color-gray--darkest});

        margin-bottom: 40px;

        @include vw-xlarge-down {
            margin-bottom: 32px;
        }

        @include vw-medium-down {
            margin-bottom: 24px;
        }

        &--medium {
            @extend %heading-medium;

            margin-bottom: 32px;

            @include vw-xlarge-down {
                margin-bottom: 24px;
            }

            @include vw-medium-down {
                margin-bottom: 16px;
            }
        }

        &--small {
            @extend %heading-small;

            font-weight: $font-weight-semi-bold;
            margin-bottom: 20px;

            @include vw-medium-down {
                margin-bottom: 12px;
            }
        }

        &--weightMedium {
            font-weight: $font-weight-medium;
        }

        &--weightRegular {
            font-weight: $font-weight-regular;
        }

        &--noMargin {
            margin: 0;
        }
    }
}

html[data-scroll-block="true"] {
    overflow: hidden;

    body {
        overflow: hidden;
        touch-action: pan-y pinch-zoom;
    }
}

html[data-scroll-block-mobile="true"] {
    @include vw-large-down {
        overflow: hidden;

        body {
            overflow: hidden;
            touch-action: pan-y pinch-zoom;
        }
    }
}

.d-block{
    display: block;
}
.mb-4{
    margin-bottom: 1rem;
}