// Variables
$custom-notification-success-color: #3cae39 !default;
$custom-notification-error-color: #f22814 !default;
$custom-notification-warning-color: #f78018 !default;
$custom-notification-info-color: #2289e9 !default;

$custom-notification-internal-padding: 24px 32px !default;
$custom-notification-internal-padding-s: 16px 24px !default;
$custom-notification-background: white !default;
$custom-notification-box-shadow: 0 2px 4px rgba(black, 0.2) !default;
$custom-notification-border-top-width: 4px !default;

$custom-notification-text-color: #2b2f2f !default;
$custom-notification-text-line-height: 1.4 !default;
$custom-notification-text-font-size: 1rem !default;

$custom-notification-header-font-size: $custom-notification-text-font-size !default;
$custom-notification-header-font-weight: 500 !default;
$custom-notification-header-margin-bottom: 8px !default;

$custom-notification-close-offset-top: 8px !default;
$custom-notification-close-offset-right: 14px !default;

// Hooks
// hook-custom-notification {}
// hook-custom-notification-header {}
// hook-custom-notification-text {}
// hook-custom-notification-close {}

// hook-custom-notification-success {}
// hook-custom-notification-error {}
// hook-custom-notification-warning {}
// hook-custom-notification-info {}
//

@mixin hook-notification-message {
    padding: $custom-notification-internal-padding;
    background: $custom-notification-background;
    box-shadow: $custom-notification-box-shadow;
    border-top: $custom-notification-border-top-width var(--notification-color)
    solid;

    @include vw-small-down {
        padding: $custom-notification-internal-padding-s;
    }

    @if (mixin-exists(hook-custom-notification)) {
        @include hook-custom-notification();
    }

    :root & {
        color: var(--notification-color);
    }
}

@mixin hook-notification-message-success {
    --notification-color: #{$custom-notification-success-color};

    @if (mixin-exists(hook-custom-notification-success)) {
        @include hook-custom-notification-success();
    }
}

@mixin hook-notification-message-danger {
    --notification-color: #{$custom-notification-error-color};

    @if (mixin-exists(hook-custom-notification-error)) {
        @include hook-custom-notification-error();
    }
}

@mixin hook-notification-message-warning {
    --notification-color: #{$custom-notification-warning-color};

    @if (mixin-exists(hook-custom-notification-warning)) {
        @include hook-custom-notification-warning();
    }
}

@mixin hook-notification-message-primary {
    --notification-color: #{$custom-notification-info-color};

    @if (mixin-exists(hook-custom-notification-info)) {
        @include hook-custom-notification-info();
    }
}

// class used for headers in notifications
.uk-notification-header {
    display: block;
    font-size: $custom-notification-header-font-size;
    font-weight: $custom-notification-header-font-weight;
    color: var(--notification-color);
    margin-bottom: $custom-notification-header-margin-bottom;

    @if (mixin-exists(hook-custom-notification-header)) {
        @include hook-custom-notification-header();
    }
}

.uk-notification-text {
    display: block;
    font-size: $custom-notification-text-font-size;
    color: $custom-notification-text-color;
    line-height: $custom-notification-text-line-height;

    @if (mixin-exists(hook-custom-notification-text)) {
        @include hook-custom-notification-text();
    }
}

@mixin hook-notification-close {
    top: $custom-notification-close-offset-top;
    right: $custom-notification-close-offset-right;

    @media (hover: none) {
        display: block;
    }

    @if (mixin-exists(hook-custom-notification-close)) {
        @include hook-custom-notification-close();
    }
}
