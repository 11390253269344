.SiteModal {
    $block: &;

    :root & {
        margin-y: auto;

        %modal-horizontal-padding {
            padding-x: 56px;

            @include vw-xlarge-down {
                padding-x: 48px;
            }

            @include vw-medium-down {
                padding-x: 32px;
            }

            @include vw-small-down {
                padding-x: 24px;
            }

            @include vw-xsmall-down {
                padding-x: 16px;
            }
        }

        &--small {
            #{$block}__dialog {
                width: 390px;
            }
        }

        &--medium {
            #{$block}__dialog {
                width: 432px;
            }
        }

        &--dark {
            #{$block}__body {
                background-color: $color-black;
            }

            #{$block}__title {
                color: white;
            }

            #{$block}__close {
                @include button-dark-gray;
            }
        }

        &--center {
            text-align: center;
        }

        &__dialog {
            width: 666px;
            border-radius: 2px;
        }

        &__body {
            @extend %modal-horizontal-padding;

            position: relative;
            padding-y: 48px;

            @include vw-xlarge-down {
                padding-y: 40px;
            }

            @include vw-medium-down {
                padding-y: 32px;
            }

            @include vw-xsmall-down {
                padding-y: 24px;
            }

            &--noPaddingBottom {
                padding-bottom: 0;
            }
        }

        &__title {
            @extend %heading-medium;

            margin-bottom: 32px;

            @include vw-medium-down {
                margin-bottom: 24px;
            }

            @include vw-small-down {
                margin-bottom: 20px;
                padding-right: 24px;
            }

            @include vw-xsmall-down {
                padding-right: 32px;
            }

            &--left {
                text-align: left;
            }
        }

        &__footer {
            @extend %modal-horizontal-padding;

            display: grid;
            gap: 8px;
            position: relative;
            background-color: white;
            padding-bottom: 48px;

            @include vw-xlarge-down {
                padding-bottom: 32px;
            }

            @include vw-medium-down {
                padding-bottom: 24px;
            }

            @include vw-xsmall-down {
                padding-bottom: 16px;
            }

            &--withDivider {
                border-top: 1px rgba($color-gray--darkest, 0.2) solid;
                padding-y: 24px;

                @include vw-xsmall-down {
                    padding-y: 16px;
                }
            }
        }
    }
}
