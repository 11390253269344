.FilePicker {
    display: flex;
    flex-direction: column;

    &__itemsList {
        margin-top: 24px;
    }

    &__item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__input {
        width: 1px;
        height: 1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    &__removeButton {
        @extend %display-flex-center;

        size: 20px;
        background-color: rgba($color-gray, 0.1);
        border: none;
        border-radius: 2px;
        outline: none;
        color: $color-black;
        cursor: pointer;

        transition-property: color, background;
        transition: 0.25s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            outline: none;
            background-color: rgba($color-red, 0.1);
            color: $color-red;
        }
    }

    &__removeButtonText {
        position: relative;
        line-height: 1;
        transform: rotate(45deg);
        font-size: toRem(24px);
    }

    &__filename {
        margin-left: 12px;
        font-size: toRem(18px);
        line-height: 1.28;
        color: $color-gray--dark;
    }
}
