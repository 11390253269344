.OffCanvasOverlay {
    @extend %overlay;

    .LayoutTrigger--mobileOffcanvas:checked ~ .Layout & {
        opacity: 1;
        z-index: $z-index-overlay;
    }
}

.OffCanvas {
    $offcanvas-background: white;

    position: fixed;
    top: var(--header-height);
    left: -$off-canvas-width;
    bottom: 0;
    width: $off-canvas-width;
    max-width: 100vw;
    background-color: $offcanvas-background;
    transition: transform 0.25s ease-in-out;
    z-index: $z-index-offcanvas;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    @include vw-xsmall-down {
        left: -$off-canvas-width-xs;
        width: $off-canvas-width-xs;
    }

    .LayoutTrigger--mobileOffcanvas:checked ~ .Layout & {
        transform: translateX(100%);
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        z-index: $z-index-offcanvas;
        background-color: $offcanvas-background;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    &__locationPicker,
    &__nav,
    &__search,
    &__bottom {
        @include off-canvas-layout;
    }

    &__locationPicker {
        padding-y: 24px 16px;
    }

    &__nav {
        padding-y: 16px 24px;
    }

    &__search {
        padding-y: 16px;

        @include vw-header-small-up {
            display: none;
        }
    }

    &__bottom {
        margin-top: auto;
        padding-y: 0 48px;
    }

    &__phone,
    &__logout {
        font-weight: $font-weight-semi-bold;
        font-size: toRem(16px);
        line-height: 1.4;
        color: $color-black;

        margin-bottom: 24px;
    }

    &__button:not(:last-child) {
        margin-bottom: 16px;
    }
}
