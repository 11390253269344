.ProductStatus {
    font-size: inherit;
    font-weight: 500;
    color: $color-gray;

    &--available {
        color: $color-green;
    }

    &--onOrder {
        color: $color-orange;
    }

    &--unavailable {
        color: $color-red;
    }
}
