@charset "UTF-8";
@media (max-width: 999px) {
  :root {
    --header-height: 80px;
  }
}
@media (max-width: 799px) {
  :root {
    --header-height: 64px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
textarea {
  resize: vertical;
}

iframe {
  max-width: 100%;
}

fieldset {
  min-width: 0;
}

th {
  font-weight: inherit;
  text-align: inherit;
}

.show-for-sr {
  position: absolute;
  top: 0;
  visibility: hidden;
  size: 1px;
}

.no-js .show-for-js {
  display: none;
}

.js .hide-for-js {
  display: none;
}

.responsiveEmbed {
  position: relative;
  padding: 0 0 56.25%;
}
.responsiveEmbed iframe {
  position: absolute;
  top: 0;
  left: 0;
  size: 100% !important;
}

:root {
  --form-height: 48px;
  --form-font-size: 1rem;
  --form-radio-size: 16px;
  --form-default-dropdown-arrow-source: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%228%22%20viewBox%3D%220%200%2024%208%22%20fill%3D%22none%22%20stroke%3D%22%23131818%22%20stroke-width%3D%222%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1%201L6%206L11%201%22%2F%3E%0A%3C%2Fsvg%3E");
  --container-padding-horizontal: 40px;
}
@media (max-width: 999px) {
  :root {
    --header-height: 80px;
  }
}
@media (max-width: 899px) {
  :root {
    --container-padding-horizontal: 32px;
  }
}
@media (max-width: 799px) {
  :root {
    --header-height: 64px;
  }
}
@media (max-width: 639px) {
  :root {
    --container-padding-horizontal: 16px;
    --form-radio-size: 20px;
  }
}
@media (max-width: 424px) {
  :root {
    --form-height: 44px;
  }
}

.LoginForm__socialsItemLink, .FilePicker__removeButton, .CartWidget__cartAmount, .CartWidget__icon, .SiteFooter__phoneIcon, .SiteHeader__phoneIcon, .Layout__main--centered, .Button__icon, .Button--accordion .Button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MiniCartItem__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.MiniCartItem__image {
  object-fit: cover;
}

.OffCanvasOverlay, .CityPopupOverlay {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.15s linear, z-index 0.15s linear;
  z-index: -1;
}

.MiniCartItem__placeholder {
  position: absolute;
  width: 62px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  vertical-align: middle;
  appearance: none;
  min-width: 162px;
  height: 48px;
  min-height: 40px;
  margin: 0;
  padding: 0 24px;
  font: inherit;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  transition: 0.25s ease-in-out;
  transition-property: color, border, background-color;
  color: var(--button-default-color);
  border-color: var(--button-default-border);
  background-color: var(--button-default-background);
}
.Button .Button__icon {
  color: var(--button-default-icon-color, currentColor);
}
.Button:hover {
  color: var(--button-hover-color);
  border-color: var(--button-hover-border);
  background-color: var(--button-hover-background);
}
.Button:hover .Button__icon {
  color: var(--button-hover-icon-color, currentColor);
}
.Button:focus {
  color: var(--button-focus-color);
  border-color: var(--button-focus-border);
  background-color: var(--button-focus-background);
}
.Button:focus .Button__icon {
  color: var(--button-focus-icon-color, currentColor);
}
.Button:active, .Button.active {
  color: var(--button-active-color);
  border-color: var(--button-active-border);
  background-color: var(--button-active-background);
}
.Button:active .Button__icon, .Button.active .Button__icon {
  color: var(--button-active-icon-color, currentColor);
}
.Button:disabled {
  color: var(--button-disabled-color);
  border-color: var(--button-disabled-border);
  background-color: var(--button-disabled-background);
}
.Button:disabled .Button__icon {
  color: var(--button-disabled-icon-color, currentColor);
}
@media (max-width: 424px) {
  .Button {
    height: 44px;
    font-size: 1rem;
  }
}
@media (max-width: 369px) {
  .Button {
    width: 100%;
  }
}
.Button:hover, .Button:focus, .Button:active {
  outline: none;
}
.Button:not(:disabled) {
  cursor: pointer;
}
.Button--noEvents {
  pointer-events: none;
}
:root .Button--loading .Button__spinner {
  display: block;
}
:root .Button--loading .Button__text {
  visibility: hidden;
}
.Button--active {
  color: var(--button-active-color);
  border-color: var(--button-active-border);
  background-color: var(--button-active-background);
  pointer-events: none;
}
.Button--active .Button__icon {
  color: var(--button-active-icon-color, currentColor);
}
.Button--medium {
  min-height: 40px;
  height: 40px;
  font-size: 1rem;
}
@media (min-width: 370px) {
  .Button--wide {
    width: 256px;
    max-width: 100%;
  }
}
.Button--fullWidth {
  width: 100%;
}
.Button--black {
  --button-default-color: white;
  --button-default-border: #131818;
  --button-default-background: #131818;
  --button-hover-color: white;
  --button-hover-border: #464a4a;
  --button-hover-background: #464a4a;
  --button-focus-color: white;
  --button-focus-border: #464a4a;
  --button-focus-background: #464a4a;
  --button-active-color: white;
  --button-active-border: #2b2f2f;
  --button-active-background: #2b2f2f;
  --button-disabled-color: rgba(101, 104, 104, 0.5);
  --button-disabled-border: transparent;
  --button-disabled-background: rgba(101, 104, 104, 0.1);
}
.Button--white {
  --button-default-color: #656868;
  --button-default-border: white;
  --button-default-background: white;
  --button-hover-color: #656868;
  --button-hover-border: rgba(101, 104, 104, 0.05);
  --button-hover-background: rgba(101, 104, 104, 0.05);
  --button-focus-color: #656868;
  --button-focus-border: rgba(101, 104, 104, 0.2);
  --button-focus-background: rgba(101, 104, 104, 0.2);
  --button-active-color: #656868;
  --button-active-border: rgba(101, 104, 104, 0.2);
  --button-active-background: rgba(101, 104, 104, 0.2);
  --button-disabled-color: #656868;
  --button-disabled-border: white;
  --button-disabled-background: white;
}
.Button--gray {
  --button-default-color: white;
  --button-default-border: #464a4a;
  --button-default-background: #464a4a;
  --button-hover-color: #fccf17;
  --button-hover-border: #383b3b;
  --button-hover-background: #383b3b;
  --button-focus-color: #fccf17;
  --button-focus-border: #383b3b;
  --button-focus-background: #383b3b;
  --button-active-color: #fccf17;
  --button-active-border: #2b2f2f;
  --button-active-background: #2b2f2f;
  --button-disabled-color: rgba(255, 255, 255, 0.8);
  --button-disabled-border: transparent;
  --button-disabled-background: rgba(70, 74, 74, 0.4);
}
.Button--yellow {
  --button-default-color: #131818;
  --button-default-border: #fccf17;
  --button-default-background: #fccf17;
  --button-hover-color: #131818;
  --button-hover-border: #e39e00;
  --button-hover-background: #e39e00;
  --button-focus-color: #131818;
  --button-focus-border: #e39e00;
  --button-focus-background: #e39e00;
  --button-active-color: white;
  --button-active-border: #e39e00;
  --button-active-background: #e39e00;
  --button-disabled-color: rgba(101, 104, 104, 0.5);
  --button-disabled-border: transparent;
  --button-disabled-background: rgba(101, 104, 104, 0.1);
}
.Button--yellowText {
  --button-default-color: #fccf17;
  --button-default-border: transparent;
  --button-default-background: transparent;
  --button-hover-color: #fccf17;
  --button-hover-border: #383b3b;
  --button-hover-background: #383b3b;
  --button-focus-color: #fccf17;
  --button-focus-border: #383b3b;
  --button-focus-background: #383b3b;
  --button-active-color: #fccf17;
  --button-active-border: #383b3b;
  --button-active-background: #383b3b;
  --button-disabled-color: rgba(252, 207, 23, 0.4);
  --button-disabled-border: transparent;
  --button-disabled-background: transparent;
}
.Button--darkYellowText {
  --button-default-color: #e39e00;
  --button-default-border: transparent;
  --button-default-background: transparent;
  --button-hover-color: rgba(227, 158, 0, 0.7);
  --button-hover-border: transparent;
  --button-hover-background: transparent;
  --button-focus-color: rgba(227, 158, 0, 0.7);
  --button-focus-border: transparent;
  --button-focus-background: transparent;
  --button-active-color: rgba(227, 158, 0, 0.7);
  --button-active-border: transparent;
  --button-active-background: transparent;
  --button-disabled-color: rgba(227, 158, 0, 0.4);
  --button-disabled-border: transparent;
  --button-disabled-background: transparent;
}
.Button--blackOutline {
  --button-default-color: #131818;
  --button-default-border: #131818;
  --button-default-background: white;
  --button-hover-color: white;
  --button-hover-border: #464a4a;
  --button-hover-background: #464a4a;
  --button-focus-color: white;
  --button-focus-border: #464a4a;
  --button-focus-background: #464a4a;
  --button-active-color: white;
  --button-active-border: #2b2f2f;
  --button-active-background: #2b2f2f;
  --button-disabled-color: #464a4a;
  --button-disabled-border: #464a4a;
  --button-disabled-background: white;
}
.Button--outlineYellow {
  --button-default-color: #fccf17;
  --button-default-border: #fccf17;
  --button-default-background: transparent;
  --button-hover-color: #131818;
  --button-hover-border: #fccf17;
  --button-hover-background: #fccf17;
  --button-focus-color: #131818;
  --button-focus-border: #fccf17;
  --button-focus-background: #fccf17;
  --button-active-color: #131818;
  --button-active-border: #fccf17;
  --button-active-background: #fccf17;
  --button-disabled-color: rgba(255, 255, 255, 0.4);
  --button-disabled-border: rgba(255, 255, 255, 0.4);
  --button-disabled-background: transparent;
}
.Button--outlineWhite {
  --button-default-color: white;
  --button-default-border: white;
  --button-default-background: transparent;
  --button-hover-color: #131818;
  --button-hover-border: white;
  --button-hover-background: white;
  --button-focus-color: #131818;
  --button-focus-border: white;
  --button-focus-background: white;
  --button-active-color: #131818;
  --button-active-border: white;
  --button-active-background: white;
  --button-disabled-color: rgba(255, 255, 255, 0.4);
  --button-disabled-border: rgba(255, 255, 255, 0.4);
  --button-disabled-background: transparent;
}
.Button--lightGray {
  --button-default-color: #2b2f2f;
  --button-default-background: rgba(101, 104, 104, 0.1);
  --button-hover-color: #2b2f2f;
  --button-hover-background: rgba(101, 104, 104, 0.2);
  --button-focus-color: #2b2f2f;
  --button-focus-background: rgba(101, 104, 104, 0.2);
  --button-active-color: #2b2f2f;
  --button-active-background: rgba(101, 104, 104, 0.3);
}
.Button--pagination {
  min-width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  font-size: 1.375rem;
  line-height: 40px;
}
.Button--empty {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  height: auto;
  min-width: 40px;
}
.Button--link, .Button--underlinedLinkNoUnderlinedIcon, .Button--underlinedLink {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  height: auto;
  min-width: 40px;
  --button-default-color: #e39e00;
  --button-hover-color: rgba(101, 104, 104, 0.7);
  --button-focus-color: #ca8c00;
  --button-active-color: #131818;
  min-height: auto;
}
.Button--underlinedLink {
  width: fit-content;
  border-bottom: 1px currentColor dashed;
}
.Button--underlinedLink.active {
  border-bottom: none;
}
.Button--underlinedLinkNoUnderlinedIcon {
  width: fit-content;
}
.Button--underlinedLinkNoUnderlinedIcon .Button__text {
  border-bottom: 1px currentColor dashed;
}
.Button--accordion.active .Button__icon::after {
  transform: scaleY(0);
}
.Button--accordion:not(.active) + * {
  display: none;
}
.Button--accordion + * {
  margin-top: 32px;
}
@media (max-width: 639px) {
  .Button--accordion + * {
    margin-top: 24px;
  }
}
.Button--accordion .Button__icon {
  position: relative;
}
.Button--accordion .Button__icon::before, .Button--accordion .Button__icon::after {
  content: "";
  background-color: currentColor;
  position: absolute;
}
.Button--accordion .Button__icon::before {
  width: 12px;
  height: 2px;
}
.Button--accordion .Button__icon::after {
  width: 2px;
  height: 12px;
  transition: transform 0.25s ease-in-out;
}
.Button--remove {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  height: auto;
  min-width: 40px;
  position: absolute;
  size: 40px;
  color: #2b2f2f;
}
.Button--remove:hover, .Button--remove:focus {
  color: #131818;
}
.Button--remove:hover .Button__icon, .Button--remove:focus .Button__icon {
  background-color: rgba(101, 104, 104, 0.2);
}
.Button--remove:active {
  color: #131818;
}
.Button--remove:active .Button__icon {
  background-color: rgba(101, 104, 104, 0.3);
}
.Button--remove .Button__icon {
  size: 24px;
  padding: 6px;
  background-color: rgba(101, 104, 104, 0.1);
  border-radius: 2px;
  transition: background-color 0.25s ease-in-out;
}
.Button--remove .Button__icon svg {
  width: 12px;
}
.Button--favorite {
  --button-default-color: #656868;
  --button-default-background: rgba(101, 104, 104, 0.05);
  --button-hover-background: rgba(101, 104, 104, 0.1);
  --button-focus-background: rgba(101, 104, 104, 0.1);
  --button-active-color: #e39e00;
  --button-active-background: rgba(252, 207, 23, 0.3);
  min-width: 48px;
  padding: 0;
  border: none;
  width: auto;
}
.Button--favorite.liked {
  --button-default-color: #e39e00;
  --button-default-background: rgba(252, 207, 23, 0.3);
  --button-hover-color: #e39e00;
  --button-hover-background: rgba(252, 207, 23, 0.3);
  --button-focus-color: #e39e00;
  --button-focus-background: rgba(252, 207, 23, 0.3);
  --button-active-color: #656868;
  --button-active-background: rgba(101, 104, 104, 0.05);
}
.Button--favorite .Button__icon {
  size: 16px;
}
.Button--small {
  min-width: 120px;
}
.Button__icon + .Button__text, .Button__text + .Button__icon {
  margin-left: 8px;
}
.Button__icon {
  position: relative;
  flex-shrink: 0;
  width: 12px;
}
.Button__icon--largeGap + .Button__text, .Button__text + .Button__icon--largeGap {
  margin-left: 12px;
}
@media (max-width: 639px) {
  .Button__icon--largeGap + .Button__text, .Button__text + .Button__icon--largeGap {
    margin-left: 10px;
  }
}
.Button__icon--medium {
  width: 14px;
}
.Button__icon--large {
  width: 16px;
}
@media (max-width: 639px) {
  .Button__icon--large {
    width: 14px;
  }
}
.Button__icon svg {
  width: 100%;
  fill: currentColor;
}
.Button__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
:root .Button__spinner {
  display: none;
}

.ButtonsGroup {
  display: flex;
}
@media (max-width: 424px) {
  .ButtonsGroup {
    flex-direction: column;
  }
  .ButtonsGroup .ButtonsGroup__buttonWrapper {
    width: 100%;
  }
}
.ButtonsGroup--fullWidth .ButtonsGroup__buttonWrapper {
  width: 100%;
}
.ButtonsGroup--fullWidth .ButtonsGroup__buttonWrapper > * {
  width: 100%;
}
.ButtonsGroup--column {
  flex-direction: column;
}
.ButtonsGroup--column .ButtonsGroup__buttonWrapper:not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 899px) {
  .ButtonsGroup--column .ButtonsGroup__buttonWrapper:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media (min-width: 425px) {
  .ButtonsGroup:not(.ButtonsGroup--column) .ButtonsGroup__buttonWrapper:not(:last-child) {
    margin-right: 24px;
  }
}
@media (min-width: 425px) and (max-width: 899px) {
  .ButtonsGroup:not(.ButtonsGroup--column) .ButtonsGroup__buttonWrapper:not(:last-child) {
    margin-right: 20px;
  }
}
@media (max-width: 424px) {
  .ButtonsGroup:not(.ButtonsGroup--column) .ButtonsGroup__buttonWrapper:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media (max-width: 424px) {
  .ButtonsGroup--rowOnMobile {
    flex-direction: row;
  }
}
@media (max-width: 424px) {
  .ButtonsGroup--rowOnMobile .ButtonsGroup__buttonWrapper:not(:last-child) {
    margin-bottom: 0;
    margin-right: 16px;
  }
}
@media (max-width: 424px) {
  :root .ButtonsGroup--links .ButtonsGroup__buttonWrapper {
    width: fit-content;
  }
}
@media (max-width: 424px) {
  :root .ButtonsGroup--links .ButtonsGroup__buttonWrapper:not(:last-child) {
    margin-bottom: 8px;
  }
}
.ButtonsGroup--justifyBetween {
  justify-content: space-between;
}
.ButtonsGroup--center {
  justify-content: center;
}
.ButtonsGroup--center.ButtonsGroup--column {
  align-items: center;
}
@media (max-width: 424px) {
  .ButtonsGroup__buttonWrapper {
    flex: 1;
  }
  .ButtonsGroup__buttonWrapper > * {
    width: 100%;
  }
}

:root .SiteModal {
  margin-y: auto;
}
:root .SiteModal .SiteModal__footer, :root .SiteModal .SiteModal__body {
  padding-x: 56px;
}
@media (max-width: 1199px) {
  :root .SiteModal .SiteModal__footer, :root .SiteModal .SiteModal__body {
    padding-x: 48px;
  }
}
@media (max-width: 899px) {
  :root .SiteModal .SiteModal__footer, :root .SiteModal .SiteModal__body {
    padding-x: 32px;
  }
}
@media (max-width: 639px) {
  :root .SiteModal .SiteModal__footer, :root .SiteModal .SiteModal__body {
    padding-x: 24px;
  }
}
@media (max-width: 424px) {
  :root .SiteModal .SiteModal__footer, :root .SiteModal .SiteModal__body {
    padding-x: 16px;
  }
}
:root .SiteModal--small .SiteModal__dialog {
  width: 390px;
}
:root .SiteModal--medium .SiteModal__dialog {
  width: 432px;
}
:root .SiteModal--dark .SiteModal__body {
  background-color: #131818;
}
:root .SiteModal--dark .SiteModal__title, :root .SiteModal--dark .SiteForm__title--modal {
  color: white;
}
:root .SiteModal--dark .SiteModal__close {
  --button-default-color: white;
  --button-default-background: #2b2f2f;
  --button-hover-color: white;
  --button-hover-background: #373c3c;
  --button-focus-color: white;
  --button-focus-background: #373c3c;
  --button-active-color: white;
  --button-active-background: #434a4a;
}
:root .SiteModal--center {
  text-align: center;
}
:root .SiteModal__dialog {
  width: 666px;
  border-radius: 2px;
}
:root .SiteModal__body {
  position: relative;
  padding-y: 48px;
}
@media (max-width: 1199px) {
  :root .SiteModal__body {
    padding-y: 40px;
  }
}
@media (max-width: 899px) {
  :root .SiteModal__body {
    padding-y: 32px;
  }
}
@media (max-width: 424px) {
  :root .SiteModal__body {
    padding-y: 24px;
  }
}
:root .SiteModal__body--noPaddingBottom {
  padding-bottom: 0;
}
:root .SiteModal__title, :root .SiteForm__title--modal {
  margin-bottom: 32px;
}
@media (max-width: 899px) {
  :root .SiteModal__title, :root .SiteForm__title--modal {
    margin-bottom: 24px;
  }
}
@media (max-width: 639px) {
  :root .SiteModal__title, :root .SiteForm__title--modal {
    margin-bottom: 20px;
    padding-right: 24px;
  }
}
@media (max-width: 424px) {
  :root .SiteModal__title, :root .SiteForm__title--modal {
    padding-right: 32px;
  }
}
:root .SiteModal__title--left {
  text-align: left;
}
:root .SiteModal__footer {
  display: grid;
  gap: 8px;
  position: relative;
  background-color: white;
  padding-bottom: 48px;
}
@media (max-width: 1199px) {
  :root .SiteModal__footer {
    padding-bottom: 32px;
  }
}
@media (max-width: 899px) {
  :root .SiteModal__footer {
    padding-bottom: 24px;
  }
}
@media (max-width: 424px) {
  :root .SiteModal__footer {
    padding-bottom: 16px;
  }
}
:root .SiteModal__footer--withDivider {
  border-top: 1px rgba(43, 47, 47, 0.2) solid;
  padding-y: 24px;
}
@media (max-width: 424px) {
  :root .SiteModal__footer--withDivider {
    padding-y: 16px;
  }
}

.MiniCart__emptyCartText, .Layout__title--small, .SiteForm__subtitle, .ErrorPage__title, .Layout__title--medium, :root .SiteModal__title, :root .SiteForm__title--modal, .Layout__title {
  font-weight: 600;
  color: #2b2f2f;
  line-height: 1.2;
}

.Layout__title {
  font-size: 2.5rem;
}
@media (max-width: 1199px) {
  .Layout__title {
    font-size: 2.25rem;
  }
}
@media (max-width: 899px) {
  .Layout__title {
    font-size: 2rem;
  }
}
@media (max-width: 639px) {
  .Layout__title {
    font-size: 1.75rem;
  }
}
@media (max-width: 424px) {
  .Layout__title {
    font-size: 1.5rem;
  }
}

.ErrorPage__title, .Layout__title--medium, :root .SiteModal__title, :root .SiteForm__title--modal {
  font-size: 2rem;
}
@media (max-width: 1199px) {
  .ErrorPage__title, .Layout__title--medium, :root .SiteModal__title, :root .SiteForm__title--modal {
    font-size: 1.875rem;
  }
}
@media (max-width: 899px) {
  .ErrorPage__title, .Layout__title--medium, :root .SiteModal__title, :root .SiteForm__title--modal {
    font-size: 1.6875rem;
  }
}
@media (max-width: 639px) {
  .ErrorPage__title, .Layout__title--medium, :root .SiteModal__title, :root .SiteForm__title--modal {
    font-size: 1.5rem;
  }
}
@media (max-width: 424px) {
  .ErrorPage__title, .Layout__title--medium, :root .SiteModal__title, :root .SiteForm__title--modal {
    font-size: 1.375rem;
  }
}

.MiniCart__emptyCartText, .Layout__title--small, .SiteForm__subtitle {
  font-size: 1.375rem;
}
@media (max-width: 639px) {
  .MiniCart__emptyCartText, .Layout__title--small, .SiteForm__subtitle {
    font-size: 1.25rem;
  }
}

.SiteForm {
  --form-default-label-color: rgba(101, 104, 104, 0.7);
  --form-default-text-color: #131818;
  --form-default-background: white;
  --form-default-border: #656868;
  --form-default-option-color: #131818;
  --form-default-option-background: white;
  --form-default-dropdown-arrow-source: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%228%22%20viewBox%3D%220%200%2024%208%22%20fill%3D%22none%22%20stroke%3D%22%23131818%22%20stroke-width%3D%222%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1%201L6%206L11%201%22%2F%3E%0A%3C%2Fsvg%3E");
  --form-hover-label-color: #131818;
  --form-hover-text-color: #131818;
  --form-hover-background: white;
  --form-hover-border: #656868;
  --form-hover-option-color: #131818;
  --form-hover-option-background: #dcdcdc;
  --form-active-label-color: #131818;
  --form-active-text-color: #131818;
  --form-active-background: white;
  --form-active-border: #656868;
  --form-active-option-color: #e39e00;
  --form-active-option-background: white;
  --form-disabled-label-color: #656868;
  --form-disabled-text-color: #656868;
  --form-disabled-background: #c4c4c4;
  --form-disabled-border: #c4c4c4;
  --fields-gap-vertical: 24px;
  --fields-gap-horizontal: 24px;
}
@media (max-width: 639px) {
  .SiteForm {
    --fields-gap-vertical: 20px;
    --fields-gap-horizontal: 24px;
  }
}
.SiteForm--dark {
  --form-default-text-color: white;
  --form-default-label-color: white;
  --form-default-background: #464a4a;
  --form-default-border: #464a4a;
  --form-default-option-color: white;
  --form-default-option-background: #464a4a;
  --form-default-dropdown-arrow-source: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%228%22%20viewBox%3D%220%200%2024%208%22%20fill%3D%22none%22%20stroke%3D%22%23E39E00%22%20stroke-width%3D%222%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1%201L6%206L11%201%22%2F%3E%0A%3C%2Fsvg%3E");
  --form-hover-text-color: white;
  --form-hover-label-color: white;
  --form-hover-background: #525757;
  --form-hover-border: #525757;
  --form-hover-option-color: white;
  --form-hover-option-background: #525757;
  --form-active-text-color: white;
  --form-active-label-color: white;
  --form-active-background: #464a4a;
  --form-active-border: #e39e00;
  --form-active-option-color: #e39e00;
  --form-active-option-background: #464a4a;
  --form-disabled-text-color: #656868;
  --form-disabled-label-color: #656868;
  --form-disabled-background: #c4c4c4;
  --form-disabled-border: #c4c4c4;
}
.SiteForm--black {
  --form-default-text-color: #fccf17;
  --form-default-label-color: #fccf17;
  --form-default-background: #131818;
  --form-default-border: #fccf17;
  --form-default-option-color: white;
  --form-default-option-background: #131818;
  --form-default-dropdown-arrow-source: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%228%22%20viewBox%3D%220%200%2024%208%22%20fill%3D%22none%22%20stroke%3D%22%23FCCF17%22%20stroke-width%3D%222%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1%201L6%206L11%201%22%2F%3E%0A%3C%2Fsvg%3E");
  --form-hover-text-color: #fccf17;
  --form-hover-label-color: #fccf17;
  --form-hover-background: #1f2222;
  --form-hover-border: #fccf17;
  --form-hover-option-color: white;
  --form-hover-option-background: #1f2222;
  --form-active-text-color: #fccf17;
  --form-active-label-color: #fccf17;
  --form-active-background: #1f2222;
  --form-active-border: #fccf17;
  --form-active-option-color: #fccf17;
  --form-active-option-background: #131818;
  --form-disabled-text-color: #656868;
  --form-disabled-label-color: #656868;
  --form-disabled-background: #131818;
  --form-disabled-border: #656868;
}
.SiteForm--noBorder {
  --form-default-border: transparent;
  --form-hover-border: transparent;
  --form-active-border: transparent;
  --form-disabled-border: transparent;
}
.SiteForm--smallWidth {
  max-width: 440px;
}
.SiteForm--mediumWidth {
  max-width: 520px;
}
.SiteForm--largeWidth {
  max-width: 720px;
}
.SiteForm__grid {
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--fields-gap-vertical) / 2) calc(-1 * var(--fields-gap-horizontal) / 2);
}
.SiteForm__grid--smallWidth {
  max-width: calc(440px + var(--fields-gap-horizontal));
}
.SiteForm__grid--mediumWidth {
  max-width: calc(520px + var(--fields-gap-horizontal));
}
.SiteForm__grid--largeWidth {
  max-width: calc(720px + var(--fields-gap-horizontal));
}
.SiteForm__grid--smallHorizontalGap {
  --fields-gap-horizontal: 8px;
}
.SiteForm__grid--checkboxesList {
  --fields-gap-vertical: 20px;
}
.SiteForm__grid--fieldWithButton {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
}
@media (max-width: 639px) {
  .SiteForm__grid--fieldWithButton {
    grid-template-columns: auto;
  }
}
.SiteForm__row {
  display: flex;
}
.SiteForm__cell {
  width: calc(100% - var(--fields-gap-horizontal));
  position: relative;
  margin: calc(var(--fields-gap-vertical) / 2) calc(var(--fields-gap-horizontal) / 2);
  text-align: left;
}
@media (min-width: 640px) {
  .SiteForm__cell--col-1-2 {
    width: calc(50% - var(--fields-gap-horizontal));
  }
}
@media (min-width: 425px) {
  .SiteForm__cell--col-1-3 {
    width: calc(50% - var(--fields-gap-horizontal));
  }
}
@media (min-width: 640px) {
  .SiteForm__cell--col-1-3 {
    width: calc(33.3333% - var(--fields-gap-horizontal));
  }
}
@media (min-width: 640px) {
  .SiteForm__cell--col-3-5 {
    width: calc(60% - var(--fields-gap-horizontal));
  }
}
.SiteForm__cell--withButton {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
}
@media (max-width: 639px) {
  .SiteForm__cellButton {
    min-width: unset;
  }
}
.SiteForm__section {
  margin-bottom: 64px;
}
@media (max-width: 1199px) {
  .SiteForm__section {
    margin-bottom: 56px;
  }
}
@media (max-width: 999px) {
  .SiteForm__section {
    margin-bottom: 48px;
  }
}
@media (max-width: 639px) {
  .SiteForm__section {
    margin-bottom: 40px;
  }
}
.SiteForm__section--noMargin {
  margin: 0;
}
.SiteForm__section--largeWidth {
  max-width: 720px;
}
.SiteForm__controlWrapper {
  position: relative;
}
.SiteForm__controlWrapper--boldLabel .SiteForm__label {
  font-weight: 500;
}
.SiteForm__controlWrapper--boldLabel .vs__selected {
  font-weight: 500;
}
:root .SiteForm__input[readonly], :root .vdpComponent.vdpWithInput > input[readonly], :root .SiteForm__textarea[readonly] {
  color: #b0b0b0;
}
:root .SiteForm__input--invalid, :root .SiteForm__input--invalid:hover, :root .SiteForm__input--invalid:focus, :root .SiteForm__input--invalid:active, :root .SiteForm__textarea--invalid, :root .SiteForm__textarea--invalid:hover, :root .SiteForm__textarea--invalid:focus, :root .SiteForm__textarea--invalid:active {
  color: #f22814;
}
.SiteForm__input::placeholder, .vdpComponent.vdpWithInput > input::placeholder, .SiteForm__textarea::placeholder {
  opacity: 0;
  user-select: none;
}
.SiteForm__input:-ms-input-placeholder, .vdpComponent.vdpWithInput > input:-ms-input-placeholder, .SiteForm__input::-ms-input-placeholder, .vdpComponent.vdpWithInput > input::-ms-input-placeholder, .SiteForm__textarea:-ms-input-placeholder, .SiteForm__textarea::-ms-input-placeholder {
  opacity: 0;
  user-select: none;
}
.SiteForm__input:-webkit-autofill, .vdpComponent.vdpWithInput > input:-webkit-autofill, .SiteForm__input:-webkit-autofill:hover, .SiteForm__input:-webkit-autofill:focus, .SiteForm__input:-webkit-autofill:active, .SiteForm__textarea:-webkit-autofill, .SiteForm__textarea:-webkit-autofill:hover, .SiteForm__textarea:-webkit-autofill:focus, .SiteForm__textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px var(--form-active-background) inset;
  -webkit-text-fill-color: var(--form-active-text-color);
}
.SiteForm__label {
  font-size: var(--form-font-size);
  color: var(--form-default-label-color);
}
.SiteForm__label--required::after {
  content: "*";
  color: #f22814;
}
.SiteForm--withStaticLabel .SiteForm__cell {
  display: flex;
  flex-direction: column;
}
.SiteForm--withStaticLabel .SiteForm__controlWrapper {
  display: flex;
  flex-direction: column;
}
.SiteForm--withStaticLabel .SiteForm__label {
  order: 1;
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 1.3;
}
.SiteForm--withStaticLabel .SiteForm__input, .SiteForm--withStaticLabel .vdpComponent.vdpWithInput > input,
.SiteForm--withStaticLabel .SiteForm__textarea {
  order: 2;
}
.SiteForm--withStaticLabel .SiteForm__error {
  order: 3;
}
.SiteForm--withDynamicLabel .SiteForm__input:not(:placeholder-shown) + .SiteForm__label, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:not(:placeholder-shown) + .SiteForm__label, .SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label,
.SiteForm--withDynamicLabel .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label,
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label, .SiteForm--withDynamicLabel .vs--open + .SiteForm__label, .SiteForm--withDynamicLabel .SiteForm__label--active {
  color: var(--form-active-label-color);
  transform: scale(0.7) translateY(calc(var(--form-height) / -2 - var(--form-font-size) / 1.6));
  pointer-events: none;
}
.SiteForm--withDynamicLabel .SiteForm__input:not(:placeholder-shown) + .SiteForm__label:not(.SiteForm--withDynamicLabel .SiteForm__input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label--showActive), .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:not(:placeholder-shown) + .SiteForm__label:not(.SiteForm--withDynamicLabel .SiteForm__input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label--showActive), .SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label:not(.SiteForm--withDynamicLabel .SiteForm__input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label--showActive), .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label:not(.SiteForm--withDynamicLabel .SiteForm__input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label--showActive),
.SiteForm--withDynamicLabel .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label:not(.SiteForm--withDynamicLabel .SiteForm__input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label--showActive),
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label:not(.SiteForm--withDynamicLabel .SiteForm__input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:not(:placeholder-shown) + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label--showActive, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label--showActive,
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label--showActive), .SiteForm--withDynamicLabel .vs--open + .SiteForm__label:not(.SiteForm__label--showActive), .SiteForm--withDynamicLabel .SiteForm__label:not(.SiteForm__label--showActive).SiteForm__label--active {
  opacity: 0;
  transform: none;
}
.SiteForm--withDynamicLabel .SiteForm__label {
  position: absolute;
  top: calc((var(--form-height) - var(--form-font-size)) / 2 - 2px);
  left: 13px;
  padding: 2px 4px;
  border-radius: 3px;
  background-color: var(--form-default-background);
  line-height: 1;
  cursor: text;
  white-space: nowrap;
  user-select: none;
  transform-origin: top left;
  transition: 0.25s ease-in-out;
  transition-property: transform, background-color, color, opacity;
  pointer-events: none;
}
.SiteForm--withDynamicLabel .v-select:hover + .SiteForm__label:not(.SiteForm__label--active) {
  background-color: var(--form-hover-background);
  color: var(--form-hover-label-color);
}
.SiteForm--withDynamicLabel .vs--disabled + .SiteForm__label {
  background-color: var(--form-disabled-background);
  color: var(--form-disabled-label-color);
}
.SiteForm--withDynamicLabel .SiteForm__input:hover + .SiteForm__label, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:hover + .SiteForm__label,
.SiteForm--withDynamicLabel .SiteForm__textarea:hover + .SiteForm__label {
  color: var(--form-hover-label-color);
  background-color: var(--form-hover-background);
}
.SiteForm--withDynamicLabel .SiteForm__input:focus + .SiteForm__label, .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input:focus + .SiteForm__label,
.SiteForm--withDynamicLabel .SiteForm__textarea:focus + .SiteForm__label {
  background-color: var(--form-active-background);
}
:root .SiteForm--withDynamicLabel .SiteForm__input[disabled] + .SiteForm__label, .SiteForm--withDynamicLabel :root .vdpComponent.vdpWithInput > input[disabled] + .SiteForm__label, :root .SiteForm--withDynamicLabel .vdpComponent.vdpWithInput > input[disabled] + .SiteForm__label,
:root .SiteForm--withDynamicLabel .SiteForm__textarea[disabled] + .SiteForm__label {
  background-color: var(--form-disabled-background);
  color: var(--form-disabled-label-color);
}
.SiteForm__error {
  display: block;
  position: relative;
  margin-top: 4px;
  font-size: 0.875rem;
  color: #f22814;
}
.SiteForm__error--checkbox {
  margin-top: 0;
}
.SiteForm__footer {
  margin-top: var(--fields-gap-vertical);
}
.SiteForm__footer--withAgreement {
  display: flex;
  align-items: center;
}
@media (max-width: 639px) {
  .SiteForm__footer--withAgreement {
    flex-direction: column;
    align-items: flex-start;
  }
}
.SiteForm__footer--withAgreement .SiteForm__button {
  flex-shrink: 0;
}
.SiteForm__footer--largeTopMargin {
  margin-top: 88px;
}
@media (max-width: 1199px) {
  .SiteForm__footer--largeTopMargin {
    margin-top: 80px;
  }
}
@media (max-width: 999px) {
  .SiteForm__footer--largeTopMargin {
    margin-top: 72px;
  }
}
@media (max-width: 899px) {
  .SiteForm__footer--largeTopMargin {
    margin-top: 64px;
  }
}
@media (max-width: 639px) {
  .SiteForm__footer--largeTopMargin {
    margin-top: 56px;
  }
}
@media (max-width: 424px) {
  .SiteForm__footer--largeTopMargin {
    margin-top: 40px;
  }
}
.SiteForm__agreement {
  margin-top: 24px;
  font-size: 0.875rem;
  line-height: 1.14;
  color: #656868;
}
@media (max-width: 639px) {
  .SiteForm__agreement {
    margin-top: 16px;
  }
}
@media (min-width: 640px) {
  .SiteForm__agreement--withLeftMargin {
    margin-top: 0;
    margin-left: 24px;
  }
}
.SiteForm__agreement a {
  color: inherit;
  text-decoration: underline;
}
.SiteForm__agreement a:hover, .SiteForm__agreement a:focus, .SiteForm__agreement a:active {
  color: #e39e00;
  text-decoration: underline;
}
.SiteForm__tooltip {
  margin-left: 4px;
  color: #f22814;
  border-bottom: 1px #f22814 dashed;
  cursor: help;
}
.SiteForm__subtitle {
  margin-bottom: 24px;
  text-align: left;
  color: white;
}
@media (max-width: 424px) {
  .SiteForm__subtitle {
    margin-bottom: 16px;
  }
}
.SiteForm__citiesList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 24px;
  margin-bottom: 48px;
}
@media (max-width: 639px) {
  .SiteForm__citiesList {
    margin-bottom: 40px;
  }
}
.SiteForm__citiesListItem {
  text-align: left;
}
.SiteForm__citiesListItemLink {
  font-size: 1.125rem;
  line-height: 1.28;
  color: white;
}
@media (max-width: 369px) {
  .SiteForm__citiesListItemLink {
    font-size: 1rem;
  }
}
.SiteForm__citiesListItemLink:hover, .SiteForm__citiesListItemLink:focus {
  color: rgba(255, 255, 255, 0.5);
}
.SiteForm__citiesListItemLink:active, .SiteForm__citiesListItemLink.active {
  color: #fccf17;
}
.SiteForm__citiesApply {
  display: flex;
  flex-direction: column;
  color: white;
}
.SiteForm__citiesApplyLoader {
  margin-top: 2em;
}

.RadioList {
  display: flex;
}
@media (max-width: 639px) {
  .RadioList {
    flex-direction: column;
  }
}
@media (min-width: 640px) {
  .RadioList__item:not(:last-child) {
    margin-right: 8px;
  }
}
@media (max-width: 639px) {
  .RadioList__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.Checkbox,
.Radio {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.Checkbox__control,
.Radio__control {
  flex-shrink: 0;
}
.Checkbox__label,
.Radio__label {
  margin-left: 12px;
  font-weight: 500;
  font-size: 1.125rem;
  color: #131818;
  transition: color 0.25s ease-in-out;
}

.Radio {
  background-color: rgba(101, 104, 104, 0.1);
  padding: 16px 24px;
}
@media (max-width: 899px) {
  .Radio {
    padding: 12px;
  }
}
@media (max-width: 639px) {
  .Radio {
    padding: 0;
    background-color: transparent;
  }
}
.Radio--disabled {
  pointer-events: none;
}
.Radio__control {
  cursor: pointer;
}
.Radio__control:hover:not(:focus):not(:disabled) + .Radio__label {
  color: #656868;
}
.Radio__control:disabled {
  pointer-events: none;
}
.Radio__control:disabled + .Radio__label {
  color: #656868;
}
:root .Radio__control:checked {
  background-image: none;
  border-width: 5px;
}
@media (max-width: 639px) {
  :root .Radio__control:checked {
    border-width: 6px;
  }
}
:root .Radio__control:checked + .Radio__label {
  color: #131818;
}
.Radio__label {
  line-height: var(--form-radio-size);
  cursor: pointer;
}

.Checkbox--invalid .Checkbox__control, .Checkbox--invalid .Checkbox__control:hover:not(:checked):not(:focus) {
  border-color: #f22814;
}
.Checkbox--disabled {
  pointer-events: none;
}
:root .Checkbox__control {
  size: 20px;
  border-radius: 2px;
}
:root .Checkbox__control:checked {
  background-color: #131818;
}
:root .Checkbox__control:checked:focus {
  background-color: #131818;
}
:root .Checkbox__control:disabled {
  border-color: #656868;
}
:root .Checkbox__control:disabled + .Checkbox__label {
  color: #656868;
}
:root .Checkbox__control:not(:disabled):hover + .Checkbox__label, :root .Checkbox__control:not(:disabled):focus + .Checkbox__label {
  color: #656868;
}
.Checkbox__label {
  line-height: 20px;
}

.Loader {
  position: relative;
  width: 100%;
  height: 200px;
  color: #e39e00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Loader--button {
  height: 24px;
  color: inherit;
}
.Loader--button .Loader__spinner {
  width: 24px;
}
.Loader--orderBill {
  height: 24px;
  width: auto;
  margin-left: 16px;
}
.Loader--orderBill .Loader__spinner {
  width: 24px;
}
.Loader--dropdown {
  height: 24px;
  color: var(--form-default-label-color);
}
.Loader--dropdown .Loader__spinner {
  width: 24px;
}
.Loader--searchDetails {
  height: 160px;
}
@media (max-width: 1199px) {
  .Loader--searchDetails {
    height: 232px;
  }
}
@media (max-width: 899px) {
  .Loader--searchDetails {
    height: 296px;
  }
}
@media (max-width: 639px) {
  .Loader--searchDetails {
    height: 241px;
  }
}
@media (max-width: 499px) {
  .Loader--searchDetails {
    height: 445px;
  }
}
.Loader--searchDetailsWithoutTabs {
  height: 64px;
}
@media (max-width: 1199px) {
  .Loader--searchDetailsWithoutTabs {
    height: 136px;
  }
}
@media (max-width: 899px) {
  .Loader--searchDetailsWithoutTabs {
    height: 208px;
  }
}
@media (max-width: 639px) {
  .Loader--searchDetailsWithoutTabs {
    height: 200px;
  }
}
@media (max-width: 499px) {
  .Loader--searchDetailsWithoutTabs {
    height: 404px;
  }
}
@media (max-width: 424px) {
  .Loader--searchDetailsWithoutTabs {
    height: 380px;
  }
}
.Loader--searchTypeDropdown {
  height: 100%;
  color: var(--form-default-label-color);
}
.Loader--searchTypeDropdown .Loader__spinner {
  width: 24px;
}
.Loader--addressForm {
  height: 330px;
  max-width: 440px;
}
.Loader--multiselect {
  height: 264px;
  max-width: 500px;
}
.Loader__spinner {
  width: 50px;
  animation: 2s linear infinite loader-rotate;
}
.Loader__circle {
  animation: 1.4s ease-in-out infinite both loader-animation;
  display: block;
  fill: transparent;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 200;
  stroke-width: 8px;
  transform-origin: 50% 50%;
}

@keyframes loader-rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes loader-animation {
  0%, 25% {
    stroke-dashoffset: 150;
    transform: rotate(0);
  }
  50%, 75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  100% {
    stroke-dashoffset: 150;
    transform: rotate(360deg);
  }
}
body {
  line-height: 1.3;
}

img:-moz-loading {
  color: transparent;
}

[hidden] {
  display: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
  z-index: 10000;
}

@keyframes shine-loader {
  100% {
    background-position: -100%;
  }
}
:root .yall-loaded {
  background: transparent none;
  animation: none;
}

html:root ::-moz-selection {
  color: white;
  background: #e39e00;
}
html:root ::selection {
  color: white;
  background: #e39e00;
}

@media (max-width: 639px) {
  .hidden--s {
    display: none;
  }
}

@media (min-width: 640px) {
  .visible--s {
    display: none;
  }
}

.fade-enter-active {
  transition: opacity 0.25s ease-in-out;
}

.fade-enter {
  opacity: 0;
}

/**
    Support for SASS is deprecated as of v3.18.

    The files remain here if your build is dependent on them
    but they will not receive updates in future releases. All
    SASS variables have been translated into CSS variables, so
    migration should be quite simple if you'd like to move over.

    In v4, these files will be removed.
 */
.v-select {
  position: relative;
  font-family: inherit;
}

.v-select,
.v-select * {
  box-sizing: border-box;
}

/* KeyFrames */
@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Dropdown Default Transition */
.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}

/** Component States */
/*
 * Disabled
 *
 * When the component is disabled, all interaction
 * should be prevented. Here we modify the bg color,
 * and change the cursor displayed on the interactive
 * components.
 */
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: rgb(248, 248, 248);
}

/*
 *  RTL - Right to Left Support
 *
 *  Because we're using a flexbox layout, the `dir="rtl"`
 *  HTML attribute does most of the work for us by
 *  rearranging the child elements visually.
 */
.v-select[dir=rtl] .vs__actions {
  padding: 0 3px 0 6px;
}
.v-select[dir=rtl] .vs__clear {
  margin-left: 6px;
  margin-right: 0;
}
.v-select[dir=rtl] .vs__deselect {
  margin-left: 0;
  margin-right: 2px;
}
.v-select[dir=rtl] .vs__dropdown-menu {
  text-align: right;
}

/**
    Dropdown Toggle

    The dropdown toggle is the primary wrapper of the component. It
    has two direct descendants: .vs__selected-options, and .vs__actions.

    .vs__selected-options holds the .vs__selected's as well as the
    main search input.

    .vs__actions holds the clear button and dropdown toggle.
 */
.vs__dropdown-toggle {
  appearance: none;
  display: flex;
  padding: 0 0 4px 0;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
}

.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative;
}

.vs__actions {
  display: flex;
  align-items: center;
  padding: 4px 6px 0 3px;
}

/* Dropdown Toggle States */
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}

.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}

.vs--open .vs__dropdown-toggle {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
  transform: scale(1);
  transition: transform 150ms cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}

.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}

.vs--loading .vs__open-indicator {
  opacity: 0;
}

/* Clear Button */
.vs__clear {
  fill: rgba(60, 60, 60, 0.5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
}

/* Dropdown Menu */
.vs__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1000;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: 160px;
  overflow-y: auto;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-top-style: none;
  border-radius: 0 0 4px 4px;
  text-align: left;
  list-style: none;
  background: #fff;
}

.vs__no-options {
  text-align: center;
}

/* List Items */
.vs__dropdown-option {
  line-height: 1.42857143; /* Normalize line height */
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333; /* Overrides most CSS frameworks */
  white-space: nowrap;
  cursor: pointer;
}

.vs__dropdown-option--highlight {
  background: #5897fb;
  color: #fff;
}

.vs__dropdown-option--deselect {
  background: #fb5858;
  color: #fff;
}

.vs__dropdown-option--disabled {
  background: inherit;
  color: rgba(60, 60, 60, 0.5);
  cursor: inherit;
}

/* Selected Tags */
.vs__selected {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  color: #333;
  line-height: 1.4;
  margin: 4px 2px 0px 2px;
  padding: 0 0.25em;
  z-index: 0;
}

.vs__deselect {
  display: inline-flex;
  appearance: none;
  margin-left: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: rgba(60, 60, 60, 0.5);
  text-shadow: 0 1px 0 #fff;
}

/* States */
.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
}
.vs--single.vs--open .vs__selected, .vs--single.vs--loading .vs__selected {
  position: absolute;
  opacity: 0.4;
}
.vs--single.vs--searching .vs__selected {
  display: none;
}

/* Search Input */
/**
 * Super weird bug... If this declaration is grouped
 * below, the cancel button will still appear in chrome.
 * If it's up here on it's own, it'll hide it.
 */
.vs__search::-webkit-search-cancel-button {
  display: none;
}

.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration,
.vs__search::-ms-clear {
  display: none;
}

.vs__search,
.vs__search:focus {
  appearance: none;
  line-height: 1.4;
  font-size: 1em;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
}

.vs__search::placeholder {
  color: inherit;
}

/**
    States
 */
.vs--unsearchable .vs__search {
  opacity: 1;
}
.vs--unsearchable:not(.vs--disabled) .vs__search {
  cursor: pointer;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}

/* Loading Spinner */
.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border-top: 0.9em solid rgba(100, 100, 100, 0.1);
  border-right: 0.9em solid rgba(100, 100, 100, 0.1);
  border-bottom: 0.9em solid rgba(100, 100, 100, 0.1);
  border-left: 0.9em solid rgba(60, 60, 60, 0.45);
  transform: translateZ(0);
  animation: vSelectSpinner 1.1s infinite linear;
  transition: opacity 0.1s;
}

.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

/* Loading Spinner States */
.vs--loading .vs__spinner {
  opacity: 1;
}

@keyframes vdpSlideFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-0.5em, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes vdpSlideFromRight {
  from {
    opacity: 0;
    transform: translate3d(0.5em, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes vdpToggleCalendar {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes vdpFadeCalendar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.vdp-toggle-calendar-enter-active.vdpPositionReady {
  transform-origin: top left;
  animation: vdpToggleCalendar 0.2s;
}

.vdp-toggle-calendar-leave-active {
  animation: vdpToggleCalendar 0.15s reverse;
}

.vdp-toggle-calendar-enter-active.vdpPositionFixed {
  animation: vdpFadeCalendar 0.3s;
}

.vdp-toggle-calendar-leave-active.vdpPositionFixed {
  animation: vdpFadeCalendar 0.3s reverse;
}

.vdpComponent {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: #303030;
  /*font-family: Helvetica, Arial, sans-serif;*/
}

.vdpComponent.vdpWithInput > input {
  padding-right: 30px;
}

.vdpClearInput {
  font-size: 1em;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 3em;
}

.vdpClearInput:before {
  content: "×";
  width: 1.4em;
  height: 1.4em;
  line-height: 1.1em;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -0.7em 0 0 -0.7em;
  color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  background-color: #fff;
}

.vdpClearInput:hover:before {
  box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.15);
}

.vdpOuterWrap.vdpFloating {
  position: absolute;
  padding: 0.5em 0;
  z-index: 220;
}

.vdpOuterWrap.vdpPositionFixed {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.vdpFloating .vdpInnerWrap {
  max-width: 30em;
}

.vdpPositionFixed .vdpInnerWrap {
  max-width: 30em;
  margin: 0 auto;
  border: 0;
  animation: vdpToggleCalendar 0.3s;
}

.vdpFloating.vdpPositionTop {
  top: 100%;
}

.vdpFloating.vdpPositionBottom {
  bottom: 100%;
}

.vdpFloating.vdpPositionLeft {
  left: 0;
}

.vdpFloating.vdpPositionRight {
  right: 0;
}

.vdpPositionTop.vdpPositionLeft {
  transform-origin: top left;
}

.vdpPositionTop.vdpPositionRight {
  transform-origin: top right;
}

.vdpPositionBottom.vdpPositionLeft {
  transform-origin: bottom left;
}

.vdpPositionBottom.vdpPositionRight {
  transform-origin: bottom right;
}

.vdpInnerWrap {
  overflow: hidden;
  min-width: 28em;
  box-sizing: border-box;
  padding: 1em;
  background: #fff;
  box-shadow: 0 0.2em 1.5em rgba(0, 0, 0, 0.06);
  border-radius: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.vdpHeader {
  position: relative;
  padding: 0 1em 2.5em;
  margin: -1em -1em -2.5em;
  text-align: center;
  background: #f5f5f5;
}

.vdpClearInput,
.vdpArrow,
.vdpPeriodControl > button,
.vdp12HourToggleBtn {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
}

.vdpArrow::-moz-focus-inner,
.vdpClearInput::-moz-focus-inner,
.vdpPeriodControl::-moz-focus-inner,
.vdp12HourToggleBtn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.vdpArrow {
  font-size: 1em;
  width: 5em;
  text-indent: -999em;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 2.5em;
  text-align: left;
}

.vdpArrow:before {
  content: "";
  width: 2.2em;
  height: 2.2em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1.1em 0 0 -1.1em;
  border-radius: 100%;
  transition: background-color 0.2s;
}

.vdpArrow:hover,
.vdpArrow:focus,
.vdpArrow:active {
  outline: 0;
}

.vdpArrow:hover:before,
.vdpArrow:focus:before {
  background-color: rgba(0, 0, 0, 0.03);
}

.vdpArrow:active:before {
  background-color: rgba(0, 0, 0, 0.07);
}

.vdpArrowNext:before {
  margin-left: -1.4em;
}

.vdpArrow:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -0.5em;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
}

.vdpArrowPrev {
  left: -0.3em;
}

.vdpArrowPrev:after {
  margin-left: -0.8em;
  border-right-color: #7485c2;
}

.vdpArrowNext {
  right: -0.6em;
}

.vdpArrowNext:after {
  margin-left: -0.5em;
  border-left-color: #7485c2;
}

.vdpPeriodControl {
  display: inline-block;
  position: relative;
}

.vdpPeriodControl > button {
  font-size: 1.5em;
  padding: 1em 0.4em;
  display: inline-block;
}

.vdpPeriodControl > select {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  font-size: 1.6em;
}

.vdpTable {
  width: 100%;
  table-layout: fixed;
  position: relative;
  z-index: 5;
}

.vdpNextDirection {
  animation: vdpSlideFromRight 0.5s;
}

.vdpPrevDirection {
  animation: vdpSlideFromLeft 0.5s;
}

.vdpCell, .vdpHeadCell {
  text-align: center;
  box-sizing: border-box;
}

.vdpCell {
  padding: 0.5em 0;
}

.vdpHeadCell {
  padding: 0.3em 0.5em 1.8em;
}

.vdpHeadCellContent {
  font-size: 1.3em;
  font-weight: normal;
  color: #848484;
}

.vdpCellContent {
  font-size: 1.4em;
  display: block;
  margin: 0 auto;
  width: 1.857em;
  line-height: 1.857em;
  text-align: center;
  border-radius: 100%;
  transition: background 0.1s, color 0.1s;
}

.vdpCell.outOfRange {
  color: #c7c7c7;
}

.vdpCell.today {
  color: #7485c2;
}

.vdpCell.selected .vdpCellContent {
  color: #fff;
  background: #7485c2;
}

@media (hover: hover) {
  .vdpCell.selectable:hover .vdpCellContent {
    color: #fff;
    background: #7485c2;
  }
}
.vdpCell.selectable {
  cursor: pointer;
}

.vdpCell.disabled {
  opacity: 0.5;
}

.vdpTimeControls {
  padding: 1.2em 2em;
  position: relative;
  margin: 1em -1em -1em;
  text-align: center;
  background: #f5f5f5;
  /*border-top: 1px solid rgba(0,0,0,0.15);*/
}

.vdpTimeUnit {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.vdpTimeUnit > pre,
.vdpTimeUnit > input {
  font-size: 1.7em;
  line-height: 1.3;
  padding: 0.1em 0.1em;
  word-wrap: break-word;
  white-space: pre-wrap;
  resize: none;
  margin: 0;
  box-sizing: border-box;
  color: #000;
  border: 0;
  border-bottom: 1px solid transparent;
  text-align: center;
}

.vdpTimeUnit > pre {
  visibility: hidden;
  font-family: inherit;
}

.vdpTimeUnit > input {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0;
  appearance: none;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.vdpTimeUnit > input::selection {
  background-color: rgba(116, 133, 194, 0.15);
}

.vdpTimeUnit > input:hover,
.vdpTimeUnit > input:focus {
  border-bottom-color: #7485c2;
}

.vdpTimeUnit > input:disabled {
  border-bottom-color: transparent;
}

.vdpTimeUnit > input::-webkit-inner-spin-button,
.vdpTimeUnit > input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.vdpTimeSeparator, .vdpTimeCaption {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.3em;
  color: #848484;
}

.vdpTimeCaption {
  margin-right: 0.5em;
}

.vdp12HourToggleBtn {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.3em;
  padding: 0 0.4em;
  color: #303030;
}

.vdp12HourToggleBtn:hover,
.vdp12HourToggleBtn:focus {
  color: #7485c2;
  outline: 0;
}

.vdp12HourToggleBtn:disabled {
  color: #303030;
}

.vdpComponent {
  width: 100%;
}
:root .vdpComponent.vdpWithInput > input[readonly] {
  color: var(--form-default-text-color);
}
.vdpComponent.vdpWithInput > input::placeholder {
  opacity: 1;
}

.vdpClearInput {
  display: none;
}

.vdpCell.selectable {
  color: #131818;
}

.vdpCell.outOfRange {
  color: #656868;
}

.vdpCell.disabled {
  color: #dcdcdc;
  opacity: 1;
}

.vdpCell.today.disabled {
  color: white;
}
.vdpCell.today .vdpCellContent {
  background-color: #dcdcdc;
}

.vdpCell.selected .vdpCellContent {
  background-color: #e39e00;
}

.vdpCellContent {
  width: 26px;
  height: 26px;
  padding-top: 2px;
}

.vdpArrowNext:after {
  border-left-color: #e39e00;
}

.vdpArrowPrev:after {
  border-right-color: #e39e00;
}

@media (hover: hover) {
  .vdpCell.selectable:hover .vdpCellContent {
    background-color: #fccf17;
    color: #131818;
  }
}
:root .uk-select:not([multiple]):not([size]), :root .Tabs__mobileDropdown:not([multiple]):not([size]), :root .HeaderSearch__mobileDropdown:not([multiple]):not([size]) {
  background-image: var(--form-default-dropdown-arrow-source);
}

.uk-notification-header {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: var(--notification-color);
  margin-bottom: 8px;
}

.uk-notification-text {
  display: block;
  font-size: 1rem;
  color: #656868;
  line-height: 1.4;
}

/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: "Futura PT", sans-serif;
  font-size: 100%;
  font-weight: normal;
  line-height: 1.3;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #666;
}

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/* Links
 ========================================================================== */
/*
 * Style
 */
a,
.uk-link {
  color: #e39e00;
  text-decoration: none;
  cursor: pointer;
  transition-property: color, opacity;
  transition: 0.25s ease-in-out;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
  color: #e39e00;
  text-decoration: none;
}

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted;
}

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap;
}

/*
 * Emphasize
 */
em {
  color: inherit;
}

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none;
}

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #666;
}

/*
 * Quote
 */
q {
  font-style: italic;
}

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between the element and the bottom of its parent container.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * 1. Constrain the element to its parent width.
 * 2. Preserve the intrinsic aspect ratio and auto-scale the height of an image if the `height` attribute is present.
 * 3. Take border and padding into account.
 */
canvas,
img,
svg,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}

/*
 * Deprecated: only needed for `img` elements with `uk-img`
 * 1. Hide `alt` text for lazy load images.
 * 2. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 */
img:not([src]) {
  /* 1 */
  visibility: hidden;
  /* 2 */
  min-width: 1px;
}

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0;
}

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 0 0;
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 0;
}

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 0 0;
  font-family: "Futura PT", sans-serif;
  font-weight: normal;
  color: #333;
  text-transform: none;
}

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 0;
}

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.3;
}

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3;
}

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.3;
}

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.3;
}

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.3;
}

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.3;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  h1, .uk-h1 {
    font-size: 2.625rem;
  }
  h2, .uk-h2 {
    font-size: 2rem;
  }
}
/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 0;
}

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px;
}

/* Address
 ========================================================================== */
address {
  font-style: normal;
}

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic;
}

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px;
}

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
}

pre code {
  font-family: Consolas, monaco, monospace;
}

/* Focus
 ========================================================================== */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px dotted #333;
}

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none;
}

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main { /* 2 */
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 900px;
  --uk-breakpoint-l: 1000px;
  --uk-breakpoint-xl: 1200px;
}

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Behave like a block element
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + .uk-table {
  margin-top: 20px;
}

/* Header cell
 ========================================================================== */
/*
 * 1. Style
 */
.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  /* 1 */
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

/* Cell
 ========================================================================== */
.uk-table td {
  padding: 16px 12px;
  vertical-align: top;
}

/*
 * Remove margin from the last-child
 */
.uk-table td > :last-child {
  margin-bottom: 0;
}

/* Footer
 ========================================================================== */
.uk-table tfoot {
  font-size: 0.875rem;
}

/* Caption
 ========================================================================== */
.uk-table caption {
  font-size: 0.875rem;
  text-align: left;
  color: #999;
}

/* Alignment modifier
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-table-divider > tr:not(:first-child),
.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

/*
 * Striped
 */
.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8;
}

/*
 * Hover
 */
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background: #ffd;
}

/* Active state
 ========================================================================== */
.uk-table > tr.uk-active,
.uk-table tbody tr.uk-active {
  background: #ffd;
}

/* Size modifier
 ========================================================================== */
.uk-table-small th,
.uk-table-small td {
  padding: 10px 12px;
}

.uk-table-large th,
.uk-table-large td {
  padding: 22px 12px;
}

/* Justify modifier
 ========================================================================== */
.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0;
}

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0;
}

/* Cell size modifier
 ========================================================================== */
.uk-table-shrink {
  width: 1px;
}

.uk-table-expand {
  min-width: 150px;
}

/* Cell link modifier
 ========================================================================== */
/*
 * Does not work with `uk-table-justify` at the moment
 */
.uk-table-link {
  padding: 0 !important;
}

.uk-table-link > a {
  display: block;
  padding: 16px 12px;
}

.uk-table-small .uk-table-link > a {
  padding: 10px 12px;
}

/* Responsive table
 ========================================================================== */
/* Phone landscape and smaller */
@media (max-width: 899px) {
  .uk-table-responsive,
.uk-table-responsive tbody,
.uk-table-responsive th,
.uk-table-responsive td,
.uk-table-responsive tr {
    display: block;
  }
  .uk-table-responsive thead {
    display: none;
  }
  .uk-table-responsive th,
.uk-table-responsive td {
    width: auto !important;
    max-width: none !important;
    min-width: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
  }
  .uk-table-responsive th:not(:first-child):not(.uk-table-link),
.uk-table-responsive td:not(:first-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:first-child) > a {
    padding-top: 5px !important;
  }
  .uk-table-responsive th:not(:last-child):not(.uk-table-link),
.uk-table-responsive td:not(:last-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:last-child) > a {
    padding-bottom: 5px !important;
  }
  .uk-table-justify.uk-table-responsive th,
.uk-table-justify.uk-table-responsive td {
    padding-left: 0;
    padding-right: 0;
  }
}
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0;
}

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0);
}

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 * 1. Required for `span` with background image
 * 2. Required for `image`
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  /* 1 */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  /* 2 */
  object-fit: scale-down;
  max-width: none;
}

/* Style modifiers
 ========================================================================== */
/*
 * Link
 * 1. Allow text within link
 */
.uk-icon-link {
  color: #999;
  /* 1 */
  text-decoration: none !important;
}

.uk-icon-link:hover {
  color: #666;
}

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959;
}

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover */
.uk-icon-button:hover {
  background-color: #ebebeb;
  color: #666;
}

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666;
}

/* ========================================================================
   Component: Form Range
 ========================================================================== */
/*
 * 1. Remove default style.
 * 2. Define consistent box sizing.
 * 3. Remove `margin` in all browsers.
 * 4. Align to the center of the line box.
 * 5. Prevent content overflow if a fixed width is used.
 * 6. Take the full width.
 * 7. Remove white background in Chrome.
 */
.uk-range {
  /* 1 */
  -webkit-appearance: none;
  /* 2 */
  box-sizing: border-box;
  /* 3 */
  margin: 0;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  max-width: 100%;
  /* 6 */
  width: 100%;
  /* 7 */
  background: transparent;
}

/* Focus */
.uk-range:focus {
  outline: none;
}

.uk-range::-moz-focus-outer {
  border: none;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-range:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer;
}

.uk-range:not(:disabled)::-moz-range-thumb {
  cursor: pointer;
}

/*
 * Track
 * 1. Safari doesn't have a focus state. Using active instead.
 */
/* Webkit */
.uk-range::-webkit-slider-runnable-track {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-webkit-slider-runnable-track,
.uk-range:active::-webkit-slider-runnable-track {
  background: #dfdfdf;
}

/* Firefox */
.uk-range::-moz-range-track {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-moz-range-track {
  background: #dfdfdf;
}

/*
 * Thumb
 * 1. Reset
 * 2. Style
 */
/* Webkit */
.uk-range::-webkit-slider-thumb {
  /* 1 */
  -webkit-appearance: none;
  margin-top: -7px;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666;
}

/* Firefox */
.uk-range::-moz-range-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  margin-top: -7px;
  border-radius: 500px;
  background: #666;
}

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input, .Counter__input, .HeaderSearch__control, .SiteForm__input, .vdpComponent.vdpWithInput > input,
.uk-select,
.Tabs__mobileDropdown,
.HeaderSearch__mobileDropdown:not([multiple]):not([size]),
.uk-textarea,
.SiteForm__textarea,
.uk-radio,
.Radio__control,
.uk-checkbox,
.Checkbox__control {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
}

/*
 * Show the overflow in Edge.
 */
.uk-input, .Counter__input, .HeaderSearch__control, .SiteForm__input, .vdpComponent.vdpWithInput > input {
  overflow: visible;
}

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select, .Tabs__mobileDropdown, .HeaderSearch__mobileDropdown:not([multiple]):not([size]) {
  text-transform: none;
}

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup, .Tabs__mobileDropdown optgroup, .HeaderSearch__mobileDropdown:not([multiple]):not([size]) optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold;
}

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea, .SiteForm__textarea {
  overflow: auto;
}

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type=search]::-webkit-search-cancel-button, [type=search].Counter__input::-webkit-search-cancel-button, [type=search].HeaderSearch__control::-webkit-search-cancel-button, [type=search].SiteForm__input::-webkit-search-cancel-button, .vdpComponent.vdpWithInput > input[type=search]::-webkit-search-cancel-button,
.uk-input[type=search]::-webkit-search-decoration,
[type=search].Counter__input::-webkit-search-decoration,
[type=search].HeaderSearch__control::-webkit-search-decoration,
[type=search].SiteForm__input::-webkit-search-decoration,
.vdpComponent.vdpWithInput > input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type=number]::-webkit-inner-spin-button, [type=number].Counter__input::-webkit-inner-spin-button, [type=number].HeaderSearch__control::-webkit-inner-spin-button, [type=number].SiteForm__input::-webkit-inner-spin-button, .vdpComponent.vdpWithInput > input[type=number]::-webkit-inner-spin-button,
.uk-input[type=number]::-webkit-outer-spin-button,
[type=number].Counter__input::-webkit-outer-spin-button,
[type=number].HeaderSearch__control::-webkit-outer-spin-button,
[type=number].SiteForm__input::-webkit-outer-spin-button,
.vdpComponent.vdpWithInput > input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder, .Counter__input::-moz-placeholder, .HeaderSearch__control::-moz-placeholder, .SiteForm__input::-moz-placeholder, .vdpComponent.vdpWithInput > input::-moz-placeholder,
.uk-textarea::-moz-placeholder,
.SiteForm__textarea::-moz-placeholder {
  opacity: 1;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled), .Radio__control:not(:disabled),
.uk-checkbox:not(:disabled),
.Checkbox__control:not(:disabled) {
  cursor: pointer;
}

/*
 * Define consistent border, margin, and padding.
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input, .Counter__input, .HeaderSearch__control, .SiteForm__input, .vdpComponent.vdpWithInput > input,
.uk-textarea,
.SiteForm__textarea {
  -webkit-appearance: none;
}

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input, .Counter__input, .HeaderSearch__control, .SiteForm__input, .vdpComponent.vdpWithInput > input,
.uk-select,
.Tabs__mobileDropdown,
.HeaderSearch__mobileDropdown:not([multiple]):not([size]),
.uk-textarea,
.SiteForm__textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 16px;
  background: var(--form-default-background);
  color: var(--form-default-text-color);
  border: 1px var(--form-default-border) solid;
  border-radius: 2px;
  font-size: var(--form-font-size);
  font-weight: 500;
  transition: 0.25s ease-in-out;
  transition-property: color, border-color, background-color;
}
.uk-input:hover, .Counter__input:hover, .HeaderSearch__control:hover, .SiteForm__input:hover, .vdpComponent.vdpWithInput > input:hover,
.uk-select:hover,
.Tabs__mobileDropdown:hover,
.HeaderSearch__mobileDropdown:hover:not([multiple]):not([size]),
.uk-textarea:hover,
.SiteForm__textarea:hover {
  color: var(--form-hover-text-color);
  background-color: var(--form-hover-background);
  border-color: var(--form-hover-border);
}

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input, .Counter__input, .HeaderSearch__control, .SiteForm__input, .vdpComponent.vdpWithInput > input,
.uk-select:not([multiple]):not([size]),
.Tabs__mobileDropdown:not([multiple]):not([size]),
.HeaderSearch__mobileDropdown:not([multiple]):not([size]) {
  height: var(--form-height);
  vertical-align: middle;
  /* 1 */
  display: inline-block;
}

/* 2 */
.uk-input:not(input), .Counter__input:not(input), .HeaderSearch__control:not(input), .SiteForm__input:not(input), .vdpComponent.vdpWithInput > input:not(input),
.uk-select:not(select),
.Tabs__mobileDropdown:not(select),
.HeaderSearch__mobileDropdown:not(select):not([multiple]):not([size]) {
  line-height: 1.19;
}

/*
 * Multi-line
 */
.uk-select[multiple], [multiple].Tabs__mobileDropdown, [multiple].HeaderSearch__mobileDropdown:not([multiple]):not([size]),
.uk-select[size],
[size].Tabs__mobileDropdown,
[size].HeaderSearch__mobileDropdown:not([multiple]):not([size]),
.uk-textarea,
.SiteForm__textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: top;
  padding: 16px 16px;
  min-height: 120px;
  border-radius: 2px;
}

.uk-select[multiple], [multiple].Tabs__mobileDropdown, [multiple].HeaderSearch__mobileDropdown:not([multiple]):not([size]),
.uk-select[size],
[size].Tabs__mobileDropdown,
[size].HeaderSearch__mobileDropdown:not([multiple]):not([size]) {
  resize: vertical;
}

/* Focus */
.uk-input:focus, .Counter__input:focus, .HeaderSearch__control:focus, .SiteForm__input:focus, .vdpComponent.vdpWithInput > input:focus,
.uk-select:focus,
.Tabs__mobileDropdown:focus,
.HeaderSearch__mobileDropdown:focus:not([multiple]):not([size]),
.uk-textarea:focus,
.SiteForm__textarea:focus {
  outline: none;
  background-color: var(--form-active-background);
  color: var(--form-active-text-color);
  border-color: var(--form-active-border);
}

/* Disabled */
.uk-input:disabled, .Counter__input:disabled, .HeaderSearch__control:disabled, .SiteForm__input:disabled, .vdpComponent.vdpWithInput > input:disabled,
.uk-select:disabled,
.Tabs__mobileDropdown:disabled,
.HeaderSearch__mobileDropdown:disabled:not([multiple]):not([size]),
.uk-textarea:disabled,
.SiteForm__textarea:disabled {
  background-color: var(--form-disabled-background);
  color: var(--form-disabled-text-color);
  border-color: var(--form-disabled-border);
}

/*
 * Placeholder
 */
.uk-input::placeholder, .Counter__input::placeholder, .HeaderSearch__control::placeholder, .SiteForm__input::placeholder, .vdpComponent.vdpWithInput > input::placeholder {
  color: rgba(101, 104, 104, 0.5);
}

.uk-textarea::placeholder, .SiteForm__textarea::placeholder {
  color: rgba(101, 104, 104, 0.5);
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.875rem;
}

/* Single-line */
.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Multi-line */
textarea.uk-form-small,
[multiple].uk-form-small,
[size].uk-form-small {
  padding: 5px 8px;
}

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 30px;
}

/*
 * Large
 */
.uk-form-large {
  font-size: 1.25rem;
}

/* Single-line */
.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px;
}

/* Multi-line */
textarea.uk-form-large,
[multiple].uk-form-large,
[size].uk-form-large {
  padding: 7px 12px;
}

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 55px;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #f0506e;
}

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: #32d296;
}

/*
 * Blank
 */
.uk-form-blank {
  background: none;
}

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px;
}

select.uk-form-width-xsmall {
  width: 75px;
}

.uk-form-width-small {
  width: 130px;
}

.uk-form-width-medium {
  width: 200px;
}

.uk-form-width-large {
  width: 500px;
}

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]), .Tabs__mobileDropdown:not([multiple]):not([size]), .HeaderSearch__mobileDropdown:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("var(--form-default-dropdown-arrow-source)");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

/* 3 */
.uk-select:not([multiple]):not([size]) option, .Tabs__mobileDropdown:not([multiple]):not([size]) option, .HeaderSearch__mobileDropdown:not([multiple]):not([size]) option {
  color: #666;
}

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled, .Tabs__mobileDropdown:not([multiple]):not([size]):disabled, .HeaderSearch__mobileDropdown:not([multiple]):not([size]):disabled {
  background-image: url("var(--form-default-dropdown-arrow-source)");
}

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list], [list].Counter__input, [list].HeaderSearch__control, [list].SiteForm__input, .vdpComponent.vdpWithInput > input[list] {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.uk-input[list]:hover, [list].Counter__input:hover, [list].HeaderSearch__control:hover, [list].SiteForm__input:hover, .vdpComponent.vdpWithInput > input[list]:hover,
.uk-input[list]:focus,
[list].Counter__input:focus,
[list].HeaderSearch__control:focus,
[list].SiteForm__input:focus,
.vdpComponent.vdpWithInput > input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator, [list].Counter__input::-webkit-calendar-picker-indicator, [list].HeaderSearch__control::-webkit-calendar-picker-indicator, [list].SiteForm__input::-webkit-calendar-picker-indicator, .vdpComponent.vdpWithInput > input[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

/* Radio and checkbox
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio, .Radio__control,
.uk-checkbox,
.Checkbox__control {
  /* 1 */
  display: inline-block;
  height: var(--form-radio-size);
  width: var(--form-radio-size);
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: 0;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: transparent;
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: 0.25s ease-in-out;
  transition-property: background-color, border-color;
  border: 2px #131818 solid;
}
.uk-radio:hover, .Radio__control:hover, .uk-radio:focus, .Radio__control:focus,
.uk-checkbox:hover,
.Checkbox__control:hover,
.uk-checkbox:focus,
.Checkbox__control:focus {
  border-color: #656868;
}
.uk-radio:checked, .Radio__control:checked,
.uk-checkbox:checked,
.Checkbox__control:checked {
  border-color: #131818;
}

.uk-radio, .Radio__control {
  border-radius: 50%;
}

/* Focus */
.uk-radio:focus, .Radio__control:focus,
.uk-checkbox:focus,
.Checkbox__control:focus {
  background-color: rgba(0, 0, 0, 0);
  outline: none;
}

/*
 * Checked
 */
.uk-radio:checked, .Radio__control:checked,
.uk-checkbox:checked,
.Checkbox__control:checked,
.uk-checkbox:indeterminate,
.Checkbox__control:indeterminate {
  background-color: transparent;
}

/* Focus */
.uk-radio:checked:focus, .Radio__control:checked:focus,
.uk-checkbox:checked:focus,
.Checkbox__control:checked:focus,
.uk-checkbox:indeterminate:focus,
.Checkbox__control:indeterminate:focus {
  background-color: transparent;
}

/*
 * Icons
 */
.uk-radio:checked, .Radio__control:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked, .Checkbox__control:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2011%22%3E%3Cpath%20d%3D%22M1%204.429L5.364%209%2013%201%22%20stroke%3D%22%23FCCF17%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
}

.uk-checkbox:indeterminate, .Checkbox__control:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/*
 * Disabled
 */
.uk-radio:disabled, .Radio__control:disabled,
.uk-checkbox:disabled,
.Checkbox__control:disabled {
  background-color: #f8f8f8;
}

.uk-radio:disabled:checked, .Radio__control:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked, .Checkbox__control:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2011%22%3E%3Cpath%20d%3D%22M1%204.429L5.364%209%2013%201%22%20stroke%3D%22%23FCCF17%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:indeterminate, .Checkbox__control:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
}

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type=file] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer;
}

.uk-form-custom input[type=file] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden;
}

/* Label
 ========================================================================== */
/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 10px;
}

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 899px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 10px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left;
  }
  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }
  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px;
  }
}
/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: var(--form-height);
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #666;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input, .uk-form-icon:not(.uk-form-icon-flip) ~ .Counter__input, .uk-form-icon:not(.uk-form-icon-flip) ~ .HeaderSearch__control, .uk-form-icon:not(.uk-form-icon-flip) ~ .SiteForm__input, .vdpComponent.vdpWithInput > .uk-form-icon:not(.uk-form-icon-flip) ~ input {
  padding-left: var(--form-height) !important;
}

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto;
}

.uk-form-icon-flip ~ .uk-input, .uk-form-icon-flip ~ .Counter__input, .uk-form-icon-flip ~ .HeaderSearch__control, .uk-form-icon-flip ~ .SiteForm__input, .vdpComponent.vdpWithInput > .uk-form-icon-flip ~ input {
  padding-right: var(--form-height) !important;
}

/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container, .SiteHeader__container, .Layout__container {
  display: flow-root;
  /* 1 */
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container, .SiteHeader__container, .Layout__container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container, .SiteHeader__container, .Layout__container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-container > :last-child, .SiteHeader__container > :last-child, .Layout__container > :last-child {
  margin-bottom: 0;
}

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container, .SiteHeader__container .uk-container, .uk-container .SiteHeader__container, .SiteHeader__container .SiteHeader__container, .Layout__container .uk-container, .Layout__container .SiteHeader__container, .uk-container .Layout__container, .SiteHeader__container .Layout__container, .Layout__container .Layout__container {
  padding-left: 0;
  padding-right: 0;
}

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 720px;
}

.uk-container-small, .Layout__container--small {
  max-width: 780px;
}

.uk-container-large, .Layout__container--large {
  max-width: 1400px;
}

.uk-container-xlarge {
  max-width: 1600px;
}

.uk-container-expand {
  max-width: none;
}

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0;
}

.uk-container-expand-right {
  margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (720px / 2) - 32px);
  }
  .uk-container-expand-left.uk-container-small, .uk-container-expand-left.Layout__container--small,
.uk-container-expand-right.uk-container-small,
.uk-container-expand-right.Layout__container--small {
    max-width: calc(50% + (780px / 2) - 32px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container-expand-left,
.uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (720px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-small, .uk-container-expand-left.Layout__container--small,
.uk-container-expand-right.uk-container-small,
.uk-container-expand-right.Layout__container--small {
    max-width: calc(50% + (780px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-large, .uk-container-expand-left.Layout__container--large,
.uk-container-expand-right.uk-container-large,
.uk-container-expand-right.Layout__container--large {
    max-width: calc(50% + (1400px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-xlarge,
.uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 40px);
  }
}
/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 16px);
}

.uk-container-item-padding-remove-left {
  margin-left: -16px;
}

.uk-container-item-padding-remove-right {
  margin-right: -16px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 32px);
  }
  .uk-container-item-padding-remove-left {
    margin-left: -32px;
  }
  .uk-container-item-padding-remove-right {
    margin-right: -32px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 40px);
  }
  .uk-container-item-padding-remove-left {
    margin-left: -40px;
  }
  .uk-container-item-padding-remove-right {
    margin-right: -40px;
  }
}
/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
  position: relative;
  box-sizing: border-box;
}

/* Sections
 ========================================================================== */
.uk-card-body {
  display: flow-root;
  padding: 30px 30px;
}

.uk-card-header {
  display: flow-root;
  padding: 15px 30px;
}

.uk-card-footer {
  display: flow-root;
  padding: 15px 30px;
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-card-body {
    padding: 40px 40px;
  }
  .uk-card-header {
    padding: 20px 40px;
  }
  .uk-card-footer {
    padding: 20px 40px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
  margin-bottom: 0;
}

/* Media
 ========================================================================== */
/*
 * Reserved alignment modifier to style the media element, e.g. with `border-radius`
 * Implemented by the theme
 */
/* Title
 ========================================================================== */
.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Badge
 ========================================================================== */
/*
 * 1. Position
 * 2. Size
 * 3. Style
 * 4. Center child vertically
 */
.uk-card-badge {
  /* 1 */
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  /* 2 */
  height: 22px;
  padding: 0 10px;
  /* 3 */
  background: #1e87f0;
  color: #fff;
  font-size: 0.875rem;
  /* 4 */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

/*
 * Remove margin from adjacent element
 */
.uk-card-badge:first-child + * {
  margin-top: 0;
}

/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background-color: #f8f8f8;
}

/* Style modifiers
 ========================================================================== */
/*
 * Default
 * Note: Header and Footer are only implemented for the default style
 */
.uk-card-default {
  background-color: #f8f8f8;
  color: #666;
}

.uk-card-default .uk-card-title {
  color: #333;
}

.uk-card-default.uk-card-hover:hover {
  background-color: #ebebeb;
}

/*
 * Primary
 */
.uk-card-primary {
  background-color: #1e87f0;
  color: #fff;
}

.uk-card-primary .uk-card-title {
  color: #fff;
}

.uk-card-primary.uk-card-hover:hover {
  background-color: #0f7ae5;
}

/*
 * Secondary
 */
.uk-card-secondary {
  background-color: #222;
  color: #fff;
}

.uk-card-secondary .uk-card-title {
  color: #fff;
}

.uk-card-secondary.uk-card-hover:hover {
  background-color: #151515;
}

/* Size modifier
 ========================================================================== */
/*
 * Small
 */
.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px;
}

.uk-card-small .uk-card-header {
  padding: 13px 20px;
}

.uk-card-small .uk-card-footer {
  padding: 13px 20px;
}

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-card-large.uk-card-body,
.uk-card-large .uk-card-body {
    padding: 70px 70px;
  }
  .uk-card-large .uk-card-header {
    padding: 35px 70px;
  }
  .uk-card-large .uk-card-footer {
    padding: 35px 70px;
  }
}
/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999;
}

/* Hover */
.uk-close:hover {
  color: #666;
}

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden;
}

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. `!important` is needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: 100% !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform;
}

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0);
}

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px;
}

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none;
}

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0);
}

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 30px 30px;
}

.uk-modal-header {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8;
}

.uk-modal-footer {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8;
}

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*=uk-modal-close-] {
  position: absolute;
  z-index: 1010;
  top: 12px;
  right: 12px;
  padding: 5px;
}

/*
 * Remove margin from adjacent element
 */
[class*=uk-modal-close-]:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/*
 * Default
 */
.uk-modal-close-default {
  color: var(--button-default-color);
  border-color: var(--button-default-border);
  background-color: var(--button-default-background);
  --button-default-color: #2b2f2f;
  --button-default-background: rgba(101, 104, 104, 0.1);
  --button-hover-color: #2b2f2f;
  --button-hover-background: rgba(101, 104, 104, 0.2);
  --button-focus-color: #2b2f2f;
  --button-focus-background: rgba(101, 104, 104, 0.2);
  --button-active-color: #2b2f2f;
  --button-active-background: rgba(101, 104, 104, 0.3);
  top: 16px;
  right: 16px;
  padding: 10px;
  border-radius: 2px;
  transition: 0.25s ease-in-out;
  transition-property: color, background-color;
}
.uk-modal-close-default .Button__icon {
  color: var(--button-default-icon-color, currentColor);
}
.uk-modal-close-default:hover {
  color: var(--button-hover-color);
  border-color: var(--button-hover-border);
  background-color: var(--button-hover-background);
}
.uk-modal-close-default:hover .Button__icon {
  color: var(--button-hover-icon-color, currentColor);
}
.uk-modal-close-default:focus {
  color: var(--button-focus-color);
  border-color: var(--button-focus-border);
  background-color: var(--button-focus-background);
}
.uk-modal-close-default:focus .Button__icon {
  color: var(--button-focus-icon-color, currentColor);
}
.uk-modal-close-default:active, .uk-modal-close-default.active {
  color: var(--button-active-color);
  border-color: var(--button-active-border);
  background-color: var(--button-active-background);
}
.uk-modal-close-default:active .Button__icon, .uk-modal-close-default.active .Button__icon {
  color: var(--button-active-icon-color, currentColor);
}
.uk-modal-close-default:disabled {
  color: var(--button-disabled-color);
  border-color: var(--button-disabled-border);
  background-color: var(--button-disabled-background);
}
.uk-modal-close-default:disabled .Button__icon {
  color: var(--button-disabled-icon-color, currentColor);
}
@media (max-width: 639px) {
  .uk-modal-close-default {
    padding: 12px;
  }
}
@media (max-width: 424px) {
  .uk-modal-close-default {
    top: 12px;
    right: 12px;
  }
}
.uk-modal-close-default svg {
  size: 12px;
}
.uk-modal-close-default svg > line {
  stroke-width: 2;
  stroke-linecap: round;
}

/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white;
}

.uk-modal-close-outside:hover {
  color: #fff;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%);
  }
}
/*
 * Full
 */
/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Create position context so it's t the same like when fixed.
 * 2. More robust if padding and border are used and the sticky height is transitioned
 */
.uk-sticky {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
}

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  z-index: 980;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*
 * Faster animations
 */
.uk-sticky[class*=uk-animation-] {
  animation-duration: 0.2s;
}

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s;
}

/*
 * Placeholder
 * Make content clickable for sticky cover and reveal effects
 */
.uk-sticky-placeholder {
  pointer-events: none;
}

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none;
}

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px;
}

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px;
}

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px;
}

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px;
}

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0;
  }
}
/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer;
  padding: 24px 32px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-top: 4px var(--notification-color) solid;
}
@media (max-width: 639px) {
  .uk-notification-message {
    padding: 16px 24px;
  }
}
:root .uk-notification-message {
  color: var(--notification-color);
}

* + .uk-notification-message {
  margin-top: 10px;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px;
  top: 20px;
  right: 15px;
}
@media (hover: none) {
  .uk-notification-close {
    display: block;
  }
}

.uk-notification-message:hover .uk-notification-close {
  display: block;
}

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0;
  --notification-color: #2289e9;
}

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296;
  --notification-color: #3cae39;
}

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a;
  --notification-color: #f78018;
}

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e;
  --notification-color: #f22814;
}

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 10000;
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 10;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  min-width: 270px;
  line-height: 1.28;
}
.uk-tooltip ul {
  list-style: disc;
  padding-left: 16px;
}
.uk-tooltip ul li {
  margin-top: 2px;
}

/* Show */
.uk-tooltip.uk-active {
  display: block;
}

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*=uk-animation-] {
  animation: 0.5s ease-out both;
}

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade, uk-scale-up;
}

.uk-animation-scale-down {
  animation-name: uk-fade, uk-scale-down;
}

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade, uk-slide-top;
}

.uk-animation-slide-bottom {
  animation-name: uk-fade, uk-slide-bottom;
}

.uk-animation-slide-left {
  animation-name: uk-fade, uk-slide-left;
}

.uk-animation-slide-right {
  animation-name: uk-fade, uk-slide-right;
}

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade, uk-slide-top-small;
}

.uk-animation-slide-bottom-small {
  animation-name: uk-fade, uk-slide-bottom-small;
}

.uk-animation-slide-left-small {
  animation-name: uk-fade, uk-slide-left-small;
}

.uk-animation-slide-right-small {
  animation-name: uk-fade, uk-slide-right-small;
}

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade, uk-slide-top-medium;
}

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade, uk-slide-bottom-medium;
}

.uk-animation-slide-left-medium {
  animation-name: uk-fade, uk-slide-left-medium;
}

.uk-animation-slide-right-medium {
  animation-name: uk-fade, uk-slide-right-medium;
}

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-kenburns;
  animation-duration: 15s;
}

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake;
}

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  animation-duration: 2s;
  stroke-dasharray: var(--uk-animation-stroke);
}

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s;
}

/* Toggle animation based on the State of the Parent Element
 ========================================================================== */
.uk-animation-toggle:not(:hover):not(:focus) [class*=uk-animation-] {
  animation-name: none;
}

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
 * Scale
 */
@keyframes uk-scale-up {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes uk-scale-down {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/*
 * Slide
 */
@keyframes uk-slide-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Slide Small
 */
@keyframes uk-slide-top-small {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-small {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left-small {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right-small {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Slide Medium
 */
@keyframes uk-slide-top-medium {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-medium {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left-medium {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right-medium {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Kenburns
 */
@keyframes uk-kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex;
}

.uk-flex-inline {
  display: inline-flex;
}

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start;
}

.uk-flex-center {
  justify-content: center;
}

.uk-flex-right {
  justify-content: flex-end;
}

.uk-flex-between {
  justify-content: space-between;
}

.uk-flex-around {
  justify-content: space-around;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start;
  }
  .uk-flex-center\@s {
    justify-content: center;
  }
  .uk-flex-right\@s {
    justify-content: flex-end;
  }
  .uk-flex-between\@s {
    justify-content: space-between;
  }
  .uk-flex-around\@s {
    justify-content: space-around;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-flex-left\@m {
    justify-content: flex-start;
  }
  .uk-flex-center\@m {
    justify-content: center;
  }
  .uk-flex-right\@m {
    justify-content: flex-end;
  }
  .uk-flex-between\@m {
    justify-content: space-between;
  }
  .uk-flex-around\@m {
    justify-content: space-around;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-flex-left\@l {
    justify-content: flex-start;
  }
  .uk-flex-center\@l {
    justify-content: center;
  }
  .uk-flex-right\@l {
    justify-content: flex-end;
  }
  .uk-flex-between\@l {
    justify-content: space-between;
  }
  .uk-flex-around\@l {
    justify-content: space-around;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@xl {
    justify-content: flex-start;
  }
  .uk-flex-center\@xl {
    justify-content: center;
  }
  .uk-flex-right\@xl {
    justify-content: flex-end;
  }
  .uk-flex-between\@xl {
    justify-content: space-between;
  }
  .uk-flex-around\@xl {
    justify-content: space-around;
  }
}
/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch;
}

.uk-flex-top {
  align-items: flex-start;
}

.uk-flex-middle {
  align-items: center;
}

.uk-flex-bottom {
  align-items: flex-end;
}

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row;
}

.uk-flex-row-reverse {
  flex-direction: row-reverse;
}

.uk-flex-column {
  flex-direction: column;
}

.uk-flex-column-reverse {
  flex-direction: column-reverse;
}

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap;
}

.uk-flex-wrap {
  flex-wrap: wrap;
}

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch;
}

.uk-flex-wrap-top {
  align-content: flex-start;
}

.uk-flex-wrap-middle {
  align-content: center;
}

.uk-flex-wrap-bottom {
  align-content: flex-end;
}

.uk-flex-wrap-between {
  align-content: space-between;
}

.uk-flex-wrap-around {
  align-content: space-around;
}

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1;
}

.uk-flex-last {
  order: 99;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1;
  }
  .uk-flex-last\@s {
    order: 99;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-flex-first\@m {
    order: -1;
  }
  .uk-flex-last\@m {
    order: 99;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-flex-first\@l {
    order: -1;
  }
  .uk-flex-last\@l {
    order: 99;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@xl {
    order: -1;
  }
  .uk-flex-last\@xl {
    order: 99;
  }
}
/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none;
}

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto;
}

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1;
}

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px;
}

* + .uk-margin {
  margin-top: 20px !important;
}

.uk-margin-top {
  margin-top: 20px !important;
}

.uk-margin-bottom {
  margin-bottom: 20px !important;
}

.uk-margin-left {
  margin-left: 20px !important;
}

.uk-margin-right {
  margin-right: 20px !important;
}

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px;
}

* + .uk-margin-small {
  margin-top: 10px !important;
}

.uk-margin-small-top {
  margin-top: 10px !important;
}

.uk-margin-small-bottom {
  margin-bottom: 10px !important;
}

.uk-margin-small-left {
  margin-left: 10px !important;
}

.uk-margin-small-right {
  margin-right: 10px !important;
}

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px;
}

* + .uk-margin-medium {
  margin-top: 40px !important;
}

.uk-margin-medium-top {
  margin-top: 40px !important;
}

.uk-margin-medium-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-medium-left {
  margin-left: 40px !important;
}

.uk-margin-medium-right {
  margin-right: 40px !important;
}

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px;
}

* + .uk-margin-large {
  margin-top: 40px !important;
}

.uk-margin-large-top {
  margin-top: 40px !important;
}

.uk-margin-large-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-large-left {
  margin-left: 40px !important;
}

.uk-margin-large-right {
  margin-right: 40px !important;
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-large {
    margin-bottom: 70px;
  }
  * + .uk-margin-large {
    margin-top: 70px !important;
  }
  .uk-margin-large-top {
    margin-top: 70px !important;
  }
  .uk-margin-large-bottom {
    margin-bottom: 70px !important;
  }
  .uk-margin-large-left {
    margin-left: 70px !important;
  }
  .uk-margin-large-right {
    margin-right: 70px !important;
  }
}
/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 70px;
}

* + .uk-margin-xlarge {
  margin-top: 70px !important;
}

.uk-margin-xlarge-top {
  margin-top: 70px !important;
}

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important;
}

.uk-margin-xlarge-left {
  margin-left: 70px !important;
}

.uk-margin-xlarge-right {
  margin-right: 70px !important;
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-xlarge {
    margin-bottom: 140px;
  }
  * + .uk-margin-xlarge {
    margin-top: 140px !important;
  }
  .uk-margin-xlarge-top {
    margin-top: 140px !important;
  }
  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important;
  }
  .uk-margin-xlarge-left {
    margin-left: 140px !important;
  }
  .uk-margin-xlarge-right {
    margin-right: 140px !important;
  }
}
/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.uk-margin-auto-top {
  margin-top: auto !important;
}

.uk-margin-auto-bottom {
  margin-bottom: auto !important;
}

.uk-margin-auto-left {
  margin-left: auto !important;
}

.uk-margin-auto-right {
  margin-right: auto !important;
}

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@s {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@s {
    margin-right: auto !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@m {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@m {
    margin-right: auto !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@l {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@l {
    margin-right: auto !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@xl {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@xl {
    margin-right: auto !important;
  }
}
/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important;
}

.uk-margin-remove-top {
  margin-top: 0 !important;
}

.uk-margin-remove-bottom {
  margin-bottom: 0 !important;
}

.uk-margin-remove-left {
  margin-left: 0 !important;
}

.uk-margin-remove-right {
  margin-right: 0 !important;
}

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important;
}

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@s {
    margin-right: 0 !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@m {
    margin-right: 0 !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@l {
    margin-right: 0 !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@xl {
    margin-right: 0 !important;
  }
}
.v-select.vs--single .vs__open-indicator {
  display: block;
  width: 24px;
  height: 8px;
  background-image: var(--form-default-dropdown-arrow-source);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  transition: transform 0.25s ease;
}
.v-select.vs--single .vs__actions {
  top: 50%;
  transform: translateY(-50%);
  margin-top: -2px;
}
.v-select.vs--single .vs__selected {
  padding: 0;
  margin: 0;
  border: none;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-select.vs--single .vs__selected-options {
  margin: 0 24px 0 16px;
  flex-wrap: nowrap;
  max-width: calc(100% - 36px);
}
.v-select.vs--single .vs__search,
.v-select.vs--single .vs__search:focus {
  margin: 0;
  border: none;
  padding: 0;
}
.v-select:hover .vs__dropdown-toggle {
  color: var(--form-hover-text-color);
  border-color: var(--form-hover-border);
  background-color: var(--form-hover-background);
}
.v-select.vs--open .vs__open-indicator {
  transform: scaleY(-1);
}
.v-select.vs--open .vs__dropdown-toggle {
  color: var(--form-active-text-color);
  border-color: var(--form-active-border);
  background-color: var(--form-active-background);
  border-radius: 2px 2px 0 0;
}
.v-select.vs--has-options .vs__no-options {
  display: none;
}
.v-select.vs--hide-actions .vs__actions {
  display: none;
}
.v-select .vs-option-content {
  display: flex;
  align-items: center;
}
.v-select .vs-option-icon {
  margin-right: 8px;
  size: 18px;
}
.v-select .vs-option-icon + .vs-option-text {
  line-height: 18px;
}
.v-select .vs__search {
  color: var(--form-default-text-color);
  line-height: unset;
}
.v-select .vs__search::placeholder {
  color: transparent;
  font-size: var(--form-font-size);
}
.v-select .vs__dropdown-toggle,
.v-select .vs__dropdown-menu {
  color: var(--form-default-text-color);
  font-size: var(--form-font-size);
}
.v-select .vs__selected {
  color: var(--form-default-text-color);
  transition: opacity 0.25s ease-in-out;
}
.v-select .vs__dropdown-toggle {
  min-height: var(--form-height);
  border-radius: 2px;
  padding: 0;
  transition-property: background-color, border-color, color;
  transition: 0.25s ease-in-out;
  border: 1px var(--form-default-border) solid;
  background-color: var(--form-default-background);
}
.v-select .vs__dropdown-menu {
  top: 100%;
  border: var(--form-default-border);
  padding: 0;
  z-index: 99;
  max-height: 320px;
  border-radius: 0 0 2px 2px;
  min-width: 80px;
  background-color: var(--form-default-option-background);
}
.v-select .vs__dropdown-option {
  padding: 10px 13px;
  white-space: normal;
  background-color: var(--form-default-option-background);
  color: var(--form-default-option-color);
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}
.v-select .vs__dropdown-option--highlight {
  background-color: var(--form-hover-option-background);
  color: var(--form-hover-option-color);
}
.v-select .vs__dropdown-option--disabled {
  opacity: 0.5;
}
.v-select .vs__dropdown-option--selected {
  background-color: var(--form-active-option-background);
  color: var(--form-active-option-color);
  cursor: default;
}
.v-select .vs__selected-options {
  align-items: center;
  padding: 0;
}
.v-select .vs__actions {
  position: absolute;
  right: 0;
  padding-right: 0;
}
.v-select .vs__no-options {
  padding: 10px 12px;
  background-color: var(--form-default-option-background);
}
.v-select .vs__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}
.v-select .vs__loader-icon {
  width: 30px;
  height: 30px;
}
.v-select.vs--disabled:hover .vs__dropdown-toggle {
  background-color: var(--form-disabled-background);
}
.v-select.vs--disabled .vs__dropdown-toggle {
  color: var(--form-disabled-text-color);
  background-color: var(--form-disabled-background);
  border-color: var(--form-disabled-border);
}
.v-select.vs--disabled .vs__search {
  background-color: transparent;
}
.v-select.vs--disabled .vs__actions,
.v-select.vs--disabled .vs__open-indicator {
  background-color: transparent;
  opacity: 0.5;
}
.v-select.vs--disabled .vs__dropdown-toggle,
.v-select.vs--disabled .vs__search,
.v-select.vs--disabled .vs__actions,
.v-select.vs--disabled .vs__open-indicator,
.v-select.vs--disabled .vs__selected-options {
  cursor: default;
}
.v-select.vs--disabled .vs__dropdown-toggle:hover,
.v-select.vs--disabled .vs__search:hover,
.v-select.vs--disabled .vs__actions:hover,
.v-select.vs--disabled .vs__open-indicator:hover,
.v-select.vs--disabled .vs__selected-options:hover {
  cursor: default;
}

.Layout {
  display: flex;
  flex-flow: column;
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}
@media (max-width: 999px) {
  .Layout {
    padding-top: var(--header-height);
  }
}
.Layout__header {
  flex-shrink: 0;
}
.Layout__main {
  flex-grow: 1;
}
.Layout__main--centered {
  min-height: 500px;
}
.Layout__footer {
  flex-shrink: 0;
}
.Layout__section {
  --heading-color: #2b2f2f;
  --section-padding-xxsmall: 40px;
  --section-padding-xsmall: 48px;
  --section-padding-small: 56px;
  --section-padding-medium: 80px;
  --section-padding-large: 106px;
  --section-padding-xlarge: 124px;
  --section-padding-xxlarge: 160px;
  padding-y: var(--section-padding-small);
  position: relative;
}
@media (max-width: 1199px) {
  .Layout__section {
    --section-padding-xsmall: 44px;
    --section-padding-small: 52px;
    --section-padding-medium: 72px;
    --section-padding-large: 88px;
    --section-padding-xlarge: 116px;
    --section-padding-xxlarge: 140px;
  }
}
@media (max-width: 999px) {
  .Layout__section {
    --section-padding-xxsmall: 36px;
    --section-padding-xsmall: 40px;
    --section-padding-small: 48px;
    --section-padding-medium: 64px;
    --section-padding-large: 80px;
    --section-padding-xlarge: 96px;
    --section-padding-xxlarge: 110px;
  }
}
@media (max-width: 899px) {
  .Layout__section {
    --section-padding-xxsmall: 32px;
    --section-padding-xsmall: 36px;
    --section-padding-small: 44px;
    --section-padding-medium: 56px;
    --section-padding-large: 72px;
    --section-padding-xlarge: 84px;
    --section-padding-xxlarge: 92px;
  }
}
@media (max-width: 639px) {
  .Layout__section {
    --section-padding-xxsmall: 32px;
    --section-padding-xsmall: 32px;
    --section-padding-small: 40px;
    --section-padding-medium: 48px;
    --section-padding-large: 64px;
    --section-padding-xlarge: 72px;
    --section-padding-xxlarge: 80px;
  }
}
@media (max-width: 424px) {
  .Layout__section {
    --section-padding-xxsmall: 32px;
    --section-padding-xsmall: 32px;
    --section-padding-small: 32px;
    --section-padding-medium: 36px;
    --section-padding-large: 42px;
    --section-padding-xlarge: 46px;
    --section-padding-xxlarge: 52px;
  }
}
.Layout__section--paddingTopXxsmall {
  padding-top: var(--section-padding-xxsmall);
}
.Layout__section--paddingBottomXxsmall {
  padding-bottom: var(--section-padding-xxsmall);
}
.Layout__section--paddingXxsmall {
  padding-y: var(--section-padding-xxsmall);
}
.Layout__section--paddingTopXsmall, .Layout__section--main {
  padding-top: var(--section-padding-xsmall);
}
.Layout__section--paddingBottomXsmall {
  padding-bottom: var(--section-padding-xsmall);
}
.Layout__section--paddingXsmall {
  padding-y: var(--section-padding-xsmall);
}
.Layout__section--paddingTopSmall {
  padding-top: var(--section-padding-small);
}
.Layout__section--paddingBottomSmall {
  padding-bottom: var(--section-padding-small);
}
.Layout__section--paddingSmall {
  padding-y: var(--section-padding-small);
}
.Layout__section--paddingTopMedium {
  padding-top: var(--section-padding-medium);
}
.Layout__section--paddingBottomMedium {
  padding-bottom: var(--section-padding-medium);
}
.Layout__section--paddingMedium {
  padding-y: var(--section-padding-medium);
}
.Layout__section--paddingTopLarge {
  padding-top: var(--section-padding-large);
}
.Layout__section--paddingBottomLarge {
  padding-bottom: var(--section-padding-large);
}
.Layout__section--paddingLarge {
  padding-y: var(--section-padding-large);
}
.Layout__section--paddingTopXlarge {
  padding-top: var(--section-padding-xlarge);
}
.Layout__section--paddingBottomXlarge {
  padding-bottom: var(--section-padding-xlarge);
}
.Layout__section--paddingXlarge {
  padding-y: var(--section-padding-xlarge);
}
.Layout__section--paddingTopXxlarge {
  padding-top: var(--section-padding-xxlarge);
}
.Layout__section--paddingBottomXxlarge, .Layout__section--main {
  padding-bottom: var(--section-padding-xxlarge);
}
.Layout__section--paddingXxlarge {
  padding-y: var(--section-padding-xxlarge);
}
.Layout__section--noTopPadding {
  padding-top: 0;
}
.Layout__section--noBottomPadding {
  padding-bottom: 0;
}
.Layout__section--gray {
  --heading-color: #656868;
  background-color: rgba(101, 104, 104, 0.05);
}
.Layout__section--dark {
  --heading-color: white;
  background-color: #2b2f2f;
}
.Layout__title {
  color: var(--heading-color, #2b2f2f);
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .Layout__title {
    margin-bottom: 32px;
  }
}
@media (max-width: 899px) {
  .Layout__title {
    margin-bottom: 24px;
  }
}
.Layout__title--medium {
  margin-bottom: 32px;
}
@media (max-width: 1199px) {
  .Layout__title--medium {
    margin-bottom: 24px;
  }
}
@media (max-width: 899px) {
  .Layout__title--medium {
    margin-bottom: 16px;
  }
}
.Layout__title--small {
  font-weight: 600;
  margin-bottom: 20px;
}
@media (max-width: 899px) {
  .Layout__title--small {
    margin-bottom: 12px;
  }
}
.Layout__title--weightMedium {
  font-weight: 500;
}
.Layout__title--weightRegular {
  font-weight: 400;
}
.Layout__title--noMargin {
  margin: 0;
}

html[data-scroll-block=true] {
  overflow: hidden;
}
html[data-scroll-block=true] body {
  overflow: hidden;
  touch-action: pan-y pinch-zoom;
}

@media (max-width: 999px) {
  html[data-scroll-block-mobile=true] {
    overflow: hidden;
  }
  html[data-scroll-block-mobile=true] body {
    overflow: hidden;
    touch-action: pan-y pinch-zoom;
  }
}

.d-block {
  display: block;
}

.mb-4 {
  margin-bottom: 1rem;
}

.MobileBurger {
  position: relative;
  cursor: pointer;
  border: none;
  background-color: rgba(101, 104, 104, 0.05);
  outline: none;
  z-index: 99;
  size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
@media (max-width: 799px) {
  .MobileBurger {
    size: 40px;
  }
}
@media (hover: hover) {
  .MobileBurger:hover .MobileBurger__inner, .MobileBurger:hover .MobileBurger__inner::before, .MobileBurger:hover .MobileBurger__inner::after, .MobileBurger:focus .MobileBurger__inner, .MobileBurger:focus .MobileBurger__inner::before, .MobileBurger:focus .MobileBurger__inner::after, .MobileBurger:active .MobileBurger__inner, .MobileBurger:active .MobileBurger__inner::before, .MobileBurger:active .MobileBurger__inner::after {
    background-color: #e39e00;
  }
}
.MobileBurger__outer {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 1px;
}
.MobileBurger__inner, .MobileBurger__inner::before, .MobileBurger__inner::after {
  position: absolute;
  width: 100%;
  height: 2px;
  transition: background-color 0.25s ease-in-out, transform 0.15s ease;
  background-color: #131818;
}
.MobileBurger__inner {
  top: 60%;
  display: block;
}
.MobileBurger__inner::before, .MobileBurger__inner::after {
  display: block;
  content: "";
}
.MobileBurger__inner::before {
  top: -8px;
  transition: background-color 0.25s ease-in-out, top 75ms ease 0.12s, opacity 75ms ease;
}
.MobileBurger__inner::after {
  bottom: -8px;
  transition: background-color 0.25s ease-in-out, bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.LayoutTrigger--mobileOffcanvas:checked ~ .Layout .MobileBurger .MobileBurger__inner {
  background-color: #e39e00;
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}
.LayoutTrigger--mobileOffcanvas:checked ~ .Layout .MobileBurger .MobileBurger__inner::before {
  background-color: #e39e00;
  top: 0;
  transition: background-color 0.25s ease-in-out, top 75ms ease, opacity 75ms ease 0.12s;
  opacity: 0;
}
.LayoutTrigger--mobileOffcanvas:checked ~ .Layout .MobileBurger .MobileBurger__inner::after {
  background-color: #e39e00;
  bottom: 0;
  transition: background-color 0.25s ease-in-out, bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg);
}

.CityPopup {
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  padding-top: 16px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 99;
  transition-property: opacity, transform;
  transition: 0.25s ease-in-out;
}
@media (max-width: 999px) {
  .CityPopup {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -100%);
  }
}
@media (min-width: 1000px) {
  .uk-sticky.uk-active .CityPopup {
    margin-left: 48px;
  }
}
@media (min-width: 1000px) {
  .uk-sticky.uk-active .CityPopup::before {
    left: calc(50% - 48px);
  }
}
@media (min-width: 1000px) {
  .CityPopup--mobile {
    display: none;
  }
}
@media (max-width: 999px) {
  .CityPopup--desktop {
    display: none;
  }
}
.CityPopup--shown {
  opacity: 1;
  transform: translate(-50%, 0);
  pointer-events: auto;
}
@media (max-width: 999px) {
  .CityPopup--shown {
    transform: translate(-50%, -50%);
  }
}
.CityPopup--shown .CityPopup__body {
  box-shadow: 0 20px 40px rgba(19, 24, 24, 0.1);
}
.CityPopup::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  size: 28px;
  background-color: #2b2f2f;
  border-radius: 4px;
  transform: rotate(45deg);
  transform-origin: top left;
}
@media (max-width: 999px) {
  .CityPopup::before {
    display: none;
  }
}
.CityPopup__body {
  padding: 24px 32px 32px;
  background-color: #2b2f2f;
  transition: box-shadow 0.25s ease-in-out;
}
@media (max-width: 639px) {
  .CityPopup__body {
    padding: 16px 24px 24px;
  }
}
.CityPopup__heading {
  margin-bottom: 24px;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.28;
  text-align: center;
  color: white;
}
@media (max-width: 639px) {
  .CityPopup__heading {
    margin-bottom: 16px;
  }
}
.CityPopup__button {
  min-width: 112px;
}

@media (max-width: 999px) {
  .cityPopupShown .CityPopupOverlay {
    opacity: 1;
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.LayoutTrigger--mobileOffcanvas:checked ~ .Layout .OffCanvasOverlay {
  opacity: 1;
  z-index: 97;
}

.OffCanvas {
  position: fixed;
  top: var(--header-height);
  left: -320px;
  bottom: 0;
  width: 320px;
  max-width: 100vw;
  background-color: white;
  transition: transform 0.25s ease-in-out;
  z-index: 98;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 424px) {
  .OffCanvas {
    left: -100vw;
    width: 100vw;
  }
}
.LayoutTrigger--mobileOffcanvas:checked ~ .Layout .OffCanvas {
  transform: translateX(100%);
}
.OffCanvas__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 98;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.OffCanvas__locationPicker, .OffCanvas__nav, .OffCanvas__search, .OffCanvas__bottom {
  padding-x: 32px;
}
@media (max-width: 899px) {
  .OffCanvas__locationPicker, .OffCanvas__nav, .OffCanvas__search, .OffCanvas__bottom {
    padding-x: 32px;
  }
}
@media (max-width: 639px) {
  .OffCanvas__locationPicker, .OffCanvas__nav, .OffCanvas__search, .OffCanvas__bottom {
    padding-x: 16px;
  }
}
.OffCanvas__locationPicker {
  padding-y: 24px 16px;
}
.OffCanvas__nav {
  padding-y: 16px 24px;
}
.OffCanvas__search {
  padding-y: 16px;
}
@media (min-width: 800px) {
  .OffCanvas__search {
    display: none;
  }
}
.OffCanvas__bottom {
  margin-top: auto;
  padding-y: 0 48px;
}
.OffCanvas__phone, .OffCanvas__logout {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4;
  color: #131818;
  margin-bottom: 24px;
}
.OffCanvas__button:not(:last-child) {
  margin-bottom: 16px;
}

.LocationPicker {
  display: flex;
  align-items: center;
}
.LocationPicker__caption {
  margin-right: 8px;
  color: #656868;
  font-size: 1rem;
  white-space: nowrap;
}
@media (min-width: 1000px) {
  .uk-sticky.uk-active .LocationPicker__caption {
    display: none;
  }
}
.LocationPicker__buttonWrapper {
  position: relative;
}

.HeaderSearch {
  display: flex;
  width: 100%;
}
@media (max-width: 799px) {
  .HeaderSearch {
    flex-direction: column;
  }
}
.HeaderSearch__inputControls {
  flex-grow: 1;
  display: flex;
  box-shadow: 0 8px 30px -10px rgba(19, 24, 24, 0.3);
  margin-right: 8px;
}
@media (max-width: 799px) {
  .HeaderSearch__inputControls {
    box-shadow: none;
    margin-right: 0;
  }
}
.HeaderSearch__searchWrapper {
  display: flex;
  width: 100%;
}
@media (max-width: 799px) {
  .HeaderSearch__control {
    --form-default-border: rgba(101, 104, 104, 0.3);
    --form-hover-border: rgba(101, 104, 104, 0.3);
    --form-active-border: rgba(101, 104, 104, 0.3);
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media (max-width: 424px) {
  .HeaderSearch__control {
    font-size: 1rem;
  }
}
.HeaderSearch__desktopDropdown {
  flex-shrink: 0;
  width: 217px;
  background-color: white;
  border-left: 1px rgba(101, 104, 104, 0.2) solid;
}
@media (max-width: 799px) {
  .HeaderSearch__desktopDropdown {
    display: none;
  }
}
.HeaderSearch__mobileDropdown:not([multiple]):not([size]) {
  --form-default-color: #656868;
  --form-default-background: rgba(101, 104, 104, 0.1);
  --form-hover-color: #656868;
  --form-hover-background: rgba(101, 104, 104, 0.2);
  --form-active-color: #656868;
  --form-active-background: rgba(101, 104, 104, 0.2);
  padding: 8px 20px 8px 16px;
  border: none;
  height: auto;
  margin-top: 16px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}
@media (min-width: 800px) {
  .HeaderSearch__mobileDropdown:not([multiple]):not([size]) {
    display: none;
  }
}
@media (max-width: 999px) {
  .HeaderSearch__button {
    min-width: 48px;
    padding: 0;
  }
}
@media (max-width: 799px) {
  .HeaderSearch__button {
    border-color: rgba(101, 104, 104, 0.3);
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media (max-width: 424px) {
  .HeaderSearch__button {
    min-width: 44px;
  }
}
@media (max-width: 369px) {
  .HeaderSearch__button {
    width: auto;
  }
}
.uk-sticky.uk-active .HeaderSearch__button {
  min-width: 48px;
  padding: 0;
}
.HeaderSearch__buttonIcon {
  display: none;
  width: 17px;
}
@media (max-width: 999px) {
  .HeaderSearch__buttonIcon {
    display: block;
  }
}
.uk-sticky.uk-active .HeaderSearch__buttonIcon {
  display: block;
}
@media (max-width: 999px) {
  .HeaderSearch__buttonText {
    display: none;
  }
}
.uk-sticky.uk-active .HeaderSearch__buttonText {
  display: none;
}

.SiteHeader {
  width: 100% !important;
}
@media (max-width: 999px) {
  .SiteHeader {
    position: fixed;
    top: 0;
    z-index: 99;
  }
}
.SiteHeader.uk-sticky.uk-active .SiteHeader__top {
  display: none;
}
@media (min-width: 1000px) {
  .SiteHeader.uk-sticky.uk-active .SiteHeader__main {
    padding-y: 16px;
    box-shadow: 0 4px 30px rgba(19, 24, 24, 0.1);
  }
}
@media (min-width: 1000px) {
  .SiteHeader.uk-sticky.uk-active .SiteHeader__mainGrid {
    grid-template-areas: "logo locationPicker svp search cart";
    grid-template-columns: minmax(auto, 48px) auto minmax(auto, 48px) 1fr auto;
    grid-column-gap: 24px;
  }
}
.SiteHeader.uk-sticky.uk-active .SiteHeader__logo--large {
  display: none;
}
.SiteHeader.uk-sticky.uk-active .SiteHeader__logo--small {
  display: block;
}
@media (min-width: 800px) {
  .SiteHeader.uk-sticky.uk-active .SiteHeader__searchWrapper {
    display: block;
  }
}
.SiteHeader.uk-sticky.uk-active .SiteHeader__bottomSearch {
  display: none;
}
.SiteHeader.uk-sticky.uk-active .SiteHeader__phoneWrapper {
  display: none;
}
.SiteHeader__top {
  background-color: #2b2f2f;
}
@media (max-width: 999px) {
  .SiteHeader__top {
    display: none;
  }
}
.SiteHeader__main {
  position: relative;
  z-index: 99;
  padding-y: 24px;
  background-color: white;
}
@media (max-width: 999px) {
  .SiteHeader__main {
    padding-y: 16px;
    box-shadow: 0 4px 30px rgba(19, 24, 24, 0.1);
  }
}
@media (max-width: 799px) {
  .SiteHeader__main {
    padding-y: 12px;
  }
}
.SiteHeader__topGrid {
  display: flex;
  align-items: center;
}
.SiteHeader__mainGrid {
  display: grid;
  grid-template-areas: "logo locationPicker svp phone cart";
  grid-template-columns: auto 1fr minmax(auto, 80px) auto auto;
  grid-column-gap: 48px;
  align-items: center;
}
@media (max-width: 999px) {
  .SiteHeader__mainGrid {
    grid-template-areas: "mobile logo svp search cart";
    grid-template-columns: auto auto minmax(auto, 48px) 1fr auto;
    grid-column-gap: 24px;
  }
}
@media (max-width: 799px) {
  .SiteHeader__mainGrid {
    grid-template-areas: "mobile logo cart";
    grid-template-columns: repeat(3, 1fr);
    display: flex;
  }
}
.SiteHeader__mobileWrapper {
  grid-area: mobile;
}
@media (min-width: 1000px) {
  .SiteHeader__mobileWrapper {
    display: none;
  }
}
.SiteHeader__logoWrapper {
  grid-area: logo;
}
@media (max-width: 799px) {
  .SiteHeader__logoWrapper {
    margin: 0 auto;
  }
}
.SiteHeader__locationPickerWrapper {
  grid-area: locationPicker;
}
@media (max-width: 999px) {
  .SiteHeader__locationPickerWrapper {
    display: none;
  }
}
.SiteHeader__sdelanoVPrymorie {
  grid-area: svp;
  cursor: pointer;
}
.SiteHeader__sdelanoVPrymorie img {
  max-width: 80px;
  width: 100%;
}
.SiteHeader__sdelanoVPrymorie:hover {
  opacity: 0.75;
}
@media (max-width: 799px) {
  .SiteHeader__sdelanoVPrymorie img {
    max-width: 48px;
  }
}
.SiteHeader__phoneWrapper {
  grid-area: phone;
}
@media (max-width: 999px) {
  .SiteHeader__phoneWrapper {
    display: none;
  }
}
@media (max-width: 799px) {
  .SiteHeader__phoneWrapper {
    display: inline-flex;
  }
  .SiteHeader__phoneWrapper .SiteHeader__phoneText {
    display: none;
  }
  .SiteHeader__phoneWrapper .SiteHeader__phoneIcon {
    width: 40px;
    height: 40px;
    margin-right: 0;
  }
}
.SiteHeader__searchWrapper {
  display: none;
}
@media (max-width: 999px) {
  .SiteHeader__searchWrapper {
    display: block;
  }
}
@media (max-width: 799px) {
  .SiteHeader__searchWrapper {
    display: none;
  }
}
.SiteHeader__cartWrapper {
  grid-area: cart;
}
@media (max-width: 799px) {
  .SiteHeader__cartWrapper {
    margin-left: auto;
  }
}
.SiteHeader__bottomSearch {
  padding-y: 12px;
  background-color: rgba(101, 104, 104, 0.05);
}
@media (max-width: 999px) {
  .SiteHeader__bottomSearch {
    display: none;
  }
}
@media (min-width: 1000px) {
  .SiteHeader__favoritesButton {
    min-height: 40px;
    height: 40px;
    font-size: 1rem;
    margin-left: auto;
    background-color: transparent;
    border: none;
    color: white;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .SiteHeader__favoritesButton {
    font-size: 0.875rem;
  }
}
@media (min-width: 1000px) {
  .SiteHeader__favoritesButton:hover, .SiteHeader__favoritesButton:focus, .SiteHeader__favoritesButton:active {
    color: #fccf17;
  }
}
@media (max-width: 999px) {
  .SiteHeader__favoritesButton {
    --button-default-color: #131818;
    --button-default-border: #131818;
    --button-default-background: white;
    --button-hover-color: white;
    --button-hover-border: #464a4a;
    --button-hover-background: #464a4a;
    --button-focus-color: white;
    --button-focus-border: #464a4a;
    --button-focus-background: #464a4a;
    --button-active-color: white;
    --button-active-border: #2b2f2f;
    --button-active-background: #2b2f2f;
    --button-disabled-color: #464a4a;
    --button-disabled-border: #464a4a;
    --button-disabled-background: white;
    width: 100%;
  }
}
.SiteHeader__favoritesButtonIcon {
  margin-top: -2px;
}
.SiteHeader__profileButton, .SiteHeader__impersonationModeButton {
  border-radius: 0;
}
@media (min-width: 1000px) {
  .SiteHeader__profileButton, .SiteHeader__impersonationModeButton {
    min-height: 40px;
    height: 40px;
    font-size: 1rem;
    --button-default-color: white;
    --button-default-border: #464a4a;
    --button-default-background: #464a4a;
    --button-hover-color: #fccf17;
    --button-hover-border: #383b3b;
    --button-hover-background: #383b3b;
    --button-focus-color: #fccf17;
    --button-focus-border: #383b3b;
    --button-focus-background: #383b3b;
    --button-active-color: #fccf17;
    --button-active-border: #2b2f2f;
    --button-active-background: #2b2f2f;
    --button-disabled-color: rgba(255, 255, 255, 0.8);
    --button-disabled-border: transparent;
    --button-disabled-background: rgba(70, 74, 74, 0.4);
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .SiteHeader__profileButton, .SiteHeader__impersonationModeButton {
    font-size: 0.875rem;
  }
}
@media (max-width: 999px) {
  .SiteHeader__profileButton, .SiteHeader__impersonationModeButton {
    --button-default-color: white;
    --button-default-border: #131818;
    --button-default-background: #131818;
    --button-hover-color: white;
    --button-hover-border: #464a4a;
    --button-hover-background: #464a4a;
    --button-focus-color: white;
    --button-focus-border: #464a4a;
    --button-focus-background: #464a4a;
    --button-active-color: white;
    --button-active-border: #2b2f2f;
    --button-active-background: #2b2f2f;
    --button-disabled-color: rgba(101, 104, 104, 0.5);
    --button-disabled-border: transparent;
    --button-disabled-background: rgba(101, 104, 104, 0.1);
    width: 100%;
  }
}
.SiteHeader__logoutButton {
  min-width: 40px;
  border-radius: 0;
  padding: 0;
}
@media (min-width: 1000px) {
  .SiteHeader__logoutButton {
    min-height: 40px;
    height: 40px;
    font-size: 1rem;
    --button-default-color: white;
    --button-default-border: #464a4a;
    --button-default-background: #464a4a;
    --button-hover-color: #fccf17;
    --button-hover-border: #383b3b;
    --button-hover-background: #383b3b;
    --button-focus-color: #fccf17;
    --button-focus-border: #383b3b;
    --button-focus-background: #383b3b;
    --button-active-color: #fccf17;
    --button-active-border: #2b2f2f;
    --button-active-background: #2b2f2f;
    --button-disabled-color: rgba(255, 255, 255, 0.8);
    --button-disabled-border: transparent;
    --button-disabled-background: rgba(70, 74, 74, 0.4);
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 999px) {
  .SiteHeader__logoutButton {
    --button-default-color: white;
    --button-default-border: #131818;
    --button-default-background: #131818;
    --button-hover-color: white;
    --button-hover-border: #464a4a;
    --button-hover-background: #464a4a;
    --button-focus-color: white;
    --button-focus-border: #464a4a;
    --button-focus-background: #464a4a;
    --button-active-color: white;
    --button-active-border: #2b2f2f;
    --button-active-background: #2b2f2f;
    --button-disabled-color: rgba(101, 104, 104, 0.5);
    --button-disabled-border: transparent;
    --button-disabled-background: rgba(101, 104, 104, 0.1);
    border-radius: 2px;
  }
}
.SiteHeader__logo {
  transition: opacity 0.25s ease-in-out;
}
.SiteHeader__logo:hover {
  opacity: 0.65;
}
.SiteHeader__logo--large {
  width: 162px;
  height: 76px;
}
@media (max-width: 1199px) {
  .SiteHeader__logo--large {
    width: 132px;
    height: 62px;
  }
}
@media (max-width: 999px) {
  .SiteHeader__logo--large {
    display: none;
  }
}
.SiteHeader__logo--small {
  width: 48px;
  display: none;
}
@media (max-width: 999px) {
  .SiteHeader__logo--small {
    display: block;
  }
}
@media (max-width: 799px) {
  .SiteHeader__logo--small {
    width: 40px;
  }
}
.SiteHeader__phone {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: #2b2f2f;
  font-size: 1.125rem;
  transition: color 0.25s ease-in-out;
}
.SiteHeader__phone:hover, .SiteHeader__phone:focus, .SiteHeader__phone:active {
  color: #e39e00;
}
.SiteHeader__phone:hover .SiteHeader__phoneIcon, .SiteHeader__phone:focus .SiteHeader__phoneIcon, .SiteHeader__phone:active .SiteHeader__phoneIcon {
  background-color: rgba(101, 104, 104, 0.1);
}
.SiteHeader__phoneIcon {
  width: 48px;
  height: 48px;
  border-radius: 2px;
  box-sizing: content-box;
  color: #2b2f2f;
  background-color: rgba(101, 104, 104, 0.05);
  transition: background-color 0.25s ease-in-out;
  margin-right: 16px;
}
.SiteHeader__phoneIcon svg {
  width: 20px;
  height: 20px;
}
.SiteHeader .ny-attention {
  padding: 5px;
  border: solid 2px #fccf17;
  text-align: center;
  font-size: 1.1rem;
  background-color: #ffffff;
}
@media (max-width: 799px) {
  .SiteHeader .ny-attention {
    display: none;
    padding: 1px;
    border: solid 1px #fccf17;
    text-align: center;
    font-size: 0.8rem;
  }
}
.SiteHeader .ny-attention-xs {
  display: none;
}
@media (max-width: 799px) {
  .SiteHeader .ny-attention-xs {
    display: block;
    padding: 1px;
    border: solid 1px #fccf17;
    text-align: center;
    font-size: 0.8rem;
  }
}

.SiteFooter {
  position: relative;
}
.SiteFooter::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(19, 24, 24, 0.9);
  backdrop-filter: blur(30px);
  display: block;
}
.SiteFooter__containerMain {
  display: grid;
  grid-template-areas: "logo menuPrimary menuSecondary formButton" "logo menuPrimary menuSecondary feedbackButton" "contacts menuPrimary menuSecondary .";
  grid-template-columns: 1fr auto 1fr auto;
  column-gap: 56px;
  padding-y: 56px;
}
@media (max-width: 1199px) {
  .SiteFooter__containerMain {
    grid-template-areas: "logo menuPrimary menuSecondary" "contacts menuPrimary menuSecondary" "contacts formButton feedbackButton";
    grid-template-columns: auto auto 1fr;
  }
}
@media (max-width: 999px) {
  .SiteFooter__containerMain {
    grid-template-areas: "logo menuPrimary formButton" "logo menuPrimary feedbackButton" "contacts menuPrimary feedbackButton" "contacts menuSecondary .";
    grid-template-columns: auto max-content minmax(200px, 256px);
    grid-template-rows: min-content min-content 1fr auto;
  }
}
@media (max-width: 899px) {
  .SiteFooter__containerMain {
    padding-y: 37px;
    grid-template-areas: "logo logo" "menuPrimary menuSecondary" "contacts formButton" "contacts feedbackButton";
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto min-content min-content;
    grid-row-gap: 24px;
  }
}
@media (max-width: 639px) {
  .SiteFooter__containerMain {
    padding-y: 28px;
    grid-template-areas: "logo" "formButton" "feedbackButton" "menuPrimary" "menuSecondary" "contacts";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
.SiteFooter__logo {
  grid-area: logo;
}
@media (max-width: 899px) {
  .SiteFooter__logo {
    margin-bottom: 16px;
  }
}
.SiteFooter__logoLink {
  display: block;
  color: #656868;
  max-width: 162px;
}
.SiteFooter__logoLink:hover, .SiteFooter__logoLink:focus, .SiteFooter__logoLink:active {
  color: #fccf17;
}
.SiteFooter__logoLink svg {
  width: 100%;
}
.SiteFooter__menuPrimary {
  grid-area: menuPrimary;
}
@media (max-width: 899px) {
  .SiteFooter__menuPrimary {
    margin-bottom: 16px;
  }
}
@media (max-width: 639px) {
  .SiteFooter__menuPrimary {
    margin-top: 8px;
    margin-bottom: 0;
  }
}
.SiteFooter__menuSecondary {
  grid-area: menuSecondary;
}
@media (max-width: 999px) {
  .SiteFooter__menuSecondary {
    margin-top: 32px;
  }
}
@media (max-width: 899px) {
  .SiteFooter__menuSecondary {
    margin-top: 0;
    margin-bottom: 16px;
  }
}
@media (max-width: 639px) {
  .SiteFooter__menuSecondary {
    margin-bottom: 0;
  }
}
.SiteFooter__formButton {
  grid-area: formButton;
}
@media (max-width: 1199px) {
  .SiteFooter__formButton {
    margin-top: 32px;
  }
}
@media (max-width: 999px) {
  .SiteFooter__formButton {
    margin-top: 0;
  }
}
.SiteFooter__formButtonSecond {
  margin-top: 24px;
}
.SiteFooter__feedbackButton {
  grid-area: feedbackButton;
  margin-top: 24px;
}
@media (max-width: 1199px) {
  .SiteFooter__feedbackButton {
    margin-top: 32px;
  }
}
@media (max-width: 999px) {
  .SiteFooter__feedbackButton {
    margin-top: 24px;
  }
}
@media (max-width: 899px) {
  .SiteFooter__feedbackButton {
    margin-top: 0;
  }
}
.SiteFooter__contacts {
  grid-area: contacts;
  display: flex;
  flex-direction: column;
  max-width: 279px;
  margin-top: 12px;
}
@media (max-width: 1199px) {
  .SiteFooter__contacts {
    justify-content: flex-end;
    margin-top: 0;
    padding-bottom: 10px;
  }
}
@media (max-width: 999px) {
  .SiteFooter__contacts {
    justify-content: flex-start;
    margin-top: 32px;
    padding-bottom: 0;
  }
}
@media (max-width: 899px) {
  .SiteFooter__contacts {
    margin-top: 0;
  }
}
@media (max-width: 639px) {
  .SiteFooter__contacts {
    margin-top: 8px;
  }
}
.SiteFooter__phone {
  color: white;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 1.375rem;
  font-weight: 600;
}
.SiteFooter__phone:hover, .SiteFooter__phone:focus, .SiteFooter__phone:active {
  color: #fccf17;
}
.SiteFooter__phoneIcon {
  flex-shrink: 0;
  margin-right: 12px;
  margin-top: -1px;
}
.SiteFooter__email {
  color: white;
  display: flex;
  width: fit-content;
  margin-top: 16px;
}
.SiteFooter__email:hover, .SiteFooter__email:focus, .SiteFooter__email:active {
  color: #fccf17;
}
.SiteFooter__socials {
  margin-top: 56px;
}
@media (max-width: 899px) {
  .SiteFooter__socials {
    margin-top: 37px;
  }
}
@media (max-width: 639px) {
  .SiteFooter__socials {
    margin-top: 28px;
  }
}
.SiteFooter__containerBottom {
  background-color: rgba(19, 24, 24, 0.2);
}
.SiteFooter__containerBottomInner {
  display: grid;
  grid-template-columns: repeat(2, max-content) 1fr;
  width: 100%;
  padding-y: 16px;
  align-items: center;
  color: white;
}
@media (max-width: 899px) {
  .SiteFooter__containerBottomInner {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 639px) {
  .SiteFooter__containerBottomInner {
    grid-template-columns: 1fr;
  }
}
.SiteFooter__containerBottomFirst {
  background-color: rgba(19, 24, 24, 0.3);
  padding: 7px 0;
  text-align: justify;
  color: #cecece;
}
.SiteFooter__privacy {
  display: flex;
}
@media (max-width: 639px) {
  .SiteFooter__privacy {
    flex-wrap: wrap;
    max-width: 270px;
  }
}
@media (max-width: 899px) {
  .SiteFooter__privacy {
    grid-column: 1/3;
    grid-row: 2;
  }
}
@media (max-width: 639px) {
  .SiteFooter__privacy {
    grid-column: 1;
  }
}
.SiteFooter__privacyLink {
  font-size: 1rem;
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.3);
  transition: color 0.25s ease-in-out;
}
.SiteFooter__privacyLink:hover, .SiteFooter__privacyLink:focus, .SiteFooter__privacyLink:active {
  color: white;
  text-decoration: underline;
}
.SiteFooter__privacyLink:not(:last-child) {
  margin-right: 24px;
}
.SiteFooter__copyright {
  color: rgba(255, 255, 255, 0.3);
  margin-right: 24px;
}
@media (max-width: 899px) {
  .SiteFooter__copyright {
    margin-right: 0;
    grid-row: 1;
    grid-column: 1;
  }
}
.SiteFooter__feipLogo {
  color: white;
  transition: opacity 0.25s ease-in-out;
  opacity: 0.3;
  position: relative;
  top: 1px;
  margin-left: auto;
}
@media (max-width: 899px) {
  .SiteFooter__feipLogo {
    grid-row: 2;
    grid-column: 2;
  }
}
@media (max-width: 639px) {
  .SiteFooter__feipLogo {
    grid-row: 3;
    grid-column: 1;
    margin: 16px 0 0;
  }
}
.SiteFooter__feipLogo:hover {
  opacity: 1;
  color: white;
}
.SiteFooter__feipLogo svg {
  fill: white;
  transition: opacity 0.25s ease-in-out;
}
.SiteFooter__feipLogo svg:hover {
  opacity: 1;
}

.SiteNav {
  display: flex;
  flex-wrap: wrap;
}
.SiteNav--modeHeader .SiteNav__listItem {
  margin: 0 32px 0 0;
}
@media (max-width: 1199px) {
  .SiteNav--modeHeader .SiteNav__listItem {
    margin: 0 20px 0 0;
  }
}
.SiteNav--modeHeader .SiteNav__item {
  color: white;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .SiteNav--modeHeader .SiteNav__item {
    font-size: 0.875rem;
  }
}
.SiteNav--modeHeader .SiteNav__item:hover, .SiteNav--modeHeader .SiteNav__item:focus, .SiteNav--modeHeader .SiteNav__item:active, .SiteNav--modeHeader .SiteNav__item--active {
  color: #fccf17;
}
.SiteNav--modeFooterPrimary, .SiteNav--modeFooterSecondary {
  flex-direction: column;
}
.SiteNav--modeFooterPrimary .SiteNav__listItem, .SiteNav--modeFooterSecondary .SiteNav__listItem {
  margin: 0 0 16px;
}
@media (max-width: 639px) {
  .SiteNav--modeFooterPrimary .SiteNav__listItem, .SiteNav--modeFooterSecondary .SiteNav__listItem {
    margin-bottom: 12px;
  }
}
.SiteNav--modeFooterPrimary .SiteNav__item {
  color: white;
  font-weight: 600;
}
.SiteNav--modeFooterPrimary .SiteNav__item:hover, .SiteNav--modeFooterPrimary .SiteNav__item:focus, .SiteNav--modeFooterPrimary .SiteNav__item:active, .SiteNav--modeFooterPrimary .SiteNav__item--active {
  color: #fccf17;
}
.SiteNav--modeFooterSecondary .SiteNav__item {
  color: rgba(255, 255, 255, 0.7);
}
.SiteNav--modeFooterSecondary .SiteNav__item:hover, .SiteNav--modeFooterSecondary .SiteNav__item:focus, .SiteNav--modeFooterSecondary .SiteNav__item:active {
  color: white;
}
.SiteNav--modeFooterSecondary .SiteNav__item--active {
  color: #fccf17;
}
.SiteNav--modeMobile {
  flex-direction: column;
}
.SiteNav--modeMobile .SiteNav__listItem {
  margin: 0 0 12px;
}
.SiteNav--modeMobile .SiteNav__item {
  font-size: 1.25rem;
  font-weight: 600;
  color: #131818;
}
.SiteNav--modeMobile .SiteNav__item:hover, .SiteNav--modeMobile .SiteNav__item:focus, .SiteNav--modeMobile .SiteNav__item:active {
  color: #e39e00;
}
.SiteNav__listItem {
  margin: 0 16px 0 0;
}
.SiteNav__listItem:last-child {
  margin: 0;
}

.SocialsList {
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-gap: 12px 24px;
  width: 100%;
}
@media (max-width: 369px) {
  .SocialsList {
    grid-column-gap: 14px;
  }
}
.SocialsList__item {
  display: flex;
  align-items: center;
  min-height: 26px;
}
.SocialsList__link {
  color: white;
}
.SocialsList__link:hover, .SocialsList__link:focus, .SocialsList__link:active {
  opacity: 0.5;
}
.SocialsList__link svg {
  min-width: 24px;
}
.SocialsList__image {
  width: 78px;
}

.CartWidget {
  position: relative;
}
.CartWidget:hover .CartWidget__icon, .CartWidget:focus .CartWidget__icon, .CartWidget:active .CartWidget__icon {
  background-color: rgba(101, 104, 104, 0.1);
}
.CartWidget:hover .CartWidget__contentTitle, .CartWidget:focus .CartWidget__contentTitle, .CartWidget:active .CartWidget__contentTitle {
  color: #e39e00;
}
.CartWidget:hover .CartWidget__miniCartWrapper, .CartWidget:focus .CartWidget__miniCartWrapper, .CartWidget:active .CartWidget__miniCartWrapper {
  transform: scaleY(1);
  box-shadow: 0 2px 30px rgba(19, 24, 24, 0.1);
  transition-delay: 0s;
}
.CartWidget:hover .CartWidget__miniCart, .CartWidget:focus .CartWidget__miniCart, .CartWidget:active .CartWidget__miniCart {
  opacity: 1;
  transition-delay: 0.15s;
}
.CartWidget__link {
  display: inline-flex;
  align-items: center;
  line-height: 23px;
}
@media (max-width: 999px) {
  .CartWidget__link {
    display: none;
  }
}
.CartWidget__link--mobileToggle {
  display: none;
}
@media (max-width: 999px) {
  .CartWidget__link--mobileToggle {
    display: block;
  }
}
.CartWidget__icon {
  size: 48px;
  border-radius: 2px;
  box-sizing: content-box;
  color: #2b2f2f;
  background-color: rgba(101, 104, 104, 0.05);
  transition: background-color 0.25s ease-in-out;
  margin-right: 16px;
}
@media (max-width: 999px) {
  .CartWidget__icon {
    margin-right: 0;
  }
}
@media (max-width: 799px) {
  .CartWidget__icon {
    size: 40px;
  }
}
.CartWidget__icon svg {
  size: 20px;
}
.CartWidget__cartAmount {
  position: absolute;
  top: -9px;
  left: 31px;
  size: 24px;
  color: white;
  border-radius: 50%;
  background: #e39e00;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
}
@media (max-width: 799px) {
  .CartWidget__cartAmount {
    top: -6px;
    size: 22px;
  }
}
.CartWidget__cartAmount--small {
  font-size: 0.875rem;
}
.CartWidget__cartAmount--xSmall {
  font-size: 0.75rem;
}
@media (max-width: 999px) {
  .CartWidget__content {
    display: none;
  }
}
.CartWidget__contentTitle {
  font-weight: 600;
  color: #2b2f2f;
  font-size: 1.125rem;
  transition: color 0.25s ease-in-out;
}
.CartWidget__totalPrice {
  display: block;
  margin-top: 4px;
  color: #e39e00;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1;
}
.CartWidget__miniCartWrapper {
  position: absolute;
  top: 100%;
  right: 100%;
  margin-right: -133px;
  background-color: white;
  min-width: 250px;
  margin-top: 8px;
  border-radius: 2px;
  box-shadow: none;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.15s ease-in-out, box-shadow 0.25s ease-in-out;
  transition-delay: 0.15s;
  z-index: 98;
}
@media (max-width: 999px) {
  .CartWidget__miniCartWrapper {
    display: none;
  }
}
.CartWidget__miniCartWrapper::before, .CartWidget__miniCartWrapper::after {
  content: "";
  position: absolute;
}
.CartWidget__miniCartWrapper::before {
  height: 8px;
  width: 100%;
  top: -8px;
}
.CartWidget__miniCartWrapper::after {
  top: -20px;
  right: 93px;
  size: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 25px solid white;
}
.CartWidget__miniCart {
  opacity: 0;
  transition-delay: 0.15s;
  transition: opacity 0.15s ease-in-out;
}

.FilePicker {
  display: flex;
  flex-direction: column;
}
.FilePicker__itemsList {
  margin-top: 24px;
}
.FilePicker__item {
  display: flex;
  align-items: center;
}
.FilePicker__item:not(:last-child) {
  margin-bottom: 20px;
}
.FilePicker__input {
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.FilePicker__removeButton {
  size: 20px;
  background-color: rgba(101, 104, 104, 0.1);
  border: none;
  border-radius: 2px;
  outline: none;
  color: #131818;
  cursor: pointer;
  transition-property: color, background;
  transition: 0.25s ease-in-out;
}
.FilePicker__removeButton:hover, .FilePicker__removeButton:focus, .FilePicker__removeButton:active {
  outline: none;
  background-color: rgba(242, 40, 20, 0.1);
  color: #f22814;
}
.FilePicker__removeButtonText {
  position: relative;
  line-height: 1;
  transform: rotate(45deg);
  font-size: 1.5rem;
}
.FilePicker__filename {
  margin-left: 12px;
  font-size: 1.125rem;
  line-height: 1.28;
  color: #464a4a;
}

.Tabs--buttonTabs .Tabs__button, .Tabs--modeWhiteShadow .Tabs__itemsList .Tabs__button, .Tabs--modeCatalog .Tabs__itemsList .Tabs__button, .Tabs--modeGray .Tabs__itemsList .Tabs__button {
  color: var(--tab-default-color);
  border-color: var(--tab-default-border);
  background-color: var(--tab-default-background);
  box-shadow: var(--tab-default-shadow);
  min-height: 48px;
  padding: 8px 16px;
  vertical-align: middle;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 2px;
}
.Tabs--buttonTabs .Tabs__button:hover, .Tabs--modeWhiteShadow .Tabs__itemsList .Tabs__button:hover, .Tabs--modeCatalog .Tabs__itemsList .Tabs__button:hover, .Tabs--modeGray .Tabs__itemsList .Tabs__button:hover, .Tabs--buttonTabs .Tabs__button:focus, .Tabs--modeWhiteShadow .Tabs__itemsList .Tabs__button:focus, .Tabs--modeCatalog .Tabs__itemsList .Tabs__button:focus, .Tabs--modeGray .Tabs__itemsList .Tabs__button:focus {
  color: var(--tab-hover-color);
  border-color: var(--tab-hover-border);
  background-color: var(--tab-hover-background);
  box-shadow: var(--tab-hover-shadow);
}
.Tabs--buttonTabs .Tabs__button:active, .Tabs--modeWhiteShadow .Tabs__itemsList .Tabs__button:active, .Tabs--modeCatalog .Tabs__itemsList .Tabs__button:active, .Tabs--modeGray .Tabs__itemsList .Tabs__button:active, .Tabs--buttonTabs .Tabs__button.active, .Tabs--modeWhiteShadow .Tabs__itemsList .Tabs__button.active, .Tabs--modeCatalog .Tabs__itemsList .Tabs__button.active, .Tabs--modeGray .Tabs__itemsList .Tabs__button.active {
  color: var(--tab-active-color);
  border-color: var(--tab-active-border);
  background-color: var(--tab-active-background);
  box-shadow: var(--tab-active-shadow);
}
.Tabs--buttonTabs .Tabs__item.uk-active .Tabs__button, .Tabs--modeWhiteShadow .Tabs__itemsList .Tabs__item.uk-active .Tabs__button, .Tabs--modeCatalog .Tabs__itemsList .Tabs__item.uk-active .Tabs__button, .Tabs--modeGray .Tabs__itemsList .Tabs__item.uk-active .Tabs__button {
  color: var(--tab-active-color);
  border-color: var(--tab-active-border);
  background-color: var(--tab-active-background);
  box-shadow: var(--tab-active-shadow);
}
.Tabs--modeGray .Tabs__itemsList {
  background-color: rgba(101, 104, 104, 0.05);
}
.Tabs--modeGray .Tabs__item {
  position: relative;
  flex-grow: 1;
}
.Tabs--modeGray .Tabs__item:first-child .Tabs__button::before {
  display: none;
}
.Tabs--modeGray .Tabs__button {
  --tab-default-color: rgba(43, 47, 47, 0.7);
  --tab-default-background: rgba(101, 104, 104, 0.05);
  --tab-default-border: transparent;
  --tab-hover-color: #2b2f2f;
  --tab-hover-background: rgba(101, 104, 104, 0.15);
  --tab-hover-border: transparent;
  --tab-active-color: #fccf17;
  --tab-active-background: #131818;
  --tab-active-border: transparent;
  width: 100%;
}
.Tabs--modeGray .Tabs__button.active::before, .Tabs--modeGray .Tabs__button:active::before {
  display: none;
}
.Tabs--modeGray .Tabs__button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -1px;
  width: 1px;
  height: 32px;
  background-color: rgba(101, 104, 104, 0.2);
  transform: translateY(-50%);
}
.Tabs--modeGray .Tabs__item.uk-active .Tabs__button::before {
  display: none;
}
.Tabs--modeGray .Tabs__mobileDropdown {
  color: #2b2f2f;
  background-color: rgba(101, 104, 104, 0.1);
  border: 1px rgba(101, 104, 104, 0.2) solid;
}
.Tabs--modeWhiteShadow .Tabs__itemsList, .Tabs--modeCatalog .Tabs__itemsList {
  margin-x: -4px;
}
.Tabs--modeWhiteShadow .Tabs__item, .Tabs--modeCatalog .Tabs__item {
  margin-x: 4px;
  flex-grow: 1;
  text-align: center;
}
.Tabs--modeWhiteShadow .Tabs__button, .Tabs--modeCatalog .Tabs__button {
  --tab-default-color: #131818;
  --tab-default-border: white;
  --tab-default-background: white;
  --tab-default-shadow: 0 6px 30px -10px rgba(19, 24, 24, 0.3);
  --tab-hover-color: #131818;
  --tab-hover-border: white;
  --tab-hover-background: white;
  --tab-hover-shadow: 0 4px 20px -5px rgba(19, 24, 24, 0.4);
  --tab-active-color: #131818;
  --tab-active-border: #fccf17;
  --tab-active-background: white;
  --tab-active-shadow: none;
  border-width: 3px;
  width: 100%;
}
.Tabs--modeWhiteShadow .Tabs__mobileDropdown, .Tabs--modeCatalog .Tabs__mobileDropdown {
  --form-default-label-color: rgba(101, 104, 104, 0.7);
  --form-default-text-color: #131818;
  --form-default-background: white;
  --form-default-border: #656868;
  --form-default-option-color: #131818;
  --form-default-option-background: white;
  --form-default-dropdown-arrow-source: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%228%22%20viewBox%3D%220%200%2024%208%22%20fill%3D%22none%22%20stroke%3D%22%23131818%22%20stroke-width%3D%222%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1%201L6%206L11%201%22%2F%3E%0A%3C%2Fsvg%3E");
  --form-hover-label-color: #131818;
  --form-hover-text-color: #131818;
  --form-hover-background: white;
  --form-hover-border: #656868;
  --form-hover-option-color: #131818;
  --form-hover-option-background: #dcdcdc;
  --form-active-label-color: #131818;
  --form-active-text-color: #131818;
  --form-active-background: white;
  --form-active-border: #656868;
  --form-active-option-color: #e39e00;
  --form-active-option-background: white;
  --form-disabled-label-color: #656868;
  --form-disabled-text-color: #656868;
  --form-disabled-background: #c4c4c4;
  --form-disabled-border: #c4c4c4;
  border: 3px #fccf17 solid;
}
.Tabs--underlinedTabs .Tabs__itemsList, .Tabs--modeGrayUnderlined .Tabs__itemsList, .Tabs--modeLegalGrayUnderlined .Tabs__itemsList, .Tabs--modeDarkUnderlined .Tabs__itemsList, .Tabs--modeSearchDetails .Tabs__itemsList {
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.Tabs--underlinedTabs .Tabs__itemsList::after, .Tabs--modeGrayUnderlined .Tabs__itemsList::after, .Tabs--modeLegalGrayUnderlined .Tabs__itemsList::after, .Tabs--modeDarkUnderlined .Tabs__itemsList::after, .Tabs--modeSearchDetails .Tabs__itemsList::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--tab-default-border);
}
.Tabs--underlinedTabs .Tabs__button, .Tabs--modeGrayUnderlined .Tabs__button, .Tabs--modeLegalGrayUnderlined .Tabs__button, .Tabs--modeDarkUnderlined .Tabs__button, .Tabs--modeSearchDetails .Tabs__button {
  color: var(--tab-default-color);
  position: relative;
  padding: 0 0 8px;
  line-height: 1;
  font-size: 1.125rem;
  font-weight: 500;
  background: none;
}
.Tabs--underlinedTabs .Tabs__button:hover, .Tabs--modeGrayUnderlined .Tabs__button:hover, .Tabs--modeLegalGrayUnderlined .Tabs__button:hover, .Tabs--modeDarkUnderlined .Tabs__button:hover, .Tabs--modeSearchDetails .Tabs__button:hover, .Tabs--underlinedTabs .Tabs__button:focus, .Tabs--modeGrayUnderlined .Tabs__button:focus, .Tabs--modeLegalGrayUnderlined .Tabs__button:focus, .Tabs--modeDarkUnderlined .Tabs__button:focus, .Tabs--modeSearchDetails .Tabs__button:focus, .Tabs--underlinedTabs .Tabs__button:active, .Tabs--modeGrayUnderlined .Tabs__button:active, .Tabs--modeLegalGrayUnderlined .Tabs__button:active, .Tabs--modeDarkUnderlined .Tabs__button:active, .Tabs--modeSearchDetails .Tabs__button:active {
  color: var(--tab-hover-color);
}
.Tabs--underlinedTabs .Tabs__button::after, .Tabs--modeGrayUnderlined .Tabs__button::after, .Tabs--modeLegalGrayUnderlined .Tabs__button::after, .Tabs--modeDarkUnderlined .Tabs__button::after, .Tabs--modeSearchDetails .Tabs__button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--tab-active-border);
  transform: scaleX(0);
  will-change: transform;
  transition: transform 0.25s ease-in-out;
}
.Tabs--underlinedTabs .Tabs__button.active, .Tabs--modeGrayUnderlined .Tabs__button.active, .Tabs--modeLegalGrayUnderlined .Tabs__button.active, .Tabs--modeDarkUnderlined .Tabs__button.active, .Tabs--modeSearchDetails .Tabs__button.active {
  color: var(--tab-active-color);
}
.Tabs--underlinedTabs .Tabs__button.active::after, .Tabs--modeGrayUnderlined .Tabs__button.active::after, .Tabs--modeLegalGrayUnderlined .Tabs__button.active::after, .Tabs--modeDarkUnderlined .Tabs__button.active::after, .Tabs--modeSearchDetails .Tabs__button.active::after {
  transform: scaleX(1);
}
.Tabs--underlinedTabs .Tabs__item.uk-active .Tabs__button, .Tabs--modeGrayUnderlined .Tabs__item.uk-active .Tabs__button, .Tabs--modeLegalGrayUnderlined .Tabs__item.uk-active .Tabs__button, .Tabs--modeDarkUnderlined .Tabs__item.uk-active .Tabs__button, .Tabs--modeSearchDetails .Tabs__item.uk-active .Tabs__button {
  color: var(--tab-active-color);
}
.Tabs--underlinedTabs .Tabs__item.uk-active .Tabs__button::after, .Tabs--modeGrayUnderlined .Tabs__item.uk-active .Tabs__button::after, .Tabs--modeLegalGrayUnderlined .Tabs__item.uk-active .Tabs__button::after, .Tabs--modeDarkUnderlined .Tabs__item.uk-active .Tabs__button::after, .Tabs--modeSearchDetails .Tabs__item.uk-active .Tabs__button::after {
  transform: scaleX(1);
}
.Tabs--modeDarkUnderlined, .Tabs--modeSearchDetails {
  --tab-default-color: rgba(255, 255, 255, 0.5);
  --tab-default-border: rgba(255, 255, 255, 0.2);
  --tab-hover-color: white;
  --tab-active-color: #e39e00;
  --tab-active-border: #e39e00;
}
.Tabs--modeDarkUnderlined .Tabs__mobileDropdown, .Tabs--modeSearchDetails .Tabs__mobileDropdown {
  --form-default-text-color: #fccf17;
  --form-default-label-color: #fccf17;
  --form-default-background: #131818;
  --form-default-border: #fccf17;
  --form-default-option-color: white;
  --form-default-option-background: #131818;
  --form-default-dropdown-arrow-source: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%228%22%20viewBox%3D%220%200%2024%208%22%20fill%3D%22none%22%20stroke%3D%22%23FCCF17%22%20stroke-width%3D%222%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1%201L6%206L11%201%22%2F%3E%0A%3C%2Fsvg%3E");
  --form-hover-text-color: #fccf17;
  --form-hover-label-color: #fccf17;
  --form-hover-background: #1f2222;
  --form-hover-border: #fccf17;
  --form-hover-option-color: white;
  --form-hover-option-background: #1f2222;
  --form-active-text-color: #fccf17;
  --form-active-label-color: #fccf17;
  --form-active-background: #1f2222;
  --form-active-border: #fccf17;
  --form-active-option-color: #fccf17;
  --form-active-option-background: #131818;
  --form-disabled-text-color: #656868;
  --form-disabled-label-color: #656868;
  --form-disabled-background: #131818;
  --form-disabled-border: #656868;
}
.Tabs--modeGrayUnderlined, .Tabs--modeLegalGrayUnderlined {
  --tab-default-color: rgba(101, 104, 104, 0.5);
  --tab-default-border: rgba(43, 47, 47, 0.2);
  --tab-hover-color: #131818;
  --tab-active-color: #131818;
  --tab-active-border: #131818;
}
.Tabs--modeGrayUnderlined .Tabs__mobileDropdown, .Tabs--modeLegalGrayUnderlined .Tabs__mobileDropdown {
  color: #2b2f2f;
  background-color: rgba(101, 104, 104, 0.1);
  border: 1px rgba(101, 104, 104, 0.2) solid;
}
@media (max-width: 799px) {
  .Tabs--modeLegalGrayUnderlined .Tabs__itemsList {
    display: none;
  }
}
@media (max-width: 799px) {
  :root .Tabs--modeLegalGrayUnderlined .Tabs__mobileDropdown:not([multiple]):not([size]) {
    display: block;
  }
}
@media (max-width: 799px) and (min-width: 640px) {
  :root .Tabs--modeLegalGrayUnderlined .Tabs__mobileDropdown:not([multiple]):not([size]) {
    max-width: 450px;
  }
}
.Tabs--modeSearchDetails {
  display: flex;
  justify-content: center;
}
.Tabs--modeSearchDetails .Tabs__itemsList {
  max-width: 645px;
}
@media (max-width: 499px) {
  .Tabs--modeSearchDetails .Tabs__mobileDropdown {
    max-width: 350px;
  }
}
.Tabs--fitContentItems .Tabs__itemsList {
  width: fit-content;
}
.Tabs--fitContentItems .Tabs__item:not(:last-child) {
  padding-right: 48px;
}
@media (max-width: 999px) {
  .Tabs--fitContentItems .Tabs__item:not(:last-child) {
    padding-right: 40px;
  }
}
@media (max-width: 899px) {
  .Tabs--fitContentItems .Tabs__item:not(:last-child) {
    padding-right: 32px;
  }
}
.Tabs--columnItems {
  margin-bottom: 24px;
}
@media (max-width: 899px) {
  .Tabs--columnItems {
    margin-bottom: 16px;
  }
}
.Tabs--columnItems .Tabs__itemsList {
  flex-direction: column;
}
.Tabs--columnItems .Tabs__item:not(:last-child) {
  padding-bottom: 8px;
}
.Tabs--modeCatalog .Tabs__itemsList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
}
@media (max-width: 999px) {
  .Tabs--modeCatalog .Tabs__itemsList {
    grid-template-columns: 1fr;
  }
}
.Tabs--modeCatalog .Tabs__item {
  margin: 0;
}
@media (max-width: 639px) {
  .Tabs--renderMobileDropdown .Tabs__itemsList {
    display: none;
  }
}
@media (min-width: 640px) {
  .Tabs--renderMobileDropdown .Tabs__mobileDropdown:not([multiple]):not([size]) {
    display: none;
  }
}
.Tabs__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-style: solid;
  appearance: none;
  height: 100%;
  outline: none;
  cursor: pointer;
  transition-property: color, border-color, background-color, box-shadow;
  transition: 0.25s ease-in-out;
}
.Tabs__button.active {
  pointer-events: none;
}
.Tabs__itemsList {
  display: flex;
}
.Tabs__mobileDropdown {
  transition: none;
}
.uk-sticky.uk-active .Tabs__mobileDropdown:not([multiple]):not([size]) {
  --form-default-dropdown-arrow-source: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%228%22%20viewBox%3D%220%200%2024%208%22%20fill%3D%22none%22%20stroke%3D%22%23E39E00%22%20stroke-width%3D%222%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1%201L6%206L11%201%22%2F%3E%0A%3C%2Fsvg%3E");
  height: unset;
  padding: 0 32px 0 0;
  border: none;
  color: #e39e00;
}

.AddToCart {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 48px;
}
.AddToCart--cart {
  height: 32px;
}
.AddToCart--catalog {
  min-width: 162px;
}
@media (max-width: 369px) {
  .AddToCart--catalog {
    min-width: unset;
  }
}
@media (max-width: 369px) {
  .AddToCart--catalog .AddToCart__button {
    min-width: unset;
  }
}
.AddToCart__button {
  width: 100%;
  z-index: 1;
}
.AddToCart__counter {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ProductStatus {
  font-size: inherit;
  font-weight: 500;
  color: #656868;
}
.ProductStatus--available {
  color: #28b378;
}
.ProductStatus--onOrder {
  color: #e39e00;
}
.ProductStatus--unavailable {
  color: #f22814;
}

.Counter {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
@media (max-width: 639px) {
  .Counter--cart .Counter__button {
    background-color: rgba(101, 104, 104, 0.05);
  }
}
.Counter--legalCart .Counter__inputWrapper {
  margin-x: 0;
}
.Counter--slider {
  padding: 0 16px;
  justify-content: space-between;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 4px 20px rgba(19, 24, 24, 0.05);
}
.Counter--slider .Counter__inputWrapper {
  max-width: 54px;
}
.Counter__button {
  size: 32px;
  min-width: unset;
  min-height: unset;
  background-color: white;
  border-radius: 2px;
  color: #e39e00;
}
.Counter__button:hover, .Counter__button:focus {
  background-color: rgba(101, 104, 104, 0.05);
  color: #e39e00;
}
.Counter__button:active {
  background-color: rgba(101, 104, 104, 0.08);
}
.Counter__inputWrapper {
  flex: 1;
  height: 32px;
  margin-x: 8px;
}
.Counter__input {
  height: 100%;
  padding: 0;
  background: none;
  border: 1px rgba(101, 104, 104, 0.3) solid;
  border-radius: 2px;
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
  color: #2b2f2f;
}
.Counter__input--unavailable {
  color: #f22814;
}
.Counter__input:hover, .Counter__input:focus, .Counter__input:active {
  outline: none;
  border-color: #656868;
  color: #131818;
}

.LoginForm__back {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 0;
  border: none;
  min-width: 32px;
  min-height: 32px;
  size: 32px;
}
@media (max-width: 424px) {
  .LoginForm__back {
    top: 12px;
    left: 12px;
  }
}
.LoginForm__backIcon {
  size: 24px;
}
.LoginForm__submitCodeAgainText, .LoginForm__submitCodeAgain {
  margin-top: 16px;
}
.LoginForm__highlight {
  color: #e39e00;
}
.LoginForm__text {
  margin-bottom: 24px;
}
@media (max-width: 899px) {
  .LoginForm__text {
    margin-bottom: 16px;
  }
}
.LoginForm__content {
  margin-top: 32px;
}
.LoginForm__content:first-child {
  margin-top: 0;
}
.LoginForm__socialsBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
  text-align: center;
}
.LoginForm__socialsText {
  margin-bottom: 16px;
  font-size: 1.125rem;
  font-weight: 500;
  color: rgba(101, 104, 104, 0.5);
}
.LoginForm__socialsList {
  display: flex;
  justify-content: center;
}
.LoginForm__socialsItem:not(:last-child) {
  margin-right: 20px;
}
.LoginForm__socialsItemLink {
  size: 40px;
  background-color: #2b2f2f;
  border-radius: 50%;
  color: white;
  transition-property: background-color, color;
  transition: 0.25s ease-in-out;
}
.LoginForm__socialsItemLink:hover, .LoginForm__socialsItemLink:focus, .LoginForm__socialsItemLink:active {
  background-color: #464a4a;
  color: white;
}

.ResetPasswordForm__back {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 0;
  border: none;
  min-width: 32px;
  min-height: 32px;
  size: 32px;
}
@media (max-width: 424px) {
  .ResetPasswordForm__back {
    top: 12px;
    left: 12px;
  }
}
.ResetPasswordForm__backIcon {
  size: 24px;
}
.ResetPasswordForm__textBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  text-align: center;
}
.ResetPasswordForm__successText {
  font-size: 1.125rem;
  font-weight: 500;
  color: #28b378;
}

.MiniCart {
  width: 552px;
}
@media (max-width: 999px) {
  .MiniCart {
    width: auto;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
.MiniCart--empty {
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}
.MiniCart--empty .MiniCart__listWrapper {
  display: none;
}
.MiniCart__listWrapper {
  flex: 1;
  padding-right: 8px;
  border-bottom: 1px rgba(101, 104, 104, 0.2) solid;
}
.MiniCart__list {
  max-height: 438px;
  overflow-y: auto;
  padding: 22px 12px 18px 24px;
}
@media (max-width: 999px) {
  .MiniCart__list {
    max-height: none;
    overflow-y: visible;
    padding: 0;
    padding-bottom: 24px;
  }
}
@media (max-width: 639px) {
  .MiniCart__list {
    padding-bottom: 16px;
  }
}
.MiniCart__item:not(:last-child) {
  margin-bottom: 16px;
}
.MiniCart__footer {
  padding: 16px 24px 24px;
}
@media (max-width: 999px) {
  .MiniCart__footer {
    padding: 0;
    padding-top: 24px;
  }
}
@media (max-width: 639px) {
  .MiniCart__footer {
    padding-top: 16px;
  }
}
.MiniCart__total {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-weight: 700;
  color: #2b2f2f;
}
.MiniCart__total:not(:first-child) {
  margin-top: 6px;
}
.MiniCart__total--weight, .MiniCart__total--delivery {
  font-weight: 400;
}
.MiniCart__total--weight .MiniCart__totalValue, .MiniCart__total--delivery .MiniCart__totalValue {
  font-weight: 500;
  font-size: 1.125rem;
}
.MiniCart__totalLabel {
  font-size: 1.125rem;
}
.MiniCart__totalValue {
  font-size: 1.375rem;
}
.MiniCart__buttonWrapper {
  margin-top: 18px;
}
.MiniCart__button {
  width: 100%;
}

.CartModal {
  padding: 0 0 24px 0;
  overflow-y: hidden;
  background: none;
}
@media (max-width: 639px) {
  .CartModal {
    padding: 0;
  }
}
:root .CartModal__body {
  --header-height: 86px;
  height: calc(100% - var(--header-height));
  margin-top: var(--header-height) !important;
  margin-right: 32px;
  box-shadow: 0 2px 30px rgba(19, 24, 24, 0.1);
}
@media (max-width: 639px) {
  :root .CartModal__body {
    --header-height: 70px;
    padding: 16px;
    margin-x: 0;
  }
}
:root .CartModal__body::after {
  content: "";
  position: absolute;
  top: -20px;
  right: 19px;
  size: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 25px solid white;
}

.MiniCartItem {
  position: relative;
  display: grid;
  grid-template-columns: 56px 1fr;
  grid-column-gap: 16px;
}
@media (max-width: 639px) {
  .MiniCartItem {
    grid-template-columns: 40px 1fr;
  }
}
.MiniCartItem--removing {
  display: none;
}
.MiniCartItem:not(.MiniCartItem--lastChild) .MiniCartItem__footer {
  padding-bottom: 18px;
  border-bottom: 1px rgba(101, 104, 104, 0.5) dashed;
}
.MiniCartItem__stocks {
  font-size: 14px;
  text-align: left;
}
@media (max-width: 639px) {
  .MiniCartItem__stocks {
    font-size: 0.75rem;
  }
}
.MiniCartItem__picture {
  display: block;
  position: relative;
  padding-bottom: 100%;
  background-color: transparent;
  overflow: hidden;
  border-radius: 2px;
}
.MiniCartItem__picture--withPlaceholder {
  background-color: #dcdcdc;
}
.MiniCartItem__placeholder {
  width: 32px;
}
.MiniCartItem__nameWrapper {
  padding-right: 40px;
  text-align: left;
}
.MiniCartItem__nameLink {
  color: #2b2f2f;
}
.MiniCartItem__nameLink:hover, .MiniCartItem__nameLink:focus, .MiniCartItem__nameLink:active {
  color: #e39e00;
}
.MiniCartItem__nameSpan {
  color: #2b2f2f;
}
.MiniCartItem__name {
  font-size: 1.125rem;
  color: inherit;
}
@media (max-width: 639px) {
  .MiniCartItem__name {
    text-align: left;
    font-size: 0.875rem;
  }
}
.MiniCartItem__footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "price price" "about counter";
  grid-gap: 4px;
  margin-top: 24px;
}
@media (max-width: 639px) {
  .MiniCartItem__footer {
    margin-top: 8px;
  }
}
@media (max-width: 369px) {
  .MiniCartItem__footer {
    grid-template-columns: 1fr;
    grid-template-areas: "price" "about" "counter";
  }
}
.MiniCartItem__priceBlock {
  grid-area: price;
}
.MiniCartItem__priceValues {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.MiniCartItem__price--total {
  font-size: 1.375rem;
  font-weight: 700;
  color: #2b2f2f;
  white-space: nowrap;
  margin-right: 8px;
}
@media (max-width: 639px) {
  .MiniCartItem__price--total {
    font-size: 0.875rem;
  }
}
.MiniCartItem__price--perUnit {
  color: #656868;
}
@media (max-width: 639px) {
  .MiniCartItem__price--perUnit {
    font-size: 0.75rem;
  }
}
.MiniCartItem__aboutBlock {
  grid-area: about;
  display: flex;
  flex-direction: column;
}
.MiniCartItem__packageUnitsQuantity {
  font-size: 0.875rem;
  text-align: left;
}
@media (max-width: 639px) {
  .MiniCartItem__packageUnitsQuantity {
    font-size: 0.75rem;
  }
}
.MiniCartItem__counter {
  width: 134px;
  height: 32px;
  grid-area: counter;
  justify-self: end;
}
@media (max-width: 369px) {
  .MiniCartItem__counter {
    justify-self: start;
  }
}
.MiniCartItem__removeButton {
  top: -8px;
  right: -8px;
}

.ErrorPage__title {
  margin-bottom: 24px;
}
@media (max-width: 999px) {
  .ErrorPage__title {
    margin-bottom: 20px;
  }
}
@media (max-width: 639px) {
  .ErrorPage__title {
    margin-bottom: 16px;
  }
}
.ErrorPage__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ErrorPage__code {
  font-size: 11.25rem;
  font-weight: 600;
  line-height: 1;
  color: #dcdcdc;
}
@media (max-width: 899px) {
  .ErrorPage__code {
    font-size: 10rem;
  }
}
@media (max-width: 639px) {
  .ErrorPage__code {
    font-size: 8.125rem;
  }
}
@media (max-width: 424px) {
  .ErrorPage__code {
    font-size: 6.25rem;
  }
}