.ButtonsGroup {
    $block: &;

    display: flex;

    @include vw-xsmall-down {
        flex-direction: column;

        #{$block}__buttonWrapper {
            width: 100%;
        }
    }

    &--fullWidth {
        #{$block}__buttonWrapper {
            width: 100%;

            & > * {
                width: 100%;
            }
        }
    }

    &--column {
        flex-direction: column;

        #{$block}__buttonWrapper:not(:last-child) {
            margin-bottom: 24px;

            @include vw-medium-down {
                margin-bottom: 20px;
            }
        }
    }

    &:not(&--column) {
        #{$block}__buttonWrapper:not(:last-child) {
            @include vw-xsmall-up {
                margin-right: 24px;

                @include vw-medium-down {
                    margin-right: 20px;
                }
            }

            @include vw-xsmall-down {
                margin-bottom: 20px;
            }
        }
    }

    &--rowOnMobile {
        @include vw-xsmall-down {
            flex-direction: row;
        }

        #{$block}__buttonWrapper:not(:last-child) {
            @include vw-xsmall-down {
                margin-bottom: 0;
                margin-right: 16px;
            }
        }
    }

    :root &--links {
        @include vw-xsmall-down {
            #{$block}__buttonWrapper {
                width: fit-content;
            }
        }

        #{$block}__buttonWrapper:not(:last-child) {
            @include vw-xsmall-down {
                margin-bottom: 8px;
            }
        }
    }

    &--justifyBetween {
        justify-content: space-between;
    }

    &--center {
        justify-content: center;

        &#{$block}--column {
            align-items: center;
        }
    }

    &__buttonWrapper {
        @include vw-xsmall-down {
            flex: 1;

            & > * {
                width: 100%;
            }
        }
    }
}
