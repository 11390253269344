.CityPopup {
    $block: &;

    position: absolute;
    top: calc(100% + 6px);
    left: 50%;
    padding-top: 16px;
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: $z-index-city-popup - 1;

    transition-property: opacity, transform;
    transition: 0.25s ease-in-out;

    @include vw-large-down {
        position: fixed;
        top: 50%;
        transform: translate(-50%, -100%);
    }

    .uk-sticky.uk-active & {
        @include vw-large-up {
            margin-left: 48px;
        }

        &::before {
            @include vw-large-up {
                left: calc(50% - 48px);
            }
        }
    }

    &--mobile {
        @include vw-large-up {
            display: none;
        }
    }

    &--desktop {
        @include vw-large-down {
            display: none;
        }
    }

    &--shown {
        opacity: 1;
        transform: translate(-50%, 0);
        pointer-events: auto;

        @include vw-large-down {
            transform: translate(-50%, -50%);
        }

        #{$block}__body {
            box-shadow: 0 20px 40px rgba($color-black, 0.1);
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        size: 28px;
        background-color: $color-gray--darkest;
        border-radius: 4px;
        transform: rotate(45deg);
        transform-origin: top left;

        @include vw-large-down {
            display: none;
        }
    }

    &__body {
        padding: 24px 32px 32px;
        background-color: $color-gray--darkest;

        transition: box-shadow 0.25s ease-in-out;

        @include vw-small-down {
            padding: 16px 24px 24px;
        }
    }

    &__heading {
        margin-bottom: 24px;
        font-size: toRem(22px);
        font-weight: $font-weight-medium;
        line-height: 1.28;
        text-align: center;
        color: white;

        @include vw-small-down {
            margin-bottom: 16px;
        }
    }

    &__button {
        min-width: 112px;
    }
}

.CityPopupOverlay {
    @extend %overlay;

    .cityPopupShown & {
        @include vw-large-down {
            opacity: 1;
            z-index: $z-index-city-popup - 2;
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
}
