@import "schemas";

.Tabs {
    $block: &;

    @mixin button-state($state) {
        color: var(--tab-#{$state}-color);
        border-color: var(--tab-#{$state}-border);
        background-color: var(--tab-#{$state}-background);
        box-shadow: var(--tab-#{$state}-shadow);
    }

    &--buttonTabs {
        #{$block}__button {
            @include button-state("default");

            min-height: 48px;
            padding: 8px 16px;
            vertical-align: middle;
            font-size: toRem(18px);
            font-weight: $font-weight-medium;
            border-radius: 2px;

            &:hover,
            &:focus {
                @include button-state("hover");
            }

            &:active,
            &.active {
                @include button-state("active");
            }
        }

        #{$block}__item.uk-active #{$block}__button {
            @include button-state("active");
        }
    }

    &--modeGray {
        @mixin active-state() {
            &::before {
                display: none;
            }
        }

        #{$block}__itemsList {
            @extend #{$block}--buttonTabs;

            background-color: rgba($color-gray, 0.05);
        }

        #{$block}__item {
            position: relative;
            flex-grow: 1;

            &:first-child #{$block}__button::before {
                display: none;
            }
        }

        #{$block}__button {
            @include custom-schema("--tab", $tabs-button-gray-schema);

            width: 100%;

            &.active,
            &:active {
                @include active-state;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: -1px;
                width: 1px;
                height: 32px;
                background-color: rgba($color-gray, 0.2);
                transform: translateY(-50%);
            }
        }

        #{$block}__item.uk-active #{$block}__button {
            @include active-state;
        }

        #{$block}__mobileDropdown {
            color: $color-gray--darkest;
            background-color: rgba($color-gray, 0.1);
            border: 1px rgba($color-gray, 0.2) solid;
        }
    }

    &--modeWhiteShadow {
        $margin: 8px;

        #{$block}__itemsList {
            @extend #{$block}--buttonTabs;

            margin-x: -$margin / 2;
        }

        #{$block}__item {
            margin-x: $margin / 2;
            flex-grow: 1;
            text-align: center;
        }

        #{$block}__button {
            @include custom-schema("--tab", $tabs-button-white-shadow-schema);

            border-width: 3px;
            width: 100%;
        }

        #{$block}__mobileDropdown {
            @include custom-schema("--form", $form-default-schema);

            border: 3px $color-yellow solid;
        }
    }

    &--underlinedTabs {
        @mixin active-state() {
            color: var(--tab-active-color);

            &::after {
                transform: scaleX(1);
            }
        }

        #{$block}__itemsList {
            justify-content: space-between;
            position: relative;
            width: 100%;

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--tab-default-border);
            }
        }

        #{$block}__button {
            color: var(--tab-default-color);
            position: relative;
            padding: 0 0 8px;
            line-height: 1;
            font-size: toRem(18px);
            font-weight: $font-weight-medium;
            background: none;

            &:hover,
            &:focus,
            &:active {
                color: var(--tab-hover-color);
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: var(--tab-active-border);
                transform: scaleX(0);
                will-change: transform;
                transition: transform 0.25s ease-in-out;
            }

            &.active {
                @include active-state;
            }
        }

        #{$block}__item.uk-active #{$block}__button {
            @include active-state;
        }
    }

    &--modeDarkUnderlined {
        @include custom-schema("--tab", $tabs-underlined-dark-schema);

        @extend #{$block}--underlinedTabs;

        #{$block}__mobileDropdown {
            @include custom-schema("--form", $form-black-schema);
        }
    }

    &--modeGrayUnderlined {
        @include custom-schema("--tab", $tabs-underlined-gray-schema);

        @extend #{$block}--underlinedTabs;

        #{$block}__mobileDropdown {
            color: $color-gray--darkest;
            background-color: rgba($color-gray, 0.1);
            border: 1px rgba($color-gray, 0.2) solid;
        }
    }

    &--modeLegalGrayUnderlined {
        @extend #{$block}--modeGrayUnderlined;

        #{$block}__itemsList {
            @include vw-legal-account-navigation-small-down {
                display: none;
            }
        }

        #{$block}__mobileDropdown {
            @include vw-legal-account-navigation-small-down {
                :root &:not([multiple]):not([size]) {
                    display: block;

                    @include vw-small-up {
                        max-width: 450px;
                    }
                }
            }
        }
    }

    &--modeSearchDetails {
        @extend #{$block}--modeDarkUnderlined;

        display: flex;
        justify-content: center;

        #{$block}__itemsList {
            max-width: 645px;
        }

        #{$block}__mobileDropdown {
            @include vw-search-details-xsmall-down {
                max-width: 350px;
            }
        }
    }

    &--fitContentItems {
        #{$block}__itemsList {
            width: fit-content;
        }

        #{$block}__item:not(:last-child) {
            padding-right: 48px;

            @include vw-large-down {
                padding-right: 40px;
            }

            @include vw-medium-down {
                padding-right: 32px;
            }
        }
    }

    &--columnItems {
        margin-bottom: 24px;

        @include vw-medium-down {
            margin-bottom: 16px;
        }

        #{$block}__itemsList {
            flex-direction: column;
        }

        #{$block}__item:not(:last-child) {
            padding-bottom: 8px;
        }
    }

    &--modeCatalog {
        @extend #{$block}--modeWhiteShadow;

        #{$block}__itemsList {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 8px;

            @include vw-large-down {
                grid-template-columns: 1fr;
            }
        }

        #{$block}__item {
            margin: 0;
        }
    }

    &--renderMobileDropdown {
        #{$block}__itemsList {
            @include vw-small-down {
                display: none;
            }
        }

        #{$block}__mobileDropdown {
            @include vw-small-up {
                &:not([multiple]):not([size]) {
                    display: none;
                }
            }
        }
    }

    &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-width: 0;
        border-style: solid;
        appearance: none;
        height: 100%;
        outline: none;
        cursor: pointer;

        transition-property: color, border-color, background-color, box-shadow;
        transition: 0.25s ease-in-out;

        &.active {
            pointer-events: none;
        }
    }

    &__itemsList {
        display: flex;
    }

    &__mobileDropdown {
        @extend .uk-select;

        transition: none;

        .uk-sticky.uk-active &:not([multiple]):not([size]) {
            --form-default-dropdown-arrow-source: #{getColoredArrow($color-orange)};

            height: unset;
            padding: 0 32px 0 0;
            border: none;
            color: $color-orange;
        }
    }
}
