.CartModal {
    padding: 0 0 24px 0;
    overflow-y: hidden;
    background: none;

    @include vw-small-down {
        padding: 0;
    }

    :root &__body {
        --header-height: 86px;

        height: calc(100% - var(--header-height));
        margin-top: var(--header-height)!important;
        margin-right: 32px;
        box-shadow: 0 2px 30px rgba($color-black, 0.1);

        @include vw-small-down {
            --header-height: 70px;

            padding: 16px;
            margin-x: 0;
        }

        &::after {
            content: "";
            position: absolute;
            top: -20px;
            right: 19px;
            size: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 25px solid white;
        }
    }
}
