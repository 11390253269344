.CartWidget {
    $block: &;

    position: relative;

    &:hover,
    &:focus,
    &:active {
        #{$block}__icon {
            background-color: rgba($color-gray, 0.1);
        }

        #{$block}__contentTitle {
            color: $color-yellow--dark;
        }

        #{$block}__miniCartWrapper {
            transform: scaleY(1);
            box-shadow: 0 2px 30px rgba($color-black, 0.1);
            transition-delay: 0s;
        }

        #{$block}__miniCart {
            opacity: 1;
            transition-delay: 0.15s;
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        line-height: 23px;

        @include vw-large-down {
            display: none;
        }

        &--mobileToggle{
            display: none;

            @include vw-large-down {
                display: block;
            }
        }
    }

    &__icon {
        @extend %display-flex-center;

        size: 48px;
        border-radius: 2px;
        box-sizing: content-box;
        color: $color-gray--darkest;
        background-color: rgba($color-gray, 0.05);
        transition: background-color 0.25s ease-in-out;
        margin-right: 16px;

        @include vw-large-down {
            margin-right: 0;
        }

        @include vw-header-small-down {
            size: 40px;
        }

        svg {
            size: 20px;
        }
    }

    &__cartAmount {
        @extend %display-flex-center;

        position: absolute;
        top: -9px;
        left: 31px;
        size: 24px;
        color: white;
        border-radius: 50%;
        background: $color-yellow--dark;
        font-size: toRem(14px);
        font-weight: $font-weight-semi-bold;
        line-height: 1;

        @include vw-header-small-down {
            top: -6px;
            size: 22px;
        }

        &--small {
            font-size: toRem(14px);
        }

        &--xSmall {
            font-size: toRem(12px);
        }
    }

    &__content {
        @include vw-large-down {
            display: none;
        }
    }

    &__contentTitle {
        font-weight: $font-weight-semi-bold;
        color: $color-gray--darkest;
        font-size: toRem(18px);
        transition: color 0.25s ease-in-out;
    }

    &__totalPrice {
        display: block;
        margin-top: 4px;
        color: $color-yellow--dark;
        font-weight: $font-weight-medium;
        font-size: toRem(18px);
        line-height: 1;
    }

    &__miniCartWrapper {
        position: absolute;
        top: 100%;
        right: 100%;
        margin-right: -133px;
        background-color: white;
        min-width: 250px;
        margin-top: 8px;
        border-radius: 2px;
        box-shadow: none;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.15s ease-in-out, box-shadow 0.25s ease-in-out;
        transition-delay: 0.15s;
        z-index: $z-index-mini-cart;

        @include vw-large-down {
            display: none;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
        }

        &::before {
            height: 8px;
            width: 100%;
            top: -8px;
        }

        &::after {
            top: -20px;
            right: 93px;
            size: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 25px solid white;
        }
    }

    &__miniCart {
        opacity: 0;
        transition-delay: 0.15s;
        transition: opacity 0.15s ease-in-out;
    }
}
