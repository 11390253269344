.LoginForm {
    &__back {
        $size: 32px;

        position: absolute;
        top: $modal-close-position-xs;
        left: $modal-close-position-xs;
        padding: 0;
        border: none;
        min-width: $size;
        min-height: $size;
        size: $size;

        @include vw-xsmall-down {
            top: $modal-close-position;
            left: $modal-close-position;
        }
    }

    &__backIcon {
        size: 24px;
    }

    &__submitCodeAgainText,
    &__submitCodeAgain {
        margin-top: 16px;
    }

    &__highlight {
        color: $color-orange;
    }

    &__text {
        margin-bottom: 24px;

        @include vw-medium-down {
            margin-bottom: 16px;
        }
    }

    &__content {
        margin-top: 32px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__socialsBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 32px 0;
        text-align: center;
    }

    &__socialsText {
        margin-bottom: 16px;
        font-size: toRem(18px);
        font-weight: $font-weight-medium;
        color: rgba($color-gray, 0.5);
    }

    &__socialsList {
        display: flex;
        justify-content: center;
    }

    &__socialsItem {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__socialsItemLink {
        @extend %display-flex-center;

        size: 40px;
        background-color: $color-gray--darkest;
        border-radius: 50%;
        color: white;

        transition-property: background-color, color;
        transition: 0.25s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            background-color: $color-gray--dark;
            color: white;
        }
    }
}
