.HeaderSearch {
    $block: &;

    display: flex;
    width: 100%;

    @include vw-header-small-down {
        flex-direction: column;
    }

    &__inputControls {
        flex-grow: 1;
        display: flex;
        box-shadow: 0 8px 30px -10px rgba($color-black, 0.3);
        margin-right: 8px;

        @include vw-header-small-down {
            box-shadow: none;
            margin-right: 0;
        }
    }

    &__searchWrapper {
        display: flex;
        width: 100%;
    }

    &__control {
        @extend .uk-input;

        @include vw-header-small-down {
            --form-default-border: #{rgba($color-gray, 0.3)};
            --form-hover-border: #{rgba($color-gray, 0.3)};
            --form-active-border: #{rgba($color-gray, 0.3)};

            border-right-width: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        @include vw-xsmall-down {
            font-size: toRem(16px);
        }
    }

    &__desktopDropdown {
        flex-shrink: 0;
        width: 217px;
        background-color: white;
        border-left: 1px rgba($color-gray, 0.2) solid;

        @include vw-header-small-down {
            display: none;
        }
    }

    &__mobileDropdown:not([multiple]):not([size]) {
        @extend .uk-select;

        @include custom-schema(
            "--form",
            (
                default: (
                    color: $color-gray,
                    background: rgba($color-gray, 0.1),
                ),
                hover: (
                    color: $color-gray,
                    background: rgba($color-gray, 0.2),
                ),
                active: (
                    color: $color-gray,
                    background: rgba($color-gray, 0.2),
                ),
            )
        );

        padding: 8px 20px 8px 16px;
        border: none;
        height: auto;
        margin-top: 16px;
        font-size: toRem(14px);
        font-weight: $font-weight-medium;
        cursor: pointer;

        @include vw-header-small-up {
            display: none;
        }
    }

    &__button {
        @include vw-large-down {
            min-width: 48px;
            padding: 0;
        }

        @include vw-header-small-down {
            border-color: rgba($color-gray, 0.3);
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        @include vw-xsmall-down {
            min-width: 44px;
        }

        @include vw-xxsmall-down {
            width: auto;
        }

        .uk-sticky.uk-active & {
            min-width: 48px;
            padding: 0;
        }
    }

    &__buttonIcon {
        display: none;
        width: 17px;

        @include vw-large-down {
            display: block;
        }

        .uk-sticky.uk-active & {
            display: block;
        }
    }

    &__buttonText {
        @include vw-large-down {
            display: none;
        }

        .uk-sticky.uk-active & {
            display: none;
        }
    }
}
