.SiteNav {
    $block: &;

    display: flex;
    flex-wrap: wrap;

    &--modeHeader {
        #{$block}__listItem {
            margin: 0 32px 0 0;

            @include vw-xlarge-down {
                margin: 0 20px 0 0;
            }
        }

        #{$block}__item {
            color: white;
            font-weight: $font-weight-semi-bold;

            @include vw-xlarge-down {
                font-size: toRem(14px);
            }

            &:hover,
            &:focus,
            &:active,
            &--active {
                color: $color-yellow;
            }
        }
    }

    &--modeFooterPrimary,
    &--modeFooterSecondary {
        flex-direction: column;

        #{$block}__listItem {
            margin: 0 0 16px;

            @include vw-small-down {
                margin-bottom: 12px;
            }
        }
    }

    &--modeFooterPrimary {
        #{$block}__item {
            color: white;
            font-weight: $font-weight-semi-bold;

            &:hover,
            &:focus,
            &:active,
            &--active {
                color: $color-yellow;
            }
        }
    }

    &--modeFooterSecondary {
        #{$block}__item {
            color: rgba(white, 0.7);

            &:hover,
            &:focus,
            &:active {
                color: white;
            }

            &--active {
                color: $color-yellow;
            }
        }
    }

    &--modeMobile {
        flex-direction: column;

        #{$block}__listItem {
            margin: 0 0 12px;
        }

        #{$block}__item {
            font-size: toRem(20px);
            font-weight: $font-weight-semi-bold;
            color: $color-black;

            &:hover,
            &:focus,
            &:active {
                color: $color-orange;
            }
        }
    }

    &__listItem {
        margin: 0 16px 0 0;

        &:last-child {
            margin: 0;
        }
    }
}
