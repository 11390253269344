.SiteFooter {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba($color-black, 0.9);
        backdrop-filter: blur(30px);
        display: block;
    }

    &__containerMain {
        display: grid;
        grid-template-areas:
            "logo menuPrimary menuSecondary formButton"
            "logo menuPrimary menuSecondary feedbackButton"
            "contacts menuPrimary menuSecondary .";
        grid-template-columns: 1fr auto 1fr auto;
        column-gap: 56px;
        padding-y: 56px;

        @include vw-xlarge-down {
            grid-template-areas:
                "logo menuPrimary menuSecondary"
                "contacts menuPrimary menuSecondary"
                "contacts formButton feedbackButton";
            grid-template-columns: auto auto 1fr;
        }

        @include vw-large-down {
            grid-template-areas:
                "logo menuPrimary formButton"
                "logo menuPrimary feedbackButton"
                "contacts menuPrimary feedbackButton"
                "contacts menuSecondary .";
            grid-template-columns: auto max-content minmax(200px, 256px);
            grid-template-rows: min-content min-content 1fr auto;
        }

        @include vw-medium-down {
            padding-y: 37px;
            grid-template-areas:
                "logo logo"
                "menuPrimary menuSecondary"
                "contacts formButton"
                "contacts feedbackButton";
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto min-content min-content;
            grid-row-gap: 24px;
        }

        @include vw-small-down {
            padding-y: 28px;
            grid-template-areas:
                "logo"
                "formButton"
                "feedbackButton"
                "menuPrimary"
                "menuSecondary"
                "contacts";
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
    }

    &__logo {
        grid-area: logo;

        @include vw-medium-down {
            margin-bottom: 16px;
        }
    }

    &__logoLink {
        display: block;
        color: $color-gray;
        max-width: 162px;

        &:hover,
        &:focus,
        &:active {
            color: $color-yellow;
        }

        svg {
            width: 100%;
        }
    }

    &__menuPrimary {
        grid-area: menuPrimary;

        @include vw-medium-down {
            margin-bottom: 16px;
        }

        @include vw-small-down {
            margin-top: 8px;
            margin-bottom: 0;
        }
    }

    &__menuSecondary {
        grid-area: menuSecondary;

        @include vw-large-down {
            margin-top: 32px;
        }

        @include vw-medium-down {
            margin-top: 0;
            margin-bottom: 16px;
        }

        @include vw-small-down {
            margin-bottom: 0;
        }
    }

    &__formButton {
        grid-area: formButton;

        @include vw-xlarge-down {
            margin-top: 32px;
        }

        @include vw-large-down {
            margin-top: 0;
        }
    }

    &__formButtonSecond {
        margin-top: 24px;
    }

    &__feedbackButton {
        grid-area: feedbackButton;
        margin-top: 24px;

        @include vw-xlarge-down {
            margin-top: 32px;
        }

        @include vw-large-down {
            margin-top: 24px;
        }

        @include vw-medium-down {
            margin-top: 0;
        }
    }

    &__contacts {
        grid-area: contacts;
        display: flex;
        flex-direction: column;
        max-width: 279px;
        margin-top: 12px;

        @include vw-xlarge-down {
            justify-content: flex-end;
            margin-top: 0;
            padding-bottom: 10px;
        }

        @include vw-large-down {
            justify-content: flex-start;
            margin-top: 32px;
            padding-bottom: 0;
        }

        @include vw-medium-down {
            margin-top: 0;
        }

        @include vw-small-down {
            margin-top: 8px;
        }
    }

    &__phone {
        color: white;
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: toRem(22px);
        font-weight: $font-weight-semi-bold;

        &:hover,
        &:focus,
        &:active {
            color: $color-yellow;
        }
    }

    &__phoneIcon {
        @extend %display-flex-center;

        flex-shrink: 0;
        margin-right: 12px;
        margin-top: -1px;
    }

    &__email {
        color: white;
        display: flex;
        width: fit-content;
        margin-top: 16px;

        &:hover,
        &:focus,
        &:active {
            color: $color-yellow;
        }
    }

    &__socials {
        margin-top: 56px;

        @include vw-medium-down {
            margin-top: 37px;
        }

        @include vw-small-down {
            margin-top: 28px;
        }
    }

    &__containerBottom {
        background-color: rgba($color-black, 0.2);
    }

    &__containerBottomInner {
        display: grid;
        grid-template-columns: repeat(2, max-content) 1fr;
        width: 100%;
        padding-y: 16px;
        align-items: center;
        color: white;

        @include vw-medium-down {
            grid-template-columns: repeat(2, 1fr);
        }

        @include vw-small-down {
            grid-template-columns: 1fr;
        }
    }

    &__containerBottomFirst {
        background-color: rgba($color-black, 0.3);
        padding: 7px 0;
        text-align: justify;
        color: #cecece;
    }

    &__privacy {
        display: flex;

        @include vw-small-down {
            flex-wrap: wrap;
            max-width: 270px;
        }

        @include vw-medium-down {
            grid-column: 1 / 3;
            grid-row: 2;
        }

        @include vw-small-down {
            grid-column: 1;
        }
    }

    &__privacyLink {
        font-size: toRem(16px);
        text-decoration: underline;
        color: rgba(white, 0.3);
        transition: color 0.25s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            color: white;
            text-decoration: underline;
        }

        &:not(:last-child) {
            margin-right: 24px;
        }
    }

    &__copyright {
        color: rgba(white, 0.3);
        margin-right: 24px;

        @include vw-medium-down {
            margin-right: 0;
            grid-row: 1;
            grid-column: 1;
        }
    }

    &__feipLogo {
        color: white;
        transition: opacity 0.25s ease-in-out;
        opacity: 0.3;
        position: relative;
        top: 1px;
        margin-left: auto;

        @include vw-medium-down {
            grid-row: 2;
            grid-column: 2;
        }

        @include vw-small-down {
            grid-row: 3;
            grid-column: 1;
            margin: 16px 0 0;
        }

        &:hover {
            opacity: 1; // FEIP logo
            color: white;
        }

        svg {
            fill: white;
            transition: opacity 0.25s ease-in-out;

            &:hover {
                opacity: 1; // Developed by
            }
        }
    }
}
