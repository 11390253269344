.MobileBurger {
    $block: &;

    $default-color: $color-black;
    $active-color: $color-orange;
    $z-index: $z-index-header-burger;

    position: relative;
    cursor: pointer;
    border: none;
    background-color: rgba($color-gray, 0.05);
    outline: none;
    z-index: $z-index; //show over mobileBar
    size: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    @include vw-header-small-down {
        size: 40px;
    }

    @media (hover: hover) {
        &:hover,
        &:focus,
        &:active {
            #{$block}__inner {
                &,
                &::before,
                &::after {
                    background-color: $active-color;
                }
            }
        }
    }

    &__outer {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 1px;
    }

    &__inner,
    &__inner::before,
    &__inner::after {
        position: absolute;
        width: 100%;
        height: 2px;
        transition: background-color 0.25s ease-in-out, transform 0.15s ease;
        background-color: $default-color;
    }

    &__inner {
        top: 60%;
        display: block;

        &::before,
        &::after {
            display: block;
            content: "";
        }

        &::before {
            top: -8px;
            transition: background-color 0.25s ease-in-out, top 75ms ease 0.12s, opacity 75ms ease;
        }

        &::after {
            bottom: -8px;
            transition: background-color 0.25s ease-in-out, bottom 75ms ease 0.12s,
                transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    // open menu
    .LayoutTrigger--mobileOffcanvas:checked ~ .Layout & &__inner {
        background-color: $active-color;
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(45deg);

        &::before {
            background-color: $active-color;
            top: 0;
            transition: background-color 0.25s ease-in-out, top 75ms ease, opacity 75ms ease 0.12s;
            opacity: 0;
        }

        &::after {
            background-color: $active-color;
            bottom: 0;
            transition: background-color 0.25s ease-in-out, bottom 75ms ease,
                transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
            transform: rotate(-90deg);
        }
    }
}
