.SocialsList {
    $block: &;

    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-gap: 12px 24px;
    width: 100%;

    @include vw-xxsmall-down {
        grid-column-gap: 14px;
    }

    &__item {
        display: flex;
        align-items: center;
        min-height: 26px;
    }

    &__link {
        color: white;

        &:hover,
        &:focus,
        &:active {
            opacity: 0.5;
        }

        svg {
            min-width: 24px;
        }
    }

    &__image {
        width: 78px;
    }
}
