.AddToCart {
    $block: &;

    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: $button-height;

    &--cart {
        height: 32px;
    }

    &--catalog {
        min-width: 162px;

        @include vw-xxsmall-down {
            min-width: unset;
        }

        #{$block}__button {
            @include vw-xxsmall-down {
                min-width: unset;
            }
        }
    }

    &__button {
        width: 100%;
        z-index: 1;
    }

    &__counter {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
