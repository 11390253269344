@import "elements/burger";
@import "elements/city-popup";
@import "elements/offcanvas";
@import "elements/location-picker";
@import "elements/search";

.SiteHeader {
    $block: &;

    width: 100% !important;

    @include vw-large-down {
        position: fixed;
        top: 0;
        z-index: $z-index-header;
    }

    &.uk-sticky.uk-active {
        #{$block}__top {
            display: none;
        }

        #{$block}__main {
            @include vw-large-up {
                padding-y: 16px;
                box-shadow: 0 4px 30px rgba(19, 24, 24, 0.1);
            }
        }

        #{$block}__mainGrid {
            @include vw-large-up {
                grid-template-areas: "logo locationPicker svp search cart";
                grid-template-columns: minmax(auto, 48px)  auto minmax(auto, 48px) 1fr auto;
                grid-column-gap: 24px;
            }
        }

        #{$block}__logo {
            &--large {
                display: none;
            }

            &--small {
                display: block;
            }
        }

        #{$block}__searchWrapper {
            @include vw-header-small-up {
                display: block;
            }
        }

        #{$block}__bottomSearch {
            display: none;
        }

        #{$block}__phoneWrapper {
            display: none;
        }
    }

    &__container {
        @extend .uk-container;
    }

    &__top {
        background-color: $color-gray--darkest;

        @include vw-large-down {
            display: none;
        }
    }

    &__main {
        position: relative;
        z-index: $z-index-header;
        padding-y: 24px;
        background-color: white;
        //New year 2025
        //background: url("/assets/images/happy-new-year.png");
        //New year 2025 end

        @include vw-large-down {
            padding-y: 16px;
            box-shadow: 0 4px 30px rgba(19, 24, 24, 0.1);
        }

        @include vw-header-small-down {
            padding-y: 12px;
        }
    }

    &__topGrid {
        display: flex;
        align-items: center;
    }

    &__mainGrid {
        display: grid;
        grid-template-areas: "logo locationPicker svp phone cart";
        grid-template-columns: auto 1fr minmax(auto, 80px) auto auto;
        grid-column-gap: 48px;
        align-items: center;

        @include vw-large-down {
            grid-template-areas: "mobile logo svp search cart";
            grid-template-columns: auto auto minmax(auto, 48px) 1fr auto;
            grid-column-gap: 24px;
        }

        @include vw-header-small-down {
            grid-template-areas: "mobile logo cart";
            grid-template-columns: repeat(3, 1fr);
            display: flex;
        }
    }

    &__mobileWrapper {
        grid-area: mobile;

        @include vw-large-up {
            display: none;
        }
    }

    &__logoWrapper {
        grid-area: logo;

        @include vw-header-small-down {
            margin: 0 auto;
        }
    }

    &__locationPickerWrapper {
        grid-area: locationPicker;

        @include vw-large-down {
            display: none;
        }
    }
    &__sdelanoVPrymorie{
        grid-area: svp;
        img{
            max-width: 80px;
            width: 100%;
        }
        cursor: pointer;
        &:hover{
            opacity: .75;
        }
        @include vw-header-small-down {
            img{
                max-width: 48px;
            }
        }
    }

    &__phoneWrapper {
        grid-area: phone;

        @include vw-large-down {
            display: none;
        }

        @include vw-header-small-down {
            display: inline-flex;
            .SiteHeader__phoneText {
                display: none;
            }

            .SiteHeader__phoneIcon {
                width: 40px;
                height: 40px;
                margin-right: 0;
            }

        }

    }

    &__searchWrapper {
        display: none;

        @include vw-large-down {
            display: block;
        }

        @include vw-header-small-down {
            display: none;
        }
    }

    &__cartWrapper {
        grid-area: cart;

        @include vw-header-small-down {
            margin-left: auto;
        }
    }

    &__bottomSearch {
        padding-y: 12px;
        background-color: rgba($color-gray, 0.05);

        @include vw-large-down {
            display: none;
        }
    }

    &__favoritesButton {
        @include vw-large-up {
            @include button-medium;

            margin-left: auto;
            background-color: transparent;
            border: none;
            color: white;

            @include vw-xlarge-down {
                font-size: toRem(14px);
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-yellow;
            }
        }

        @include vw-large-down {
            @include button-black-outline;

            width: 100%;
        }
    }

    &__favoritesButtonIcon {
        // Optical align
        margin-top: -2px;
    }

    &__profileButton,
    &__impersonationModeButton {
        border-radius: 0;

        @include vw-large-up {
            @include button-medium;
            @include button-gray;

            @include vw-xlarge-down {
                font-size: toRem(14px);
            }
        }

        @include vw-large-down {
            @include button-black;

            width: 100%;
        }
    }

    &__logoutButton {
        min-width: 40px;
        border-radius: 0;
        padding: 0;

        @include vw-large-up {
            @include button-medium;
            @include button-gray;

            background-color: rgba(white, 0.1);
        }

        @include vw-large-down {
            @include button-black;

            border-radius: 2px;
        }
    }

    &__logo {
        transition: opacity 0.25s ease-in-out;

        &:hover {
            opacity: 0.65;
        }

        &--large {
            width: 162px;
            height: 76px;

            @include vw-xlarge-down {
                width: 132px;
                height: 62px;
            }

            @include vw-large-down {
                display: none;
            }
        }

        &--small {
            width: 48px;
            display: none;

            @include vw-large-down {
                display: block;
            }

            @include vw-header-small-down {
                width: 40px;
            }
        }
    }

    &__phone {
        display: inline-flex;
        align-items: center;
        font-weight: $font-weight-semi-bold;
        color: $color-gray--darkest;
        font-size: toRem(18px);
        transition: color 0.25s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            color: $color-yellow--dark;

            #{$block}__phoneIcon {
                background-color: rgba($color-gray, 0.1);
            }
        }
    }

    &__phoneIcon {
        @extend %display-flex-center;

        width: 48px;
        height: 48px;
        border-radius: 2px;
        box-sizing: content-box;
        color: $color-gray--darkest;
        background-color: rgba($color-gray, 0.05);
        transition: background-color 0.25s ease-in-out;
        margin-right: 16px;

        svg {
            width: 20px;
            height: 20px;
        }

    }

    .ny-attention{
        padding: 5px;
        border: solid 2px #fccf17;
        text-align: center;
        font-size: 1.1rem;
        background-color: #ffffff;
        @include vw-header-small-down {
            display:none;
            padding: 1px;
            border: solid 1px #fccf17;
            text-align: center;
            font-size: 0.8rem;
        }
    }
    .ny-attention-xs{
        display: none;
        @include vw-header-small-down {
            display: block;
            padding: 1px;
            border: solid 1px #fccf17;
            text-align: center;
            font-size: 0.8rem;
        }
    }
}
