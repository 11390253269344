.vdpComponent {
    width: 100%;

    &.vdpWithInput > input {
        @extend .SiteForm__input;

        :root &[readonly] {
            color: var(--form-default-text-color);
        }

        &::placeholder {
            opacity: 1;
        }
    }
}

.vdpClearInput {
    display: none;
}

.vdpCell.selectable {
    color: $color-black;
}

.vdpCell.outOfRange {
    color: $color-gray;
}

.vdpCell.disabled {
    color: $color-gray--lighter;
    opacity: 1;
}

.vdpCell.today {
    &.disabled {
        color: white;
    }

    .vdpCellContent {
        background-color: $color-gray--lighter;
    }
}

.vdpCell.selected .vdpCellContent {
    background-color: $color-orange;
}

.vdpCellContent {
    width: 26px;
    height: 26px;
    padding-top: 2px;
}

.vdpArrowNext:after {
    border-left-color: $color-orange;
}

.vdpArrowPrev:after {
    border-right-color: $color-orange;
}

@media (hover: hover) {
    .vdpCell.selectable:hover .vdpCellContent {
        background-color: $color-yellow;
        color: $color-black;
    }
}
