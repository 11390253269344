.MiniCart {
    $block: &;

    width: 552px;

    @include vw-large-down {
        width: auto;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    &--empty {
        margin-left: auto;
        margin-right: auto;
        width: 250px;

        #{$block}__listWrapper {
            display: none;
        }
    }

    &__listWrapper {
        flex: 1;
        padding-right: 8px;
        border-bottom: 1px rgba($color-gray, 0.2) solid;
    }

    &__list {
        max-height: 438px;
        overflow-y: auto;
        padding: 22px 12px 18px 24px;

        @include vw-large-down {
            max-height: none;
            overflow-y: visible;
            padding: 0;
            padding-bottom: 24px;
        }

        @include vw-small-down {
            padding-bottom: 16px;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__footer {
        padding: 16px 24px 24px;

        @include vw-large-down {
            padding: 0;
            padding-top: 24px;
        }

        @include vw-small-down {
            padding-top: 16px;

        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-weight: $font-weight-bold;
        color: $color-gray--darkest;

        &:not(:first-child) {
            margin-top: 6px;
        }

        &--weight,
        &--delivery {
            font-weight: $font-weight-regular;

            #{$block}__totalValue {
                font-weight: $font-weight-medium;
                font-size: toRem(18px);
            }
        }
    }

    &__totalLabel {
        font-size: toRem(18px);
    }

    &__totalValue {
        font-size: toRem(22px);
    }

    &__emptyCartText {
        @extend %heading-small;
    }

    &__buttonWrapper {
        margin-top: 18px;
    }

    &__button {
        width: 100%;
    }
}
