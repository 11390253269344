// Miscellaneous
body {
    line-height: $base-body-line-height;
}

// Remove loading 'alt' text for firefox images
img:-moz-loading {
    color: transparent;
}

[hidden] {
    display: none !important;
}

.grecaptcha-badge {
    visibility: hidden;
    z-index: $z-index-recaptcha-badge;
}

@keyframes shine-loader {
    100% {
        background-position: -100%;
    }
}

// remove skeleton loader after image load
// if you find bugs related to this solution
// ask @lnd_feip
:root .yall-loaded {
    background: transparent none;
    animation: none;
}

html:root {
    ::-moz-selection {
        color: white;
        background: $color-yellow--dark;
    }

    ::selection {
        color: white;
        background: $color-yellow--dark;
    }
}

.hidden--s {
    @include vw-small-down {
        display: none;
    }
}

.visible--s {
    @include vw-small-up {
        display: none;
    }
}

.fade-enter-active {
    transition: opacity 0.25s ease-in-out;
}

.fade-enter {
    opacity: 0;
}
