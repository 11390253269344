.Counter {
    $block: &;

    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    &--cart {
        #{$block}__button {
            @include vw-small-down {
                background-color: rgba($color-gray, 0.05);
            }
        }
    }

    &--legalCart {
        #{$block}__inputWrapper {
            margin-x: 0;
        }
    }

    &--slider {
        padding: 0 16px;
        justify-content: space-between;
        background-color: white;
        border-radius: 2px;
        box-shadow: 0 4px 20px rgba($color-black, 0.05);

        #{$block}__inputWrapper {
            max-width: 54px;
        }
    }

    &__button {
        size: 32px;
        min-width: unset;
        min-height: unset;
        background-color: white;
        border-radius: 2px;
        color: $color-orange;

        &:hover,
        &:focus {
            background-color: rgba($color-gray, 0.05);
            color: $color-orange;
        }

        &:active {
            background-color: rgba($color-gray, 0.08);
        }
    }

    // Avoid bug in Firefox by wrapping input in div
    &__inputWrapper {
        flex: 1;
        height: 32px;
        margin-x: 8px;
    }

    &__input {
        @extend .uk-input;

        height: 100%;
        padding: 0;
        background: none;
        border: 1px rgba($color-gray, 0.3) solid;
        border-radius: 2px;
        font-weight: $font-weight-semi-bold;
        font-size: toRem(18px);
        text-align: center;
        color: $color-gray--darkest;

        &--unavailable {
            color: $color-red;
        }

        &:hover,
        &:focus,
        &:active {
            outline: none;
            border-color: $color-gray;
            color: $color-black;
        }
    }
}
