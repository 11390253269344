%heading-base {
    font-weight: $font-weight-semi-bold;
    color: $color-gray--darkest;
    line-height: 1.2;
}

%heading-xlarge {
    @extend %heading-base !optional;

    font-size: toRem(56px);

    @include vw-large-down {
        font-size: toRem(48px);
    }

    @include vw-medium-down {
        font-size: toRem(40px);
    }

    @include vw-small-down {
        font-size: toRem(32px);
    }
}

%heading-large {
    @extend %heading-base !optional;

    font-size: toRem(40px);

    @include vw-xlarge-down {
        font-size: toRem(36px);
    }

    @include vw-medium-down {
        font-size: toRem(32px);
    }

    @include vw-small-down {
        font-size: toRem(28px);
    }

    @include vw-xsmall-down {
        font-size: toRem(24px);
    }
}

%heading-medium {
    @extend %heading-base !optional;

    font-size: toRem(32px);

    @include vw-xlarge-down {
        font-size: toRem(30px);
    }

    @include vw-medium-down {
        font-size: toRem(27px);
    }

    @include vw-small-down {
        font-size: toRem(24px);
    }

    @include vw-xsmall-down {
        font-size: toRem(22px);
    }
}

%heading-small {
    @extend %heading-base !optional;

    font-size: toRem(22px);

    @include vw-small-down {
        font-size: toRem(20px);
    }
}

%heading-xsmall {
    @extend %heading-base !optional;

    font-size: toRem(18px);

    @include vw-xsmall-down {
        font-size: toRem(16px);
    }
}
