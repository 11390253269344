.ErrorPage {
    &__title {
        @extend %heading-medium;

        margin-bottom: 24px;

        @include vw-large-down {
            margin-bottom: 20px;
        }

        @include vw-small-down {
            margin-bottom: 16px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__code {
        font-size: toRem(180px);
        font-weight: $font-weight-semi-bold;
        line-height: 1;
        color: $color-gray--lighter;

        @include vw-medium-down {
            font-size: toRem(160px);
        }

        @include vw-small-down {
            font-size: toRem(130px);
        }

        @include vw-xsmall-down {
            font-size: toRem(100px);
        }
    }
}
