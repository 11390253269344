$v-select-placeholder-color: transparent;

.v-select {
    // Single
    &.vs--single {
        .vs__open-indicator {
            display: block;
            width: 24px;
            height: 8px;
            background-image: var(--form-default-dropdown-arrow-source);
            background-repeat: no-repeat;
            background-position: 100% 50%;
            transition: transform 0.25s ease;
        }

        .vs__actions {
            top: 50%;
            transform: translateY(-50%);
            margin-top: -2px;
        }

        .vs__selected {
            padding: 0;
            margin: 0;
            border: none;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .vs__selected-options {
            margin: 0 24px 0 $form-padding-horizontal;
            flex-wrap: nowrap;
            max-width: calc(100% - 36px);
        }

        .vs__search,
        .vs__search:focus {
            margin: 0;
            border: none;
            padding: 0;
        }
    }

    &:hover {
        .vs__dropdown-toggle {
            color: var(--form-hover-text-color);
            border-color: var(--form-hover-border);
            background-color: var(--form-hover-background);
        }
    }

    &.vs--open {
        .vs__open-indicator {
            transform: scaleY(-1);
        }

        .vs__dropdown-toggle {
            color: var(--form-active-text-color);
            border-color: var(--form-active-border);
            background-color: var(--form-active-background);
            border-radius: $form-border-radius $form-border-radius 0 0;
        }
    }

    &.vs--has-options .vs__no-options {
        display: none;
    }

    &.vs--hide-actions .vs__actions {
        display: none;
    }

    .vs-option-content {
        display: flex;
        align-items: center;
    }

    .vs-option-icon {
        margin-right: 8px;
        size: 18px;

        & + .vs-option-text {
            line-height: 18px;
        }
    }

    // Common styles (single + multiple)
    .vs__search {
        color: var(--form-default-text-color);
        line-height: unset;
    }

    .vs__search::placeholder {
        color: $v-select-placeholder-color;
        font-size: $form-font-size;
    }

    .vs__dropdown-toggle,
    .vs__dropdown-menu {
        color: var(--form-default-text-color);
        font-size: $form-font-size;
    }

    .vs__selected {
        color: var(--form-default-text-color);
        transition: opacity 0.25s ease-in-out;
    }

    .vs__dropdown-toggle {
        min-height: $form-height;
        border-radius: $form-border-radius;
        padding: 0;
        transition-property: background-color, border-color, color;
        transition: 0.25s ease-in-out;
        border: 1px var(--form-default-border) solid;
        background-color: var(--form-default-background);
    }

    .vs__dropdown-menu {
        top: 100%;
        border: var(--form-default-border);
        padding: 0;
        z-index: 99;
        max-height: 320px;
        border-radius: 0 0 $form-border-radius $form-border-radius;
        min-width: 80px;
        background-color: var(--form-default-option-background);
    }

    .vs__dropdown-option {
        padding: 10px 13px;
        white-space: normal;
        background-color: var(--form-default-option-background);
        color: var(--form-default-option-color);
        transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

        &--highlight {
            background-color: var(--form-hover-option-background);
            color: var(--form-hover-option-color);
        }

        &--disabled {
            opacity: 0.5;
        }

        &--selected {
            background-color: var(--form-active-option-background);
            color: var(--form-active-option-color);
            cursor: default;
        }
    }

    .vs__selected-options {
        align-items: center;
        padding: 0;
    }

    .vs__actions {
        position: absolute;
        right: 0;
        padding-right: 0;
    }

    .vs__no-options {
        padding: 10px 12px;
        background-color: var(--form-default-option-background);
    }

    .vs__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
    }

    .vs__loader-icon {
        width: 30px;
        height: 30px;
    }

    &.vs--disabled {
        &:hover {
            .vs__dropdown-toggle {
                background-color: var(--form-disabled-background);
            }
        }

        .vs__dropdown-toggle {
            color: var(--form-disabled-text-color);
            background-color: var(--form-disabled-background);
            border-color: var(--form-disabled-border);
        }

        .vs__search {
            background-color: transparent;
        }

        .vs__actions,
        .vs__open-indicator {
            background-color: transparent;
            opacity: 0.5;
        }

        .vs__dropdown-toggle,
        .vs__search,
        .vs__actions,
        .vs__open-indicator,
        .vs__selected-options {
            cursor: default;

            &:hover {
                cursor: default;
            }
        }
    }
}
